import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import { setFilteredUserChips, clearFilteredUserChips, postUserFilterApi } from "../../../redux/features/userSlice";
import { userRoleConstants } from "../../../utils/constants";
import { errorToast, successToast } from "../../Toasts/Toasts";

const ManageUserFilterModal = ({ currentSize, clearFilterValues, accountNumber, setAccountNumber, user, setUser, onboardingDate, setOnboardingDate, status, setStatus, displayDate, setDisplayDate }) => {
  const [userFilterShow, setUserFilterShow] = useState(false);

  let page = 1;

  const dispatch = useDispatch();

  const { DISTRIBUTOR_USER_ID } = userRoleConstants;

  const userAddedBy = localStorage.getItem("userId");

  const handleModalClose = () => {
    setUserFilterShow(!userFilterShow);
  };

  const handleAccountNumber = (e) => {
    setAccountNumber(e?.target?.value);
  };

  const handleUser = (e) => {
    setUser(e?.target?.value);
  };

  useEffect(() => {
    const filterValue = () => {
      if (clearFilterValues === "account_no") setAccountNumber("");
      if (clearFilterValues === "name") setUser("");
      if (clearFilterValues === "createdAt") setDisplayDate("");
      if (clearFilterValues === "is_active") setStatus("");
    };

    filterValue();

    // eslint-disable-next-line
  }, [clearFilterValues]);

  const handleEventOnboardingDate = (event, picker) => {
    picker.element.val(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setOnboardingDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setDisplayDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelOnboardingDate = (event, picker) => {
    picker.element.val("");
    setOnboardingDate("");
  };

  const filterData = {
    account_no: accountNumber,
    name: user,
    createdAt: onboardingDate,
    is_active: status?.value ?? "",
    role_id: DISTRIBUTOR_USER_ID,
    added_by_id: userAddedBy,
  };

  const filterDataForChips = {
    account_no: accountNumber,
    name: user,
    createdAt: onboardingDate,
    is_active: status ?? "",
  };

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };

  const filteredData = filterEmptyFields(filterData);
  const filteredDataForChips = filterEmptyFields(filterDataForChips);

  const handleSubmitData = async () => {
    const isFilterEmpty = Object.entries(filteredData).every(([key, value]) => {
      if (key === "role_id" || key === "added_by_id") {
        return true;
      }
      return value === "";
    });

    if (!isFilterEmpty) {
      const response = await dispatch(postUserFilterApi({ filteredData: filteredData, page, size: currentSize }));

      dispatch(setFilteredUserChips(Object.entries(filteredDataForChips)));

      if (response?.meta?.requestStatus === "fulfilled") {
        successToast("Filter applied successfully");
      } else {
        errorToast("Error occurred while filtering");
      }
      handleModalClose();
    } else {
      errorToast("No field selected for filter");
    }
  };

  const statusOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];

  const handleCancelButton = () => {
    setDisplayDate("");
    setAccountNumber("");
    setOnboardingDate("");
    setStatus("");
    setUser("");
    dispatch(postUserFilterApi({ filteredData: { role_id: DISTRIBUTOR_USER_ID, added_by_id: userAddedBy }, page, size: currentSize }));
    dispatch(clearFilteredUserChips());
    handleModalClose();
  };

  return (
    <>
      <button type="button" onClick={() => setUserFilterShow(true)} className="btn btn-sm btn-primary rounded-pill btn-smwaves-effect waves-light me-2">
        <i className="ti ti-filter me-1" /> Filter
      </button>
      {
        <Modal show={userFilterShow} centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <div className="modal-body">
                <button type="button" className="btn-close" onClick={handleModalClose}></button>
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">Account No.</label>
                    <input type="text" onChange={handleAccountNumber} placeholder="" className="form-control mb-3" value={accountNumber} />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Users</label>
                    <input type="text" onChange={handleUser} placeholder="" className="form-control mb-3" value={user} />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Onboarding Date</label>
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: { cancelLabel: "Clear", format: "YYYY-MM-DD" },
                        showDropdowns: true,
                        drops: "up",
                      }}
                      onApply={handleEventOnboardingDate}
                      onCancel={handleCancelOnboardingDate}
                    >
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={displayDate} autoComplete="off" />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Status</label>
                    <Select options={statusOptions} isMulti={false} onChange={(selectedOption) => setStatus(selectedOption)} value={status} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" onClick={handleSubmitData} className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-filter">
                      Submit
                    </button>
                    <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleCancelButton}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default ManageUserFilterModal;
