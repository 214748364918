import React from "react";
import { Modal } from "react-bootstrap";

const PaymentInProcessModal = ({ showModal }) => {
  
  return (
    <>
      <Modal show={showModal} size="sm" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body className="dashboard-cards rounded text-center">
              <div className="text-warning check warning" >
                <p>
                  <i className="ti ti-progress fs-1"></i>
                </p>
              </div>
              <p className="para">Payment is in process.</p>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentInProcessModal;