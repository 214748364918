import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const productFilter = async (filterData,page,size) => {
  try {
    let url = `${baseUrl}/${ApiConstants.PRODUCT_FILTER}?page=${page}&size=${size}`
    const queryParams = new URLSearchParams(filterData).toString();

    if (queryParams) {
      url += `&${queryParams}`;
    }
    
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCommissionHistory = async (filterData) => {
  try {
    let url = (filterData.product_id && filterData.account_no) ? `${baseUrl}/products/${filterData.product_id}/accounts/${filterData.account_no}/commission-history` : filterData.product_id ? `${baseUrl}/products/${filterData.product_id}/commission-history` : ""

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    
  }
};

export const addProduct = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.PRODUCT}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editProduct = async (id,data) => {
  try {
    const response = await axios.put(`${baseUrl}/${ApiConstants.PRODUCT}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const importProduct = async (formData) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.PRODUCT_IMPORT}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response;
  } catch (error) {
    console.error("Error uploading files:", error);
  }
}

export const logTransactionFilter = async (filterData,page,size) => {
  try {
    let url = `${baseUrl}/${ApiConstants.LOG_TRANSACTION}?page=${page}&size=${size}`
    const queryParams = new URLSearchParams(filterData).toString();

    if (queryParams) {
      url += `&${queryParams}`;
    }
    
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
