import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { addProduct } from "../../../services/productService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { postProductFilterApi } from "../../../redux/features/productSlice";
import { useDispatch, useSelector } from "react-redux";

const AddProductModal = ({ masterData, showAddProductModal, setShowAddProductModal, currentSize }) => {
  const dispatch = useDispatch();
  let page = 1;
  const filteredChips = useSelector((state) => state.product.filteredChips);

  const dataToBeFiltered = Object.fromEntries(filteredChips);

  let transformedDataToBeFiltered = {};

  for (let key in dataToBeFiltered) {
    if (typeof dataToBeFiltered[key] === "object" && "label" in dataToBeFiltered[key] && "value" in dataToBeFiltered[key]) {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key].value;
    } else {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key];
    }
  }

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const handleClose = () => {
    setShowAddProductModal(false);
  };

  const carrierOptions = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const countryOptions = () => {
    return masterData?.countries?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const onSubmit = async (data, event) => {
    const button = event.nativeEvent.submitter;
    button.disabled = true;
    let ProductInfo = {
      name: data?.retailer_name,
      base_price: data?.price,
      commission: data?.commission,
      carrier_id: data?.carrier.value,
      country_id: data?.country?.value,
    };

    try {
      const response = await addProduct(ProductInfo);
      button.disabled = false;

      if (response?.success) {
        successToast(response?.message);
        dispatch(postProductFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize }));

        // Check if "Submit" button was clicked
        if (button.id === "saveButton") {
          setShowAddProductModal(false);
        } else {
          // Reset form fields for "Submit & Next" button
          reset({
            retailer_name: "",
            price: "",
            commission: "",
            carrier: null,
            country: null,
          });
        }
      } else {
        errorToast(response?.message);
        setShowAddProductModal(true);
        button.disabled = false;
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while adding the product.");
      setShowAddProductModal(true);
      button.disabled = false;
    }
  };


  return (
    <>
      <Modal show={showAddProductModal} size="md" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                <button type="button" className="btn-close" onClick={handleClose} />
                <div className="text-left mb-4">
                  <h3 className="mb-2">Add Product</h3>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Name <sup className="text-danger">*</sup></label>
                    <input type="text" placeholder="" className="form-control" name="retailer_name" minLength={1} maxLength={256} {...register("retailer_name", { required: "required" })} />
                    <small className="text-danger">{errors?.retailer_name && errors.retailer_name.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">
                      Price <small>(USD)</small> <sup className="text-danger">*</sup>
                    </label>
                    <input type="number" min={0} placeholder="" className="form-control" name="price" {...register("price", { required: "required" })} />
                    <small className="text-danger">{errors?.price && errors.price.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Carrier <sup className="text-danger">*</sup></label>
                    <Controller
                      name="carrier"
                      control={control}
                      rules={{ required: "required" }}
                      render={({ field }) => (
                        <>
                          <Select {...field} options={carrierOptions()} isSearchable placeholder="Select Carrier..." />
                          {errors.carrier && <small className="text-danger">{errors.carrier.message}</small>}
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Country <sup className="text-danger">*</sup></label>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: "required" }}
                      render={({ field }) => (
                        <>
                          <Select {...field} options={countryOptions()} isSearchable placeholder="Select Country..." />
                          {errors.country && <small className="text-danger">{errors.country.message}</small>}
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">
                      Commission <small>(%)</small> <sup className="text-danger">*</sup>
                    </label>
                    <input type="number" min={0} placeholder="" className="form-control" name="commission" {...register("commission", { required: "required" })} />
                    <small className="text-danger">{errors?.commission && errors.commission.message}</small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" id="saveButton">
                      Submit
                    </button>
                    <button type="submit" className="btn btn-outline-primary rounded-pill btn-reset waves-effect" id="submitButton">Submit &amp; Next</button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddProductModal;
