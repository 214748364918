import React, { useEffect, useState } from "react";
import StatusCard from "../StatusCard/SmallStatusCard";
import ManageProductGrid from "./ManageProductGrid";
import { getMasterData } from "../../../services/userService";
import ManageProductFilter from "./ManageProductFilter";
import localStorageStore from "../../../utils/localStorageStore";
import { userRoleConstants } from "../../../utils/constants";
import AddProductModal from "./AddProductModal";
import { useSelector } from "react-redux";
import { formatCreditBalance } from "../../../utils/sharedFunctions";
import ImportProductModal from "./ImportProductModal";

const ManageProduct = () => {
  
  const [masterData, setMasterData] = useState("");
  const [showAddProductModal, setShowAddProductModal] = useState(false); 
  const [clearFilterValues, setClearFilterValues] = useState("");
  const [currentSize, setCurrentSize] = useState(10);
  const [showImportProductBtn ,setShowImportProductBtn] = useState(false);
  let id = "";
  const RoleId = parseInt(localStorageStore.getRole());

  useEffect(() => {
    fetchMasterData();

    // eslint-disable-next-line
  }, []);

  const fetchMasterData = async () => {
    try {
      const response = await getMasterData(id);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handleAddProductBtn = () => {
    setShowAddProductModal(true);
  };

  const headerDetails = useSelector((state) => state.product.count);

  const handleImportProductBtn = () => {
    setShowImportProductBtn(true);
  };
 
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-1 pt-1"> Products</h4>
        <div className="d-flex">
          <ManageProductFilter masterData={masterData} clearFilterValues={clearFilterValues} currentSize={currentSize} />
          {(RoleId === userRoleConstants.ADMIN_ID) && (
           <> 
            <button type="button" className="btn btn-sm btn-secondary rounded-pill btn-smwaves-effect waves-light me-2" onClick={handleAddProductBtn}>
              <i className="ti ti-plus me-1"></i> Add
            </button>
            <button type="submit" className="btn btn-sm btn-outline-secondary rounded-pill btn-smwaves-effect waves-light me-2" onClick={handleImportProductBtn}>
              <i className="ti ti-upload me-1" /> Import
            </button>
          </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-12 mb-4">
          <StatusCard color="primary" icon="ti ti-world ti-sm" value={formatCreditBalance(headerDetails?.total_countries ?? 0)} label="Total Countries" />
        </div>
        <div className="col-lg-3 col-12 mb-4">
          <StatusCard color="info" icon="ti ti-access-point ti-sm" value={formatCreditBalance(headerDetails?.total_carriers ?? 0)} label="Total Carriers" />
        </div>
        <div className="col-lg-3 col-12 mb-4">
          <StatusCard color="success" icon="ti ti-box ti-sm" value={formatCreditBalance(headerDetails?.total_products ?? 0)} label="Total Products" />
        </div>
        {(RoleId !== userRoleConstants.RETAILER_ID) && (
          <div className="col-lg-3 col-12 mb-4">
            <StatusCard color="warning" icon="ti ti-box-seam ti-sm" value={formatCreditBalance(headerDetails?.total_active_products ?? 0)} label="Active Products" />
          </div>
        )}
      </div>
      <ImportProductModal showModalBox={showImportProductBtn} setShowImportProductBtn={setShowImportProductBtn} />
      <ManageProductGrid setClearFilterValues={setClearFilterValues} currentSize={currentSize} setCurrentSize={setCurrentSize}/>
      {showAddProductModal && <AddProductModal masterData={masterData} showAddProductModal={showAddProductModal} setShowAddProductModal={setShowAddProductModal} currentSize={currentSize} />}
    </>
  );
};

export default ManageProduct;
