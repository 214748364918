import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { helpAndSupportFilter } from "../../services/helpAndSupportService";

const initialState = {
  helpAndSupportDetails: [],
  isLoading: false,
  error: null,
  count: {},
};

export const postHelpAndSupportFilterApi = createAsyncThunk(
  "helpAndSupport/postHelpAndSupportFilterApi",
  async (filteredData) => {
    try {
      const response = await helpAndSupportFilter(filteredData?.filteredData,filteredData?.page,filteredData?.size);
      return response;
    } catch (error) {
      console.error("Error fetching transaction details:", error);
      throw error;
    }
  }
);

const helpAndSupportSlice = createSlice({
  name: "helpAndSupport",
  initialState,
  reducers: {
    setInitHelpAndSupportDetails: (state) => {
      state.helpAndSupportDetails = [];
    },
    resetProductState: (state) => {
      Object.assign(state, initialState);
    },   
  },
  extraReducers: (builder) => {
    builder.addCase(postHelpAndSupportFilterApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(postHelpAndSupportFilterApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.helpAndSupportDetails = action.payload.ticketData;
      state.count = action.payload.countOfTags;
    });

    builder.addCase(postHelpAndSupportFilterApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = helpAndSupportSlice;
export const { setInitHelpAndSupportDetails, resetProductState,} = helpAndSupportSlice.actions;
export default reducer;