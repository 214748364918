import { axios } from "../utils/axios";
import ApiConstants from "../utils/apiConstants";

const baseUrl = process.env.REACT_APP_API_HOST;

export const downloadReceipt = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.RECEIPT_DOWNLOAD}/${id}`, {  responseType: "arraybuffer" });
    return response;
  } catch (error){
    throw error;
  }
}

export const customerProfile = async () => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMER}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCustomerContactList = async (page, currentSize) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMER}/contacts?page=${page}&size=${currentSize}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addContact = async (data) => {
  try {
    if(data?.image === null) {
      delete data?.image    
    }
    if(data?.email === null || data?.email === "") {
      delete data?.email
    }
    
    const response = await axios.post(`${baseUrl}/${ApiConstants.CUSTOMER}/contacts`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getContactHistory = async (page, currentSize, id) => {
  try {
    if(id) {
      let response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMER}/history?contact_id=${id}&page=${page}&size=${currentSize}`);
      return response;
    }
    else{
      let response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMER}/history?page=${page}&size=${currentSize}`);
      return response;
    }
  } catch (error) {
    throw error;
  }
}
export const updateContactDetails = async (id, data) => {
  try {
    if(data?.image === null || data?.image.startsWith("http")) {
      delete data?.image    
    }
    if(data?.email === null || data?.email === "") {
      delete data?.email
    }
    
    const response = await axios.put(`${baseUrl}/${ApiConstants.CUSTOMER}/contacts/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
} 

export const deleteContact = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}/${ApiConstants.CUSTOMER}/contacts/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateCustomerProfile = async (data) => {
  try {
    if(data?.image === null || data?.image.startsWith("http")) {
      delete data?.image    
    }
    if(data?.email === null || data?.email === "") {
      delete data?.email    
    }
    if(data?.name === null || data?.name === "") {
      delete data?.name    
    }
    const response = await axios.put(`${baseUrl}/${ApiConstants.CUSTOMER}`, data);
    return response;
  } catch (error) {
    throw error;
  }
} 

export const fetchCustomerProduct = async (page, size) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMER}/products?page=${page}&size=${size}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const convertCurrency = async (data) => {
  try {
    let response = await axios.post(`${baseUrl}/${ApiConstants.CUSTOMER}/convert-currency`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCustomer = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}/${ApiConstants.CUSTOMER}`);
    return response;
  } catch (error) {
    throw error;
  }
}