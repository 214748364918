import CryptoJS from "crypto-js";

const decryptToken = (encryptedText) => {

  // eslint-disable-next-line no-useless-escape
  const decrypted = CryptoJS.AES.decrypt(encryptedText.replace(/\_/g, "/"), CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_PASS), {
    iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export default decryptToken;
