import CryptoJS from "crypto-js";

const encrypt = (textToEncrypt) => {
//   return crypto.AES.encrypt(value, process.env.REACT_APP_SECRET_PASS).toString();
//   const result = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_PASS), { iv: CryptoJS.enc.Utf8.parse("\0".repeat(16)), mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.Pkcs7 });
//   return result.toString();
  const encrypted = CryptoJS.AES.encrypt(textToEncrypt, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_PASS), { 
    iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.toString();
};

export default encrypt;
