import React,{useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import { importProduct } from "../../../services/productService";
import { useDropzone } from "react-dropzone";
import { errorToast } from "../../Toasts/Toasts";
import { Link } from "react-router-dom";


const ImportProductModal = ({ showModalBox, setShowImportProductBtn }) => {

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();  
  const [file, setFile] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [failureRecords, setFailureRecords] = useState(0);
  const [successRecords, setSuccessRecords] = useState(0);
  const [link, setLink] = useState("");

  const nameAndSize = acceptedFiles.map((file, index) => (
    <span key={index}>
      {file.name} - {file.size} bytes
    </span>
  ));

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        setFile(base64String);
      };
      reader.readAsDataURL(file);
    }
  }, [acceptedFiles]);

  const handleUpload = async () => {
    if (acceptedFiles.length > 0) {
      if (file) {
        try {
          const response = await importProduct({ file: file });

          if (response && response.data) {
            setFailureRecords(parseInt(response?.data?.failure_records));
            setSuccessRecords(parseInt(response?.data?.success_records));
            setLink(response?.data?.link);
          } else {
            console.error("Unexpected response format:", response);
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }

        setToggle(!toggle);
      } else {
        errorToast("No file to upload");
      }
    }
  };

  const handleClose = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
    setToggle(false)
    setShowImportProductBtn(false)
  }

  const handleReupload = () => {
    setToggle(false)
  }

  return (
    <>
      <Modal show={showModalBox} size="md" centered>
        <div className="modal-simple">
          {(!toggle) ? <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <button type="button" className="btn-close" onClick={handleClose} />
              <div className="text-left mb-4">
                <h3 className="mb-2">Bulk import</h3>
                <p className="pb-2">Please follow instructions below to import multiple records in one go.</p>
              </div>
              <ul className="timeline mb-0">
                <li className="timeline-item timeline-item-transparent">
                  <span className="timeline-point timeline-point-success" />
                  <div className="timeline-event">
                    <div className="timeline-header mb-sm-0 mb-3">
                      <h6 className="mb-0 text-muted">Step 1</h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="mb-0">Download Template</h6>
                      <div className="d-flex">
                        <div className="avatar avatar-xs me-2">
                        
                        <a href="../../assets/excel/Products_Template.xlsx"  target="_blank">   <img src="../../assets/img/csv.png" id="download" alt="File" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="timeline-item timeline-item-transparent">
                  <span className="timeline-point timeline-point-success" />
                  <div className="timeline-event">
                    <div className="timeline-header mb-sm-0 mb-3">
                      <h6 className="mb-0 text-muted">Step 2</h6>
                    </div>
                    <p className="h6 mb-0">Enter data in .xls file</p>
                  </div>
                </li>
                <li className="timeline-item timeline-item-transparent">
                  <span className="timeline-point timeline-point-success" />
                  <div className="timeline-event">
                    <div className="timeline-header mb-sm-0 mb-3">
                      <h6 className="mb-0 text-muted">Step 3</h6>
                    </div>
                    <p className="h6 mb-0">You can refer instructions and master data with in the template workbook</p>
                  </div>
                </li>
                <li className="timeline-item timeline-item-transparent">
                  <span className="timeline-point timeline-point-success" />
                  <div className="timeline-event">
                    <div className="timeline-header mb-sm-0 mb-3">
                      <h6 className="mb-0 text-muted">Step 4</h6>
                    </div>
                    <p className="h6 mb-0">Import data files below</p>
                  </div>
                </li>
              </ul>
              <div {...getRootProps()} style={{border: "2px dashed #ccc", borderRadius: "4px", padding: "20px", textAlign: "center", cursor: "pointer"}} className="dropzone needsclick mt-3 dz-clickable">
                <input {...getInputProps()} />
                <div className="dz-message needsclick" style={{fontSize: "16px", color: "#666"}}>Drop files here or click to upload</div>
                <span>{nameAndSize}</span>
              </div>
              <div className="row">
                <div className="col-12 text-center mt-4">
                  <button type="submit" className="btn btn-secondary me-sm-3 me-1 waves-effect waves-light" onClick={handleUpload} >
                    Upload
                  </button>
                  <button type="reset" className="btn btn-label-secondary btn-reset waves-effect" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div> :
          <div className="modal-content p-3 p-md-3">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={handleClose} />
            <div className="text-left mb-4">
              <h3 className="mb-2">Import products</h3>
            </div>
            <div className="p-6 space-y-4">
              <form className="flex flex-col space-y-3">
                <div className="items-center justify-between mb-3">
                  <p className="text-muted small mb-0 block text-slate-500 dark:text-slate-300 text-sm font-normal me-0 mb-3">
                    Statistics of successful vs failed records from the data imported on
                    the previous screen
                  </p>
                </div>
                <div className="items-center justify-between space-x-1 space-y-4">
                <span className="h3 d-block mb-2">
                  {(failureRecords + successRecords) === 0 ? 0 : ((successRecords / (failureRecords + successRecords)) * 100).toFixed(2)} % successful
                </span>

                  <div className="flex-1">
                    <div className="w-full bg-slate-200 h-2 rounded-xl overflow-hidden">
                      <div
                        className="progress-bar bg-warning-500 h-full rounded-xl"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <div className="d-flex justify-content-between text-sm font-normal dark:text-slate-300 mb-2">
                      <span>0%</span>
                      <span className="font-normal">100%</span>
                    </div>
                  </div>
                  <div className="progress rounded-pill mb-1" style={{ height: 5 }}>
                    <div
                      className="progress-bar chart-color5 bg-success"
                      role="progressbar"
                      style={{ width: `${(failureRecords + successRecords) === 0 ? 0 : (successRecords / (failureRecords + successRecords)) * 100}%` }}
                      aria-valuenow={32}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="table-td">
                            <strong>Total records</strong>
                          </td>
                          <td className="table-td">
                            <span className="text-primary h5">{failureRecords + successRecords}</span>
                          </td>
                        </tr>
                        <tr className="table-active">
                          <td className="table-td">Successful records</td>
                          <td className="table-td">
                            <span className="text-success h5">{successRecords}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="table-td">Failure records</td>
                          <td className="table-td">
                            <span className="text-danger h5">{failureRecords}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex items-center justify-content-between mt-2">
                  <p className="flex text-sm mt-3">
                    Download failed records &nbsp;<Link to={link}> here</Link>
                    <span>
                      {" "}
                      <img src="../../assets/img/xls.png" width="30px;" alt="" />
                    </span>
                  </p>
                </div>
                <p className="text-sm">
                  This sheet will also have error descriptions corresponding to each
                  records.
                </p>
                <p className="text-sm">
                  You can proceed to next step or reupload after fixing the errors.
                </p>
              </form>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-4">
                <button className="btn btn-secondary me-sm-3 me-1 waves-effect" onClick={handleReupload} >
                  Re-Upload
                </button>
                <button type="reset" className="btn btn-label-secondary btn-reset waves-effect" onClick={handleClose} >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>        
          }
        </div>
      </Modal>
    </>
  );
};

export default ImportProductModal;
