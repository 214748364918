import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <section id="landingPricing" className=" py-5 bg-body landing-pricing">
        <div className="container">
          <div className="text-center mb-3 pb-1">
            <span className="badge bg-label-primary">Testimonial</span>
          </div>
          <h2 className="text-center mb-1">"Effortless to send mobile top-ups to my family back home."</h2>
          <p className="text-center">Alex, A Grateful User</p>
        </div>
      </section>
      {/* Pricing plans: End */}
      {/* FAQ: Start */}
      <section id="landingFAQ" className="py-5 landing-faq">
        <div className="container">
          <div className="text-center mb-3 pb-1">
            <span className="badge bg-label-primary">Frequently Asked Questions</span>
          </div>
          <h3 className="text-center mb-5">About Shaad Mobile Top-Up Services</h3>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row gy-5">
                {/* <div class="col-lg-5">
            <div class="text-center">
              <img src="assets/img/girl-3.png" alt="Shaad" class="faq-image" />
    
            </div>
          </div> */}
                <div className="col-lg-6">
                  <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">
                      <h2 className="accordion-header" id="headingOne">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="false" aria-controls="accordionOne">
                          What is Shaad?
                        </button>
                      </h2>
                      <div id="accordionOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Shaad is an emerging mobile top-up service provider dedicated to offering convenient and affordable solutions for recharging prepaid mobile phones globally. Our platform allows users to easily top up their own phones or send recharges to loved ones, all with just a few clicks.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                          How does Shaad work?
                        </button>
                      </h2>
                      <div id="accordionTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Shaad provides a user-friendly platform, accessible via our app or website, where users can select their desired mobile operator, enter the recipient's phone number, choose the amount to recharge, and proceed with the payment. Once secure payment is made, the mobile phone will be topped up instantly.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionThree" aria-expanded="false" aria-controls="accordionThree">
                          Is Shaad available worldwide?
                        </button>
                      </h2>
                      <div id="accordionThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Yes, Shaad's services are available globally. Whether you're in the Middle East, Asia, Africa, Europe, or the Americas, you can access Shaad's platform and send happiness through mobile top-ups wherever you are.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFour" aria-expanded="false" aria-controls="accordionFour">
                          What mobile operators does Shaad support?
                        </button>
                      </h2>
                      <div id="accordionFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Shaad supports a wide range of mobile operators across various countries. Our platform covers major operators, ensuring that you can recharge almost any prepaid mobile phone seamlessly.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFive" aria-expanded="false" aria-controls="accordionFive">
                          How can I pay for a mobile top-up on Shaad?
                        </button>
                      </h2>
                      <div id="accordionFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Shaad offers multiple payment options for your convenience, including credit/debit cards, PayPal, and other popular payment methods depending on your region.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion" id="accordionExample">
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionSix" aria-expanded="false" aria-controls="accordionSix">
                          Is Shaad secure to use?
                        </button>
                      </h2>
                      <div id="accordionSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Yes, Shaad prioritizes the security and privacy of our users' transactions. Our platform utilizes robust encryption protocols to safeguard your payment information and ensure a secure browsing experience. Please read the data security and privacy policy for more details.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionSeven" aria-expanded="false" aria-controls="accordionSeven">
                          Are there any additional fees or hidden charges when using Shaad?
                        </button>
                      </h2>
                      <div id="accordionSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Shaad is committed to transparency, and we strive to provide upfront pricing with no hidden fees. The amount you see during the transaction process is the total amount you'll pay, with no surprises.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionEight" aria-expanded="false" aria-controls="accordionEight">
                          How quickly will the mobile phone be topped up after payment?
                        </button>
                      </h2>
                      <div id="accordionEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">In most cases, the mobile phone will be topped up instantly after the payment is processed successfully. However, certain factors such as network delays or maintenance by the mobile operator may cause slight delays.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingNine">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionNine" aria-expanded="false" aria-controls="accordionNine">
                          Can I top up my mobile phone with Shaad while traveling abroad?
                        </button>
                      </h2>
                      <div id="accordionNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">Yes, you can use Shaad to top up your mobile phone while traveling abroad, as long as you have internet access. Simply log in to our app or website and proceed with the top-up process as usual.</div>
                      </div>
                    </div>
                    <div className="card accordion-item">
                      <h2 className="accordion-header" id="headingTen">
                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTen" aria-expanded="false" aria-controls="accordionTen">
                          What should I do if I encounter an issue with my mobile top-up transaction?
                        </button>
                      </h2>
                      <div id="accordionTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          If you encounter any issues during the top-up process or have any questions, our customer support team is available to assist you. You can reach out to us via email <a href="mailto:customer.care@shaadonline.com">customer.care@shaadonline.com</a> or live chat, and we'll promptly address your concerns to ensure a smooth experience.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ: End */}
      {/* CTA: Start */}
      <section id="landingCTA" className="section-py bg-body ">
        <div className="container">
          <div className="row align-items-center gy-5 gy-lg-0">
            <div className="col-lg-6 pt-lg-5 text-center">
              <img src="assets/img/app-screens.png" alt="Shaad App" className="img-fluid" />
            </div>
            <div className="col-lg-6 text-lg-start">
              <h6 className="h1 text-primary fw-bold mb-1">
                Instant Mobile Top-Ups, <br />
                Anytime, Anywhere
              </h6>
              <p className="fw-medium mb-4">Get the Shaad App for the fastest, easiest way to top- up.</p>
              <Link to="#" className="footer-link mb-3 pb-2 me-2">
                <img src="assets/img/App Store badge.png" alt="apple icon" />
              </Link>
              <Link to="#" className="footer-link">
                <img src="assets/img/Play Store badge.png" alt="google play icon" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
