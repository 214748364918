import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateNotifications } from "../../services/notificationService";

const initialState = {
  notificationDetails: [],
  isLoading: false,
  error: null,
};

export const updateNotificationApi = createAsyncThunk("notification/putNotificationApi", async (id) => {
  try {
    const response = await updateNotifications(id);
    return response
  } catch (error) {
    throw error;
  }
});

const notificationUpdateSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setInitNotificationDetail: (state) => {
      state.notificationDetails = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateNotificationApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateNotificationApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notificationDetails = action.payload;
    });

    builder.addCase(updateNotificationApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = notificationUpdateSlice;
export const { setInitNotificationDetail } = notificationUpdateSlice.actions;
export default reducer;