import Chart from "./Chart";
import ComparisionCard from "./ComparisionCard";
import StatusCard from "./StatusCard";
// import { fetchAdminDashboard } from "../../../services/configurationService";
import { errorToast } from "../../Toasts/Toasts";
import { formatCreditBalance, formatAbsoluteValue } from "../../../utils/sharedFunctions";
import { useEffect, useState } from "react";
import { getAggregatorStatus } from "../../../services/Admin";
import DateRangePicker from "react-bootstrap-daterangepicker";

const AdminDashboard = ({ dashboardRecord, setDateValue }) => {
  const [aggregatorStatus, setAggregatorStatus] = useState();

  useEffect(() => {
    fetchApiOnlineStatus();
    // Schedule the function to run every hour
    const intervalId = setInterval(fetchApiOnlineStatus, 600000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchApiOnlineStatus = async () => {
    try {
      const response = await getAggregatorStatus();

      if (response?.success) {
        setAggregatorStatus(response);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const handleEventReceiveDate = (event, picker) => {
    picker.element.val(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setDateValue(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelReceiveDate = (event, picker) => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; 
  
    picker.element.val(`${formattedToday} - ${formattedToday}`);
    setDateValue(`${formattedToday} - ${formattedToday}`);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-1 pt-1">Dashboard</h4>
        <div className="row" style={{ marginTop: "-20px" }}>
          <div className="col-lg-5">
            {aggregatorStatus ? (
              <div className="bg-label-success rounded-pill p-3 py-1">
                <i className="ti ti-point-filled" />
                <span className="w-100">API Online</span>
              </div>
            ) : (
              <div className="bg-label-danger rounded-pill p-3 py-1">
                <i className="ti ti-point-filled" />
                <span className="w-100">API Offline</span>
              </div>
            )}
          </div>
          <div className="col-lg-7">
          <DateRangePicker
            initialSettings={{
              autoUpdateInput: true,
              locale: { cancelLabel: "Clear", format: "YYYY-MM-DD" },
              showDropdowns: true,
              drops: "down",
            }}
            onApply={handleEventReceiveDate}
            onCancel={handleCancelReceiveDate}
          >
            <input type="text" id="dateRangePickerReceiveDate" className="form-control me-3" placeholder="Select Date" autoComplete="off" />
          </DateRangePicker>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-4 col-12 mb-4">
              <StatusCard color="badge rounded-pill bg-label-primary me-3 p-2" icon="ti ti-users ti-sm" value={dashboardRecord?.total_distributors ?? 0} list={dashboardRecord?.distributorList} label="Distributors" tooltipKey="name"/>
            </div>

            <div className="col-lg-5 col-12 mb-4">
              <StatusCard color="badge rounded-pill bg-label-warning me-3 p-2" icon="ti ti-wallet ti-sm" value={`${formatCreditBalance(dashboardRecord?.total_topup_sum ?? 0)}`} label="Total Top-Ups" currencyFormat="USD" topUpCount={`/ Count: ${formatCreditBalance(dashboardRecord?.total_topup_count ?? 0)}`} />
            </div>

            <div className="col-lg-3 col-12 mb-4">
              <StatusCard color="badge rounded-pill bg-label-info me-3 p-2" icon="ti ti-access-point ti-sm" value={dashboardRecord?.distinct_carriers_count ?? 0} list={dashboardRecord?.carriers} label="Carriers" tooltipKey="operator_name"/>
            </div>
            <div className="col-lg-4 col-12 mb-4">
              <StatusCard color="badge rounded-pill bg-label-primary me-3 p-2" icon="ti ti-world ti-sm" value={dashboardRecord?.distinct_countries_count ?? 0} list={dashboardRecord?.countries}  label="Countries" tooltipKey="name"/>
            </div>
            <div className="col-lg-4 col-12 mb-4">
              <StatusCard color="badge rounded-pill bg-label-warning me-3 p-2" icon="ti ti-building-store ti-sm" value={dashboardRecord?.total_retailers ?? 0}  list={dashboardRecord?.retailerList} label="Retailers" tooltipKey="name"/>
            </div>

            <div className="col-lg-4 col-12 mb-4">
              <StatusCard color="badge rounded-pill bg-label-success me-3 p-2" icon="ti ti-cash-banknote ti-sm" value={`${formatCreditBalance(dashboardRecord?.total_profit_amount ?? 0)}`} label="Total Profit" currencyFormat="USD" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 mb-4">
          <ComparisionCard secondaryText= "Balance Spent" text="Total Balance Remaining" textClassName="d-block text-muted mb-1" totalBalance={`${formatAbsoluteValue(dashboardRecord?.totalLiveBalance ?? 0)}`} currencyFormat="USD" firstCarrierName={dashboardRecord?.carriersAmount && dashboardRecord.carriersAmount[0]?.carrier_name} secondCarrierName={dashboardRecord?.carriersAmount && dashboardRecord.carriersAmount[1]?.carrier_name} firstCarrierBalance={`${formatCreditBalance((dashboardRecord?.carriersAmount && dashboardRecord.carriersAmount[0]?.total_carrier_amount) ?? 0)}`} secondCarrierBalance={`${formatCreditBalance((dashboardRecord?.carriersAmount && dashboardRecord.carriersAmount[1]?.total_carrier_amount) ?? 0)}`} />
        </div>
        
        <div className="col-lg-3 col-12 mb-4">
          <ComparisionCard text="Customers (from)" textClassName="d-block mb-2 h5" firstCarrierName="Retailer" secondCarrierName="Direct" firstCarrierBalance={`${formatCreditBalance(dashboardRecord?.total_retailer_topup_amount ?? 0)}`} secondCarrierBalance={`${formatCreditBalance(dashboardRecord?.total_customer_topup_amount ?? 0)}`} />
        </div>
      </div>
      <div className="row">
        <Chart dashboardRecord={dashboardRecord} />
      </div>
    </>
  );
};
export default AdminDashboard;
