import React from "react";
import ManageDashboardBody from "./ManageDashboardBody";
import StatusCard from "./StatusCard";
import { formatCreditBalance } from "../../../utils/sharedFunctions";
import DateRangePicker from "react-bootstrap-daterangepicker";

const ManageDashboard = ({ dashboardRecord, setDateValue }) => {

  const handleEventReceiveDate = (event, picker) => {
    picker.element.val(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setDateValue(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelReceiveDate = (event, picker) => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; 
  
    picker.element.val(`${formattedToday} - ${formattedToday}`);
    setDateValue(`${formattedToday} - ${formattedToday}`);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4>Dashboard</h4>
        <p>
          <DateRangePicker
            initialSettings={{
              autoUpdateInput: true,
              locale: { cancelLabel: "Clear", format: "YYYY-MM-DD" },
              showDropdowns: true,
              drops: "down",
            }}
            onApply={handleEventReceiveDate}
            onCancel={handleCancelReceiveDate}
          >
            <input type="text" id="dateRangePickerReceiveDate" className="form-control me-1" placeholder="Select Date" autoComplete="off" />
          </DateRangePicker>
        </p>
      </div>
      <div className="row">
        <div className="col-lg-7 mb-4 col-md-12">
          <StatusCard text="Retailer" icon="ti ti-building-store ti-sm" text1="Total Top-Ups" text2="Credit Sent" text3="Active Retailer" value1={`${formatCreditBalance(dashboardRecord?.total_topup_sum ?? 0)} `} value2={dashboardRecord?.TotalToPups ?? 0} value3={dashboardRecord?.activeRetailersCount ?? 0} topUpCount={`/ Count: ${formatCreditBalance(dashboardRecord?.total_topup_count ?? 0)}`} list={dashboardRecord?.activeRetailersList} tooltipKey="name" />
        </div>
        <div className="col-lg-5 mb-4 col-md-12">
          <StatusCard text="Self" icon="ti ti-cash-banknote ti-sm" text1="Live Balance" text2="Credit Spent" text3="Credit Received" value1={`${formatCreditBalance(dashboardRecord?.liveBalance ?? 0)}`} value2={`${formatCreditBalance(dashboardRecord?.total_credit_sent_or_spent ?? 0)}`} value3={`${formatCreditBalance(dashboardRecord?.total_credit_receive ?? 0)}`} currencyFormate="USD" />
        </div>
      </div>
      <div className="row">
        <ManageDashboardBody dashboardRecord={dashboardRecord} />
      </div>
    </>
  );
};

export default ManageDashboard;
