import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { replyQueryResponse } from "../../../services/helpAndSupportService";
import CustomAlert from "../CustomAlert";
import { postHelpAndSupportFilterApi } from "../../../redux/features/helpAndSupportSlice";
import { useDispatch } from "react-redux";

const ReplyNotResolved = ({ queryDetails, showNotResolvedBox, categoriesData, setShowNotResolvedBox, ticketId, currentPage, currentSize, tagId, emailView }) => {
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState([]);
  // const [categoryId, setCategoryId] = useState("");
  const [isFollowUpRequested, setIsFollowUpRequested] = useState(false);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const handleDescriptionChange = (content) => {
    const regex = /<p>(.*?)<\/p>/;
    const match = content?.match(regex);
    const extractedValue = match ? match[1] : null;
    setDescription(extractedValue === "<br>" ? "" : extractedValue);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    // Check if the selectedFiles array length exceeds 5
    if (selectedFiles.length + files.length > 5) {
      // Notify the user about the maximum number of files allowed
      errorToast("Maximum 5 files are allowed");
      return;
    }

    // Loop through each selected file
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // Check if file type is allowed
      if (!allowedTypes.includes(file.type)) {
        errorToast(`File type not allowed for ${file.name}`);
        continue;
      }
      // Check if file size is within the allowed limit
      if (file.size > maxSize) {
        errorToast(`File size exceeds 2 MB limit for ${file.name}`);
        continue;
      }

      setFiles((prevFiles) => [...prevFiles, file]);

      event.target.value = '';
    }
  };

  const deleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  async function getFilesBase64(files) {
    const base64Array = [];
    for (const file of files) {
      const base64String = await getFileBase64(file);
      base64Array.push({ fileName: file.name, fileType: file?.name?.split(".")?.pop(), base64: `data:${file?.type};base64,${base64String}` });
    }
    return base64Array;
  }

  async function getFileBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  useEffect(() => {
    const changeToBase64 = async () => {
      try {
        const base64Files = await getFilesBase64(files);
        setAttachment(base64Files);
      } catch (error) {
        console.error("Error converting files to base64:", error);
      }
    };

    changeToBase64();

    // eslint-disable-next-line
  }, [files]);

  const handleSendQuery = async () => {
    try {
    if (description !== "") {
      const requestData = {
        ticketId: ticketId,
        description: description,
        attachments: attachment,
        title: queryDetails?.ticketHistory[0]?.title
      };

      const sendQueryResponse = await replyQueryResponse(requestData);

      if (sendQueryResponse?.success) {
        successToast(sendQueryResponse?.message);
        setIsFollowUpRequested(true);
        dispatch(postHelpAndSupportFilterApi({ filteredData: { tag_id: tagId }, page: currentPage, size: currentSize }));
        emailView();
        setShowNotResolvedBox(!showNotResolvedBox);
      } else {
        errorToast("Failed to reply query");
        setShowNotResolvedBox(!showNotResolvedBox);
      }
    } else {
      errorToast("Please fill the required fields");
    }
  } catch(error) {
    errorToast(error?.response?.data?.message);
  }
  };

  return (
    <>
      {!isFollowUpRequested ? (
        <Modal show={showNotResolvedBox} size="lg" className="app-email-compose">
          <div className="modal-content p-0">
            <div className="modal-header py-3 bg-body d-flex justify-content-between">
              <h5 className="modal-title fs-5">Reply to Admin</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShowNotResolvedBox(!showNotResolvedBox);
                }}
              />
            </div>
            <div className="modal-body flex-grow-1 pb-sm-0 p-4 py-2">
              <form className="email-compose-form">
                {/* <div className="email-compose-subject d-flex align-items-center mb-2">
                <label htmlFor="email-subject" className="form-label mb-0">
                  Query:
                </label>
                <input type="text" onChange={handleTitle} className="form-control border-0 shadow-none flex-grow-1 mx-2" id="email-subject" placeholder="Query Title" />
              </div> */}
                <div className="email-compose-content container-m-nx">
                  <ReactQuill onChange={handleDescriptionChange} modules={{ toolbar: false }} placeholder="Write your message here..." />
                </div>
                <div className="file-input-container">
                  <input type="file" multiple onChange={handleFileChange} className="d-none" id="file-upload" />
                  <label htmlFor="file-upload" className="attach-file">
                    <i className="ti ti-paperclip cursor-pointer ms-2" />
                  </label>
                  <span>{files.length} file(s) selected</span>
                </div>
                <div className="selected-files-container">
                  {files.map((file, index) => (
                    <div key={index} className="file-item">
                      <span>{file.name}</span>
                      <button type="button" className="btn delete-file" onClick={() => deleteFile(index)}>
                        <i className="ti ti-trash" />
                      </button>
                    </div>
                  ))}
                </div>
                <hr className="container-m-nx mt-0 mb-2" />
                <div className="email-compose-actions d-flex justify-content-between align-items-center mt-3 mb-3">
                  <div className="d-flex align-items-center">
                    <div className="btn-group">
                      <button type="button" className="btn btn-sm rounded-pill btn-primary" onClick={handleSendQuery}>
                        <i className="ti ti-send ti-xs me-1" />
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      ) : (
        <CustomAlert showCustomeAlertModal={showNotResolvedBox} setShowCustomeAlertModal={setShowNotResolvedBox} text="Your request has been sent to Admin successfully." showClassName="text-success check info" iconClassName="ti ti-check fs-1" />
      )}
    </>
  );
};

export default ReplyNotResolved;
