import React, { useState } from "react";
import { Link } from "react-router-dom";
import SelectPaymentMethod from "../../Shared/SelectPaymentMethod";

const CheckoutAfterNotification = () => {

  const [showBox, setShowBox ] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const fetchedRechargeRequestDetailsJSON = localStorage.getItem("fetchedRechargeRequestDetails");

  const fetchedRechargeRequestDetails = JSON.parse(fetchedRechargeRequestDetailsJSON);

  const handleFinalAmount = () => {
    localStorage.setItem("rechargeThroughNotification", 1)
    setShowBox(true);
  };

  let amount = (Math.round(parseFloat(fetchedRechargeRequestDetails?.total_converted_amount) * 100) / 100).toFixed(2);

  return (
    <section id="hero-animation">
      <div id="landingHero" className="section-py landing-hero position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="top-up">
                <div className="authentication-wrapper authentication-basic container-p-y pt-0 mt-3">
                  <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2 pt-0">
                    <div className="card card-topup bg-outline-primary mt-0 sending-topup scrollbar">
                      <div className="card-header d-flex justify-content-center pb-2 text-center">
                        <div className="back-button-products back"></div>
                      </div>
                      <div className="row pb-3 border-bottom border-bottom-dashed">
                        <div className="row ps-5">
                          <div className="col-md-6 d-flex mb-3 justify-content-between">
                            <div className="d-flex">
                              <h6 className="mb-0 me-1"> {fetchedRechargeRequestDetails?.senderDetails?.phone_code + fetchedRechargeRequestDetails?.senderDetails?.phone_number} </h6>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex">
                            <img src={fetchedRechargeRequestDetails?.carrier_id === 2 ? "/assets/img/roshan.png" : "/assets/img/etisalat-lg.png"} alt={fetchedRechargeRequestDetails?.carrier_id === 2 ? "Roshan" : "Etisalat"} className="me-3" width="50" height="30" />     
                            <div className="align-items-left">
                              <h5 className="mb-0 me-1">
                                {fetchedRechargeRequestDetails?.carrier_name} <small> (carrier)</small>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center px-4">
                        <div className="col-md-12">
                          <div className="d-flex w-100 flex-wrap justify-content-between py-2 mt-3">
                            <div className="me-2">
                              <h5 className="mb-0">Amount</h5>
                            </div>
                            <div>
                              <div className="user-progress d-flex align-items-center gap-1">
                                <p className="mb-0 fw-medium h5">
                                  <span className="text-primary me-2">
                                    ({parseFloat(fetchedRechargeRequestDetails?.source_amount).toFixed(2)}
                                    <small>{fetchedRechargeRequestDetails?.source_currency}</small>)
                                  </span>
                                  <span className="h5">
                                    {parseFloat(fetchedRechargeRequestDetails?.converted_amount).toFixed(4)} <small>{fetchedRechargeRequestDetails?.converted_currency}</small>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                            <div className="me-2">
                              <h5 className="mb-0">Service Fee</h5>
                            </div>
                            <div className="user-progress d-flex align-items-center gap-1">
                              <p className="mb-0 fw-medium h5">
                                {parseFloat(fetchedRechargeRequestDetails?.service_fee).toFixed(4)} <small>USD</small>
                              </p>
                            </div>
                          </div>
                          <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                            <div className="me-2">
                              <h5 className="mb-0">VAT</h5>
                            </div>
                            <div className="user-progress d-flex align-items-center gap-1">
                              <p className="mb-0 fw-medium h5">
                                {parseFloat(fetchedRechargeRequestDetails?.vat).toFixed(4)} <small>USD</small>
                              </p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between border-top mt-2 pt-2">
                            <h4>Total</h4>
                            <div>
                              <p className=" h3">
                                <span className="text-primary me-2 fs-5">
                                  ({parseFloat(fetchedRechargeRequestDetails?.total_source_amount).toFixed(2)} <small>{fetchedRechargeRequestDetails?.source_currency}</small>)
                                </span>
                                {parseFloat(fetchedRechargeRequestDetails?.total_converted_amount).toFixed(4)} <small>{fetchedRechargeRequestDetails?.converted_currency}</small>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <Link to="#" onClick={handleFinalAmount} className="btn btn-md btn-primary rounded-pill mt-2 mb-4">
                            Checkout
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBox && <SelectPaymentMethod showBox= {showBox} setShowBox= {setShowBox} setSelectedValue={setSelectedValue} selectedValue={selectedValue} amount={amount} carrierId= {fetchedRechargeRequestDetails?.carrier_id} />}
    </section>
  );
};

export default CheckoutAfterNotification;
