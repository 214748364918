import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingStatusCards from "./BillingStatusCards";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import BillingViewDetailsModal from "./BillingViewDetailsModal";
import { getTransactionDetailApi, clearFilteredChips, setFilteredChips } from "../../../redux/features/transactionCreditSlice";
import moment from "moment";
import BillingTransactionUpdateModal from "./BillingTransactionUpdateModal";
import { chipName } from "../../../utils/sharedFunctions";
import { errorToast } from "../../Toasts/Toasts";

const BillingGrid = ({ selectedRoleTabId, paymentTypeTabId, roleId, userRoleConstants, creditedToOrBy, creditStatusType, setCurrentSize, currentSize, filteredChips, handleChipRemove, hasMoreData, setHasMoreData, location }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingForScrollbar, setLoadingForScrollbar] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateType, setUpdateType] = useState("Approve");
  const [transctionId, setTransactionId] = useState("");
  const [allTransactionDetails, setAllTransactionDetails] = useState({});
  const page = 1;

  const dispatch = useDispatch();

  const handleDetailsModalDisplay = (transactionDetail) => {
    setAllTransactionDetails(transactionDetail);
    setShowDetailsModal(!showDetailsModal);
  };

  useEffect(() => {
    setLoading(true);

    let filterData = {};

    if (roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) {
      filterData = {
        type: "credited_by",
        billing_type_id: paymentTypeTabId,
        ...(location?.state?.account_no?.trim() && { account_no: location?.state?.account_no.trim() }),   
      };
    } else if (roleId === userRoleConstants.RETAILER_ID) {
      filterData = {
        type: "credited_to",
        billing_type_id: selectedRoleTabId === 0 ? 1 : 2,
      };
    } else {
      filterData = {
        type: creditedToOrBy,
        billing_type_id: paymentTypeTabId,
        ...(location?.state?.account_no?.trim() && { account_no: location?.state?.account_no.trim() }),
      };
    }

    dispatch(getTransactionDetailApi({ filterData, page, size: currentSize }))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
     let  filteredChipsData = {'account_no':location?.state?.account_no};
     if(location?.state?.account_no) {dispatch(setFilteredChips(Object.entries(filteredChipsData)))}; 
    // eslint-disable-next-line
  }, [selectedRoleTabId, paymentTypeTabId, creditedToOrBy, dispatch]);

  useEffect(() => {
    if(!location?.state?.account_no) {dispatch(clearFilteredChips())};
    setCurrentResultSetLength(0);

    // eslint-disable-next-line
  }, [selectedRoleTabId, paymentTypeTabId, dispatch]);

  const transactionDetails = useSelector((state) => state?.transaction?.transactionDetails);
  const count = useSelector((state) => state?.transaction?.count);

  const handleShowModal = (data) => {
    setShowUpdateModal(!showUpdateModal);
    setUpdateType(data.type);
    setTransactionId(data?.transaction_id);
  };

  let filteredData = Object.fromEntries(filteredChips);

  let transformedFilterData = {};

  for (let key in filteredData) {
    if (typeof filteredData[key] === "object" && "label" in filteredData[key] && "value" in filteredData[key]) {
      transformedFilterData[key] = filteredData[key].value;
    } else {
      transformedFilterData[key] = filteredData[key];
    }
  }

  transformedFilterData.type = (roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) ? "credited_by" : roleId === userRoleConstants.RETAILER_ID ? "credited_to" : creditedToOrBy;
  transformedFilterData.billing_type_id = (roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) ? paymentTypeTabId : roleId === userRoleConstants.RETAILER_ID ? (selectedRoleTabId === 0 ? 1 : 2) : paymentTypeTabId;

  const loadMoreData = useCallback(async () => {
    if (loadingForScrollbar || !hasMoreData) return;
    setLoadingForScrollbar(true);
    const response = await dispatch(getTransactionDetailApi({ filterData: transformedFilterData, page, size: currentSize + 10 }));
    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload.records;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoadingForScrollbar(false);
    // eslint-disable-next-line
  }, [hasMoreData, currentSize, dispatch, page, filteredChips, creditedToOrBy, currentResultSetLength, filteredData, loadingForScrollbar, paymentTypeTabId, roleId, selectedRoleTabId, setCurrentSize, userRoleConstants.ADMIN_ID, userRoleConstants.RETAILER_ID]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("distributorUserComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("distributorUserComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  return (
    <>
      <div className="tab-content p-0 credit-tabs">
        {loading ? ( <div className="tr"><div className="td text">Loading.....</div></div> ) : (
          <div className="tab-pane fade active show" role="tabpanel">
            <BillingStatusCards selectedRoleTabId={selectedRoleTabId} paymentTypeTabId={paymentTypeTabId} roleId={roleId} userRoleConstants={userRoleConstants} count={count} transformedFilterData={transformedFilterData} page={page} currentSize={currentSize} />
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="card">
                  <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
                    <h5 className="card-title mb-0">{((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && (selectedRoleTabId === 0 || (selectedRoleTabId === 2 && paymentTypeTabId === 1))) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && paymentTypeTabId === 1) ? "Credits" : ((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && paymentTypeTabId === 2) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && paymentTypeTabId === 2) ? "Wallet" : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? "Credits Airtime" : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 2) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 1) ? "Wallet Airtime" : ""}</h5>
                    <div className="filter-card" style={{ display: (location?.state?.account_no && location?.state?.selectedRoleTabFilter !== selectedRoleTabId) ? "none" : "block" }}>
                      {filteredChips?.map(([key, value]) => (
                        <div className="chip" key={key}>
                          <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                          <span>
                            {chipName(key)} {":"} {key === "credit_status_type_id" ? value?.label : value}
                          </span>
                          <button type="button" className="zap">
                            <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <InfiniteScroll dataLength={transactionDetails.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="distributorUserComponent">
                    <div className="card-datatable table-responsive pb-0" id="distributorUserComponent" style={{ height: "400px", overflowY: "auto" }}>
                      <table className="table">
                        <thead>
                          <tr className="align-baseline">
                            {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? <th>S. No.</th> : ""}
                            {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <th>Transaction ID</th> : ""}
                            {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID) && (selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <th>Account No.</th> : ""}
                            {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <th>Distributor Name</th> : roleId === userRoleConstants.DISTRIBUTOR_ID && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <th>Retailer Name</th> : ""}
                            {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? ( <th>Amount <small>(USD)</small></th> ) : ( "" )}    
                            {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.RETAILER_ID || roleId === userRoleConstants.DISTRIBUTOR_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? <th width="10%">Request Date</th> : ""}
                            {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <th width="10%">Credit Date</th> : ""}
                            {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && paymentTypeTabId === 1) || (roleId === userRoleConstants.DISTRIBUTOR_ID && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? <th width="10%">Due Date</th> : ""}
                            {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && paymentTypeTabId === 1) || (roleId === userRoleConstants.DISTRIBUTOR_ID && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? <th width="10%">Payment Date</th> : ""}
                            {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? <th>Status</th> : ""}
                            {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && ((selectedRoleTabId === 0 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || (selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)))) || (roleId === userRoleConstants.DISTRIBUTOR_ID && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? <th width="8%">Action</th> : ""}
                          </tr>
                        </thead>
                        {!((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 0) && (
                          <tbody>
                            {transactionDetails?.length !== 0 ? (
                              transactionDetails?.map((transactionDetail, index) => (
                                <tr key={index}>
                                  {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? <td>{index + 1}</td> : ""}
                                  {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? (
                                    <td>
                                      {" "}
                                      <Link to="" onClick={() => { handleDetailsModalDisplay(transactionDetail); }} >
                                        {" "} {transactionDetail?.transaction_id}{" "}
                                      </Link>{" "}
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <td>{transactionDetail?.account_no}</td> : ""}
                                  {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <td>{transactionDetail?.name}</td> : (roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <td>{transactionDetail?.name}</td> : ""}
                                  {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <td>{transactionDetail?.amount}</td> : ""}
                                  {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.RETAILER_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? <td>{moment(transactionDetail?.request_date, moment.ISO_8601).format('DD MMM YY  hh:mm A') !== "Invalid date" ? <><div>{moment.utc(transactionDetail?.request_date).format('DD MMM YY')}</div><small className="text-muted">{moment.utc(transactionDetail?.request_date, moment.ISO_8601).format('hh:mm A')}</small></>  : "--"}</td> : ""}
                                  {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <td> {moment(transactionDetail?.credit_date, moment.ISO_8601).format('DD MMM YY  hh:mm A') !== "Invalid date" ? <><div>{moment.utc(transactionDetail?.credit_date).format('DD MMM YY')}</div><small className="text-muted">{moment.utc(transactionDetail?.credit_date, moment.ISO_8601).format('hh:mm A')}</small></> : "--"} </td> : ""}
                                  {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && paymentTypeTabId === 1) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? <td> {moment(transactionDetail?.due_date, moment.ISO_8601).format('DD MMM YY  hh:mm A') !== "Invalid date" ? <><div>{moment.utc(transactionDetail?.due_date).format('DD MMM YY')}</div><small className="text-muted">{moment.utc(transactionDetail?.due_date, moment.ISO_8601).format('hh:mm A')}</small></> : "--"} </td> : ""}
                                  {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && paymentTypeTabId === 1) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? <td> {moment(transactionDetail?.payment_date, moment.ISO_8601).format('DD MMM YY  hh:mm A') !== "Invalid date" ? <><div>{moment.utc(transactionDetail?.payment_date).format('DD MMM YY')}</div><small className="text-muted">{moment.utc(transactionDetail?.payment_date, moment.ISO_8601).format('hh:mm A')}</small></> : "--"} </td> : ""}
                                  {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? (
                                    <td>
                                      {" "}
                                      <span className={transactionDetail?.credit_status_type_name === "Pending" ? "badge px-2 bg-label-warning" : transactionDetail?.credit_status_type_name === "Requested" ? "badge px-2 bg-label-info" : transactionDetail?.credit_status_type_name === "Approved" || transactionDetail?.credit_status_type_name === "Paid" ? "badge px-2 bg-label-success" : transactionDetail?.credit_status_type_name === "Declined" ? "badge px-2 bg-label-danger" : transactionDetail?.credit_status_type_name === "Unpaid" ? "badge px-2 bg-label-warning" : ""}>{transactionDetail?.credit_status_type_name === 'Approved' && !transactionDetail?.request_date ?  'Direct-Approved' : transactionDetail?.credit_status_type_name}</span>{" "}
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && ((selectedRoleTabId === 0 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || (selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)))) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID)  && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? (
                                    <td>
                                      {" "}
                                      {transactionDetail?.credit_status_type_name === "Pending" || transactionDetail?.credit_status_type_name === "Requested" ? (
                                        <>
                                          <Link to="" onClick={() => { handleShowModal({ type: "Approve", transaction_id: transactionDetail?.transaction_id }); }} className="me-3" title="Approve" >
                                            <i className="ti ti-file-check" />
                                          </Link>
                                          <Link to="" title="Decline" onClick={() => { handleShowModal({ type: "Decline", transaction_id: transactionDetail?.transaction_id }); }} >
                                            <i className="ti ti-file-x" />
                                          </Link>
                                        </>
                                      ) : (transactionDetail?.credit_status_type_name === "Unpaid" && !(selectedRoleTabId === 1 && paymentTypeTabId === 2 && (roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID)) && !(selectedRoleTabId === 2 && paymentTypeTabId === 2 && (roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID)) ) ? (
                                        <>
                                          {/* <Link to="" className="me-3" title="Resend Invoice">
                                            <i className="ti ti-file-invoice " />
                                          </Link> */}
                                          {!((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 0 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) && (
                                            <Link to="" onClick={() => { handleShowModal({ type: "Paid", transaction_id: transactionDetail?.transaction_id }); }} title="Payment Receive" >
                                              <i className="ti ti-cash" />
                                            </Link>
                                          )}
                                        </>
                                      ) : transactionDetail?.credit_status_type_name === "Declined" ? ( "--" ) : ( "--" )}{" "}
                                    </td>
                                  ) : ( "" )}
                                </tr>
                              ))
                            ) : ( <h5 className="pt-3 ps-3">No record found</h5> )}
                            {loadingForScrollbar && ( <div className="tr"> {" "} <div className="td text">Loading.....</div>{" "} </div> )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDetailsModal && <BillingViewDetailsModal setShowDetailsModal={setShowDetailsModal} showDetailsModal={showDetailsModal} allTransactionDetails={allTransactionDetails} selectedRoleTabId={selectedRoleTabId} paymentTypeTabId={paymentTypeTabId} roleId={roleId} userRoleConstants={userRoleConstants} />}
        {showUpdateModal && <BillingTransactionUpdateModal showUpdateModal={showUpdateModal} updateType={updateType} setShowUpdateModal={setShowUpdateModal} transctionId={transctionId} creditStatusType={creditStatusType} transformedFilterData={transformedFilterData} page={page} currentSize={currentSize} />}
      </div>
    </>
  );
};

export default BillingGrid;