import React from "react";
import { useForm } from "react-hook-form";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { registerEmailOrPhoneNumber } from "../../../services/userService";
import { useLocation, useNavigate } from "react-router-dom";

const RequestOtp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const location = useLocation();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const button = document.getElementById("btnLogin");
    try {
      button.disabled = true;
      let obj = {
        email : data?.userEmailOrPhone,
        loginType : "customer"
      }
      let response = await registerEmailOrPhoneNumber(obj);
      button.disabled = false;
      if (response?.success) {
        successToast(response?.message);
        let verifyOtpPath = "";
        if (location.pathname.startsWith("/customer/register/request-otp")) {
          verifyOtpPath = "/customer/register/verify-otp";
        }
        else{
          verifyOtpPath="/verify-otp"
        }

        // Navigate to the appropriate path
        navigate(verifyOtpPath, { state: { userEmailOrPhone: data?.userEmailOrPhone, loginType: "customer" } });
  
      } else {
        errorToast(response?.message);
        button.disabled = false;
      }
    } catch (error) {
      errorToast(error?.message);
      button.disabled = false;
    }
  
  };
  
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
            <div className="card card-topup bg-outline-primary mt-4">
              <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                <div className="w-px-400 mx-auto">
                  <div className="card-body email-details" style={{ display: "block" }}>
                    <h3 className="mb-5 text-center">Register to continue</h3>
                    <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label htmlFor="userEmailOrPhone" className="form-label">
                          Email 
                        </label>
                        <input
                          type="text"
                          className={`form-control rounded-pill ${errors.userEmailOrPhone ? "is-invalid" : ""}`}
                          id="userEmailOrPhone"
                          {...register("userEmailOrPhone", {
                            required: "This field is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          placeholder="Enter your email"
                        />
                        {errors.userEmailOrPhone && <div className="text-danger">{errors.userEmailOrPhone.message}</div>}{" "}
                      </div>
                      <button id="btnLogin" type="submit" className="btn btn-primary w-100 text-white d-grid rounded-pill">
                        Request OTP
                      </button>
                    
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestOtp;
