import { axios } from "../utils/axios";
import ApiConstants from "../utils/apiConstants";
import { errorToast } from "../components/Toasts/Toasts";
import { billingType } from "../utils/constants";

const baseUrl = process.env.REACT_APP_API_HOST;

export const userLogin = async (userCred) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER_LOGIN}`, userCred);
    return response;
  } catch (error) {
    throw error;
  }
};

export const userForgotPassword = async (obj) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER_FORGOT_PASSWORD}`, obj);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const userFilter = async (filterData, page, size) => {
  try {
    let url = `${baseUrl}/${ApiConstants.USER_FILTER}?page=${page}&size=${size}`;
    const queryParams = new URLSearchParams(filterData).toString();

    if (queryParams) {
      url += `&${queryParams}`;
    }
    
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const userVerifyOtp = async (obj) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER_VERIFY_OTP}`, obj);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const userSetPassword = async (obj) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER_SET_PASSWORD}`, obj);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const userResendOtp = async (obj) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER_RESEND_OTP}`, obj);
    return response;
  } catch (error) {
    errorToast(error?.response?.data?.message[0]?.message);
    return error.response.data;
  }
};

export const addUser = async (userData) => {
  try { 
    if(userData?.billing_type_id === billingType?.WALLET) {
      delete userData?.credit_limit
      delete userData?.credit_day
    }
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editUser = async (id, userData) => {
  try {
    let response = await axios.put(`${baseUrl}/${ApiConstants.USER}/${id}`, userData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const userProfile = async () => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.USER_PROFILE}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getMasterData = async (id) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.MASTER_DATA}?countryCode=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
}

export const getUserProfileByAccountNo = async (id,data) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.USERS_ACCOUNT_NO}/${id}?dateRangeConstant=${data}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const registerEmailOrPhoneNumber = async (obj) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.REGISTER_CUSTOMER}`, obj);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const SendEmailMobileOtp = async(obj) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER}/email-mobile`, obj);
    return response;
  } catch (error) {
    return error.response.data;
  }
}

export const verifyEmailOrPhoneNumberOtp = async (obj) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.USER}/email-mobile/verify`, obj);
    return response;
  } catch (error) {
    return error.response.data;
  }
}

export const getCarrier = async (id) => {
  try {
    let response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMER}/carriers`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
}

export const getMfa = async() => {
  try {
    let response = await axios.get(`${baseUrl}/mfa`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
}

export const validateOtpForMfa = async(obj) => {
  try {
    let response = await axios.post(`${baseUrl}/${ApiConstants.VALIDATE_MFA}`,obj);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
}

export const verifyMfa = async(obj) => {
  try {
    let response = await axios.post(`${baseUrl}/${ApiConstants.VERIFY_MFA}`,obj);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
}