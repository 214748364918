import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import SendQuery from "./SendQuery";
import { getQueryCategories, getQueryDetails, getQueryDetailsForAll, getQueryDetailsForParticularId, queryResponse, requestFollowUp, updateStatus } from "../../../services/helpAndSupportService";
import CustomAlert from "../CustomAlert";
import { errorToast, successToast } from "../../Toasts/Toasts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";

const CustomerModal = ({typeId, ticketId , showCustomerSupportModal, setShowCustomerSupportModal }) => {
  const [files, setFiles] = useState([]);
  const [showQuery, setShowQuery] = useState(false);
  const [description, setDescription] = useState("");
  const [accordion, setAccordion] = useState(false);
  const [categoriesData, setCategoriesData] = useState();
  const [selectedAccordion, setSelectedAccordion] = useState("");
  const [isFollowUpRequested, setIsFollowUpRequested] = useState(false);
  const [queryDetailsForNotification, setQueryDetailsForNotification] = useState();
  const [helpAndSupportHistory, setHelpAndSupportHistory] = useState();
  const [replyToQuery, setReplyToQuery] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [isFollow, setIsFollow] = useState(false);
  const [allDetailsForHealthAndSupport, setAllDetailsForHealthAndSupport] = useState([]);
  const [ind, setIndex] = useState("");

  const handleQuery = () => {
    setShowQuery(!showQuery);
  };
  const handleCloseModal = () => {
    setShowCustomerSupportModal(!showCustomerSupportModal);
  };

  const handleAccordion = (index) => {
    if (index === selectedAccordion || selectedAccordion === "") {
      setAccordion(!accordion);
      setSelectedAccordion(index);
    } else {
      setAccordion(true);
      setSelectedAccordion(index);
    }
  };

  useEffect(() => {
    const queryCategories = async () => {
      try {
        const response = await getQueryCategories();

        if (response) {
          setCategoriesData(response);
        }
      } catch (error) {}
    };

    const getQuery = async () => {
      try {
        if (typeId === 12 || typeId === 13) {
          const response = await getQueryDetailsForParticularId(ticketId);
          const historyResponse = await getQueryDetails(ticketId);

          if (response) {
            setQueryDetailsForNotification(response?.data);
            setHelpAndSupportHistory(historyResponse?.data);
          }
        }
      } catch(error) {
        errorToast(error?.response?.data?.message);
      }
    };

    const displayOfFollowUp = async () => {
      const lastUpdateDate = new Date(queryDetailsForNotification?.follow_up_last_update);
      const today = new Date();
      const timeDiff = today - lastUpdateDate;
      const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (diffDays <= 2 && queryDetailsForNotification?.follow_up_count < 2) {
        setIsFollow(true);
      } else {
        setIsFollow(false);
      }
    };

    const getAllQueries = async () => {
      try{
      const allDetails = await getQueryDetailsForAll();
      setAllDetailsForHealthAndSupport(allDetails?.data);
      } catch (error) {
        errorToast(error?.response?.data?.message);
      }
    };

    getQuery();
    queryCategories();
    displayOfFollowUp();
    getAllQueries();

    // eslint-disable-next-line
  }, []);

  const handleFollowUpRequest = async (id) => {
    try {
      const response = await requestFollowUp({ ticket_id: id });
      if (response?.success) {
        setIsFollowUpRequested(true);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const handleResolved = async (ticketId) => {
    try{
    const obj = {
      ticketId: ticketId,
      isResolved: true,
    };

    const response = await updateStatus(obj);

    if (response) {
      setIsFollowUpRequested(!isFollowUpRequested);
    }} catch(error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const handleNotResolved = async (ticketId) => {
    try{
    const obj = {
      ticketId: ticketId?.ticket_id,
      isResolved: false,
    };

    const response = await updateStatus(obj);

    if (response) {
      setIndex(ticketId?.index)
      setReplyToQuery(true);
    }
  } catch (error) {
    errorToast(error?.response?.data?.message);
  }
  };

  const handleDescriptionChange = (content) => {
    const regex = /<p>(.*?)<\/p>/;
    const match = content?.match(regex);
    const extractedValue = match ? match[1] : null;
    setDescription(extractedValue === "<br>" ? "" : extractedValue);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    // Check if the selectedFiles array length exceeds 5
    if (selectedFiles.length + files.length > 5) {
      // Notify the user about the maximum number of files allowed
      errorToast("Maximum 5 files are allowed");
      return;
    }

    // Loop through each selected file
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // Check if file type is allowed
      if (!allowedTypes.includes(file.type)) {
        errorToast(`File type not allowed for ${file.name}`);
        continue;
      }
      // Check if file size is within the allowed limit
      if (file.size > maxSize) {
        errorToast(`File size exceeds 2 MB limit for ${file.name}`);
        continue;
      }

      setFiles((prevFiles) => [...prevFiles, file]);

      event.target.value = '';
    }
  };

  const deleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSendQuery = async () => {
    const button = document.getElementById('saveButton');
    try {
      if (queryDetailsForNotification?.id !== "" && description !== "") {
        const requestData = {
          ticketId: queryDetailsForNotification?.id,
          description: description,
          attachments: attachment,
          title: helpAndSupportHistory?.ticketHistory[0]?.title
        };

        button.disabled = true;
        const sendQueryResponse = await queryResponse(requestData);

        if (sendQueryResponse?.success) {
          successToast("Replied successfully to the query");
          setShowCustomerSupportModal(!showCustomerSupportModal);
          button.disabled = false;
        } else {
          errorToast("Failed to reply query");
          setShowCustomerSupportModal(!showCustomerSupportModal);
          button.disabled = false;
        }
      }
    } catch (error) {
      errorToast("Failed to reply query");
      setShowCustomerSupportModal(!showCustomerSupportModal);
      button.disabled = false;
    }
  };

  async function getFilesBase64(files) {
    const base64Array = [];
    for (const file of files) {
      const base64String = await getFileBase64(file);
      base64Array.push({ fileName: file.name, fileType: file?.name?.split(".")?.pop(), base64: `data:${file?.type};base64,${base64String}` });
    }
    return base64Array;
  }

  async function getFileBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  useEffect(() => {
    const changeToBase64 = async () => {
      try {
        const base64Files = await getFilesBase64(files);
        setAttachment(base64Files);
      } catch (error) {}
    };
    changeToBase64();
    
    // eslint-disable-next-line
  }, [files]);

  return (
    <>
      {!isFollowUpRequested ? (
        !showQuery ? (
          <Modal show={showCustomerSupportModal} size="lg" centered>
            <div className="modal-simple">
              {
                <div className="modal-content p-3 p-md-3">
                  <div className="modal-body py-3 bg-body" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h5 className="modal-title fs-5">Help &amp; Support</h5>
                    {typeId === 12 || typeId === 13 ? (
                      <button className="btn btn-sm btn-outline-primary" onClick={handleQuery}>
                        <i className="ti ti-plus" /> Query
                      </button>
                    ) : allDetailsForHealthAndSupport[0]?.tag_name?.toLowerCase() !== "open" || allDetailsForHealthAndSupport[1]?.tag_name?.toLowerCase() !== "open" ? (
                      <button className="btn btn-sm btn-outline-primary" onClick={handleQuery}>
                        <i className="ti ti-plus" /> Query
                      </button>
                    ) : (
                      ""
                    )}
                    <button type="button" className="btn-close" onClick={handleCloseModal} />
                  </div>
                  <div className="modal-body flex-grow-1 pb-sm-0 p-0 py-2" id="distributorUserComponent">
                    {typeId === 12 || typeId === 13 ? (
                      <form className="email-compose-form p-3">
                        <React.Fragment key={queryDetailsForNotification?.id}>
                          <Link to="#" className="last-query">
                            <div className="d-flex justify-content-between bg-lighter-blue p-2 mb-2">
                              <div className="card-title">
                                <small className="d-block mb-0 text-dark fs-6">Details</small>
                                <p className="card-title mb-1">
                                  <span className="text-muted fs-6">Query No.: </span>
                                  <span className="h5">{queryDetailsForNotification?.id} </span> 
                                  <span className={`badge rounded-pill me-0 mb-0 ${queryDetailsForNotification?.category_name?.toLowerCase() === "top-up related" ? "bg-label-info" : queryDetailsForNotification?.category_name?.toLowerCase() === "platform related" ? "bg-label-warning" : "bg-label-danger"} capital`}>{(queryDetailsForNotification?.category_name)}</span>                                 
                                </p>
                                <small>{moment(queryDetailsForNotification?.updatedAt, moment.ISO_8601).format("DD MMM YY, hh:mm A")}</small>
                              </div>
                              <div className="last-query-status">
                                <div className={`badge bg-label-${queryDetailsForNotification?.tag_name?.toLowerCase() === "open" ? "warning" : queryDetailsForNotification?.tag_name?.toLowerCase() === "pending" ? "warning" : queryDetailsForNotification?.tag_name?.toLowerCase() === "solved" ? "success" : queryDetailsForNotification?.tag_name?.toLowerCase() === "re_open" ? "warning" : queryDetailsForNotification?.tag_name?.toLowerCase() === "closed" ? "danger" : ""} rounded-pill me-0 mb-2`}>{queryDetailsForNotification?.tag_name}</div>
                                <p className="mb-0 mt-2">
                                  {" "}
                                  <span
                                    onClick={() => {
                                      handleAccordion(queryDetailsForNotification?.id);
                                    }}
                                    className="badge bg-label-secondary rounded-pill"
                                  >
                                    <i className={`ti arrow-icon ti-arrow-narrow-${queryDetailsForNotification?.id !== selectedAccordion || !accordion ? "right" : "left"}`} />
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </React.Fragment>
                        {helpAndSupportHistory?.ticketHistory?.map((queryDetails, index) => (
                          <div
                            key={queryDetails?.id}
                            className="show-query"
                            style={{
                              display: queryDetails?.ticket_id !== selectedAccordion || !accordion ? "none" : "block",
                            }}
                          >
                            <hr className="container mt-0 mb-2" />
                            <ul className="list-group list-group-flush px-0">
                              <li className="list-group-item list-group-item-action dropdown-notifications-item px-2">
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <h6 className="mb-1">{queryDetails?.title}</h6>
                                    <p className="mb-0">{queryDetails?.description}</p>
                                    <small className="text-muted">{moment(queryDetails?.updatedAt, moment.ISO_8601).format("DD MMM YY, hh:mm A")}</small>
                                  </div>
                                </div>
                                {typeId === 12 && queryDetailsForNotification?.tag_name?.toLowerCase() === "solved" && index === helpAndSupportHistory?.ticketHistory?.length - 1 && (
                                  <div className="col-12 text-end mt-2">
                                    <span
                                      type="button"
                                      className="btn btn-sm btn-label-secondary rounded-pill btn-reset not-resolved"
                                      onClick={() => {
                                        handleNotResolved({ ticket_id: queryDetails?.ticket_id, index: index });
                                      }}
                                    >
                                      Not Resolved
                                    </span>
                                    <span
                                      type="button"
                                      className="btn btn-sm btn-primary rounded-pill me-sm-3 me-1"
                                      onClick={() => {
                                        handleResolved(queryDetails?.ticket_id);
                                      }}
                                    >
                                      Resolved
                                    </span>
                                  </div>
                                )}
                                {replyToQuery && typeId === 12 && queryDetails?.role === 1 && queryDetailsForNotification?.tag_name?.toLowerCase() === "solved" && index === ind && (
                                  <div>
                                    <div className="modal-header py-3 d-flex justify-content-between">
                                      <div className="choose-category-query"></div>
                                    </div>
                                    <div className="modal-body flex-grow-1 pb-sm-0 p-4 py-2">
                                      <form className="email-compose-form">
                                        <div className="email-compose-content container-m-nx mb-2">
                                          <ReactQuill onChange={handleDescriptionChange} modules={{ toolbar: false }} placeholder="Write your message here..." />
                                        </div>
                                        <div className="file-input-container">
                                          <input type="file" multiple onChange={handleFileChange} className="d-none" id="file-input" />
                                          <label htmlFor="file-input" className="attach-file">
                                            <i className="ti ti-paperclip cursor-pointer ms-2" />
                                          </label>
                                          <span>{files.length} file(s) selected</span>
                                        </div>
                                        <div className="selected-files-container" style={{paddingLeft:'1.5%'}}>
                                          {files.map((file, index) => (
                                            <div key={index} className="file-item">
                                              <span>{file.name}</span>
                                              <button type="button" className="btn delete-file" onClick={() => deleteFile(index)}>
                                                <i className="ti ti-trash" />
                                              </button>
                                            </div>
                                          ))}
                                        </div>
                                        <hr className="container-m-nx mt-2 mb-2" />
                                        <div className="email-compose-actions d-flex justify-content-between align-items-center mt-3 mb-3">
                                          <div className="d-flex align-items-center">
                                            <div className="btn-group">
                                              <button type="button" className="btn btn-sm rounded-pill btn-primary" onClick={handleSendQuery} id="saveButton">
                                                <i className="ti ti-send ti-xs me-1" />
                                                Send
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                )}
                              </li>
                            </ul>
                            {typeId !== 12 && isFollow && (
                              <p className="mt-3 mb-3">
                                <span
                                  style={{ color: "#1E90CA", cursor: "pointer" }}
                                  onClick={() => {
                                    handleFollowUpRequest(queryDetails?.ticket_id);
                                  }}
                                >
                                  {" "}
                                  Request a Follow Up
                                </span>
                              </p>
                            )}
                          </div>
                        ))}

                        <div className="send-message ">
                          <div className="email-compose-actions d-flex justify-content-between align-items-center mt-3 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="btn-group">
                                <button type="reset" className="btn btn-sm btn-primary rounded-pill" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#submitquery">
                                  <i className="ti ti-send ti-xs me-1" />
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form className="email-compose-form p-3">
                        {allDetailsForHealthAndSupport?.map((allDetails) => (
                          <React.Fragment key={allDetails?.id}>
                            <Link to="#" className="last-query">
                              <div className="d-flex justify-content-between bg-lighter-blue p-2 mb-2">
                                <div className="card-title">
                                  <small className="d-block mb-0 text-dark fs-6">Details</small>
                                  <p className="card-title mb-1">
                                    <span className="text-muted fs-6">Query No.: </span>
                                    <span className="h5">{allDetails?.id} </span>
                                    <span className={`badge rounded-pill me-0 mb-0 ${allDetails?.category_name?.toLowerCase() === "top-up related" ? "bg-label-info" : allDetails?.category_name?.toLowerCase() === "platform related" ? "bg-label-warning" : "bg-label-danger"} capital`}>{(allDetails?.category_name)}</span>                                  
                                  </p>
                                  <small>{moment(allDetails?.CommunicationLogs[0]?.updatedAt, moment.ISO_8601).format("DD MMM YY, hh:mm A")}</small>
                                </div>
                                <div className="last-query-status">
                                  <div className={`badge bg-label-${allDetails?.tag_name?.toLowerCase() === "open" ? "warning" : allDetails?.tag_name?.toLowerCase() === "pending" ? "warning" : allDetails?.tag_name?.toLowerCase() === "solved" ? "success" : allDetails?.tag_name?.toLowerCase() === "re_open" ? "warning" : allDetails?.tag_name?.toLowerCase() === "closed" ? "danger" : ""} rounded-pill me-0 mb-2`}>{allDetails?.tag_name}</div>
                                  <p className="mb-0 mt-2">
                                    {" "}
                                    <span
                                      onClick={() => {
                                        handleAccordion(allDetails?.id);
                                      }}
                                      className="badge bg-label-secondary rounded-pill"
                                    >
                                      <i className={`ti arrow-icon ti-arrow-narrow-${allDetails?.id !== selectedAccordion || !accordion ? "right" : "left"}`} />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <div className="show-query" style={{ display: allDetails?.id !== selectedAccordion || !accordion ? "none" : "block" }}>
                              <hr className="container mt-0 mb-2" />
                              <ul className="list-group list-group-flush px-0">
                                <li className="list-group-item list-group-item-action dropdown-notifications-item px-2">
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <h6 className="mb-1">{allDetails?.CommunicationLogs[0]?.title}</h6>
                                      <p className="mb-0">{allDetails?.CommunicationLogs[0]?.description}</p>
                                      <small className="text-muted">{moment(allDetails?.CommunicationLogs[0]?.updatedAt, moment.ISO_8601).format("DD MMM YY, hh:mm A")}</small>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              {typeId !== 12 && isFollow && (
                                <p className="mt-3 mb-3">
                                  <span
                                    style={{ color: "#1E90CA", cursor: "pointer" }}
                                    onClick={() => {
                                      handleFollowUpRequest(allDetails?.id);
                                    }}
                                  >
                                    {" "}
                                    Request a Follow Up
                                  </span>
                                </p>
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                        <div className="send-message ">
                          <div className="d-flex justify-content-end">
                            <select className="form-select form-select-sm w-px-150 mb-2">
                              <option value="">Category</option>
                              <option value="">Top-Up Related</option>
                              <option value="">Platform Related</option>
                              <option value="">Others</option>
                            </select>
                          </div>
                          <div className="email-compose-actions d-flex justify-content-between align-items-center mt-3 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="btn-group">
                                <button type="reset" className="btn btn-sm btn-primary rounded-pill" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#submitquery">
                                  <i className="ti ti-send ti-xs me-1" />
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              }
            </div>
          </Modal>
        ) : (
          <SendQuery showQueryBox={showCustomerSupportModal} categoriesData={categoriesData?.data} setShowQueryBox={setShowCustomerSupportModal} />
        )
      ) : (
        <CustomAlert showCustomeAlertModal={showCustomerSupportModal} setShowCustomeAlertModal={setShowCustomerSupportModal} text="Your request has been sent to Admin successfully." showClassName="text-success check info" iconClassName="ti ti-check fs-1" />
      )}
    </>
  );
};

export default CustomerModal;
