import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { addUser, SendEmailMobileOtp, verifyEmailOrPhoneNumberOtp } from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { useDispatch } from "react-redux";
import { postUserFilterApi } from "../../../redux/features/userSlice";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";

const RetailerAddModal = ({ retailerModalType, retailerAddEditModalShow, setRetailerAddEditModalShow, masterData, setId, currentSize, filteredChips }) => {
  const dispatch = useDispatch();
  let page = 1;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCodeError, setPhoneCodeError] = useState(false);
  const [billingType, setBillingType] = useState(null);
  const [showEmailBox, setShowEmailBox] = useState(true);
  const [showNumberBox, setShowNumberBox] = useState(true);
  const [showEmailOtpBox, setShowEmailOtpBox] = useState(false);
  const [showNumberOtpBox, setShowNumberOtpBox] = useState(false);
  const [numberOtp, setNumberOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [numberDisabled, setNumberDisabled] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);
  const [numberVerified, setNumberVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNumber, setLoadingNumber] = useState(false);
  const RoleId = parseInt(localStorageStore.getRole());

  const countryOptions = () => {
    return masterData?.countries?.map((data) => ({
      label: data?.name,
      value: {
        code: data?.countryCode,
        id: data?.id,
      },
    }));
  };

  const handleState = (event) => {
    setId(event?.value?.code);
    setValue("retailer_state", null);
  };

  const stateOptions = () => {
    return masterData?.states?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const billingTypeOption = () => {
    return masterData?.billingType?.map((data) => ({
      label: data?.name,
      value: data?.billing_type_id,
    }));
  };

  const creditDayOption = Array.from({ length: 28 }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  }));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  const registerOptions = {
    retailer_name: {
      required: "Required",
      pattern: { value: /^\S[A-Za-zÀ-ȕ0-9 ]/, message: "Invalid input" },
    },
    retailer_email: {
      required: "Required",
      pattern: {
        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        message: "Invalid input",
      },
    },
    retailer_address: {
      required: "Required",
    },
    retailer_country: {
      required: "Required",
    },
    retailer_city: {
      required: "Required",
    },
    retailer_state: {
      required: "Required",
    },
    retailer_commission: {
      required: "Required",
      pattern: { value: /^[0-9]\d{0,9}(\.\d{1,3})?%?$/, message: "Invalid input" },
    },
    retailer_zip: {
      required: "Required",
    },
  };

  const handleClose = () => setRetailerAddEditModalShow(false);

  const handleAddEditRetailer = async (data) => {
    let retailerInfo = {
      name: data?.retailer_name,
      email: data?.retailer_email,
      commission: parseFloat(data?.retailer_commission),
      address: data?.retailer_address,
      phone_number: phoneNumber,
      city: data?.retailer_city,
      state_id: data?.retailer_state?.value,
      country_id: data?.retailer_country?.value?.id,
      zip: data?.retailer_zip,
      phone_code: phoneCode,
      credit_day: data?.credit_day?.value,
      billing_type_id: data?.billing_type?.value,
      credit_limit: data?.credit_limit,
    };

    if (retailerModalType === "add") {
      const button = document.getElementById("saveButton");
      try {
        let filteredData = {};
        if (retailerInfo.phone_number !== "") {
          button.disabled = true;
          const response = await addUser(retailerInfo);
          if (response?.success) {
            filteredData = Object.fromEntries(filteredChips);

            let transformedChips = {};

            for (let key in filteredData) {
              if (typeof filteredData[key] === "object" && "label" in filteredData[key] && "value" in filteredData[key]) {
                transformedChips[key] = filteredData[key].value;
              } else {
                transformedChips[key] = filteredData[key];
              }
            }

            if (RoleId === userRoleConstants.ADMIN_ID) {
              filteredData = {};
            } else if (RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) {
              filteredData.role_id = userRoleConstants.RETAILER_ID;
            }

            handleResetGrantModal();
            successToast(response?.message);
            dispatch(postUserFilterApi({ filteredData: transformedChips, page, size: currentSize }));
            button.disabled = false;
          } else {
            errorToast(response?.message);
            button.disabled = false;
          }
        } else {
          setPhoneCodeError(true);
        }
      } catch (error) {
        errorToast(error?.response?.data?.message);
        button.disabled = false;
      }
    }
  };

  const handleResetGrantModal = () => {
    reset({
      retailer_name: "",
      retailer_email: "",
      retailer_commission: "",
      retailer_address: "",
      retailer_phone_number: "",
      retailer_city: "",
      retailer_state: "",
      retailer_country: "",
      retailer_zip: "",
      credit_day: "",
      billing_type: "",
      credit_limit: "",
    });
    handleClose();
  };

  const handleChange = (value) => {
    const stringValue = String(value);
    const parsedPhoneNumber = parsePhoneNumber(stringValue);
    if (parsedPhoneNumber) {
      setPhoneCode(`+${parsedPhoneNumber?.countryCallingCode}`);
      setPhoneNumber(parsedPhoneNumber?.nationalNumber);
      setValue("retailer_phone_number", stringValue);
      setPhoneCodeError(false);
    } else {
      setPhoneCode("");
      setPhoneNumber("");
      // setValue("retailer_phone_number", "");
    }
    if (value) {
      setValue("retailer_phone_number", value, { shouldValidate: true });
      setPhoneCodeError(false);
    }
    if (value === undefined) {
      setValue("retailer_phone_number", setPhoneCode, { shouldValidate: true });
      setPhoneCodeError(true);
    }
  };

  const sendNumberOtp = async (number) => {
    const numberVerifyButton = document.getElementById("number-verify");

    // Check if number is empty
    if (!number) {
      errorToast("Please fill the phone number field");
    } else {
      let obj = {
        mobile: number,
      };
      setLoadingNumber(true);
      setShowNumberBox(false);
      try {
        numberVerifyButton.style.pointerEvents = "none";
        const response = await SendEmailMobileOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowNumberBox(false);
          setShowNumberOtpBox(true);
          setLoadingNumber(false);
        } else {
          numberVerifyButton.style.pointerEvents = "auto";
          errorToast(response?.message);
          setLoadingNumber(false);
          setShowNumberBox(true);
        }
      } catch (error) {
        numberVerifyButton.style.pointerEvents = "auto";
        errorToast(error?.response?.data?.message);
      }
    }
  };

  const sendEmailOtp = async (email) => {
    const emailVerifyButton = document.getElementById("email-verify");

    // Check if email is empty
    if (!email) {
      errorToast("Please fill the email field");
    } else {
      let obj = {
        email: email,
      };
      setLoading(true);
      setShowEmailBox(false);
      try {
        emailVerifyButton.style.pointerEvents = "none";
        const response = await SendEmailMobileOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowEmailBox(false);
          setShowEmailOtpBox(true);
          setLoading(false);
        } else {
          emailVerifyButton.style.pointerEvents = "auto";
          errorToast(response?.message);
          setLoading(false);
          setShowEmailBox(true);
        }
      } catch (error) {
        emailVerifyButton.style.pointerEvents = "auto";
        errorToast(error?.response?.data?.message);
      }
    }
  };
  
  // Inside the verifyEmail and verifyNumber functions, update the submit button disabled state

  const verifyEmail = async (email) => {
    if (!emailOtp) {
      errorToast("Please fill the otp field");
    } else {
      let obj = {
        email: email,
        emailOtp: emailOtp,
      };
      try {
        const response = await verifyEmailOrPhoneNumberOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowEmailBox(true);
          setShowEmailOtpBox(false);
          setEmailDisabled(true);
          setShowEmailBox(false);
          setEmailVerified(true);
          // Check if both email and number have been verified
          if (numberOtp) {
            setSubmitButtonDisabled(false);
          }
        } else {
          errorToast(response?.message);
        }
      } catch (error) {
        errorToast(error?.response?.data?.message);
      }
    }
  };

  const verifyNumber = async (number) => {

    if (!numberOtp) {
      errorToast("Please fill the otp field");
    } else {
      let obj = {
        mobile: number,
        mobileOtp: numberOtp,
      };
      try {
        const response = await verifyEmailOrPhoneNumberOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowNumberBox(true);
          setShowNumberOtpBox(false);
          setNumberVerified(true);
          setShowNumberBox(false);
          setNumberDisabled(false);
          // Check if both email and number have been verified
          if (emailOtp) {
            setSubmitButtonDisabled(false);
          }
        } else {
          errorToast(response?.message);
        }
      } catch (error) {
        errorToast(error?.response?.data?.message);
      }
    }
  };

 
  return (
    <>
      <Modal show={retailerAddEditModalShow} centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <div className="modal-body">
              <form onSubmit={handleSubmit(handleAddEditRetailer)}>
                <button onClick={handleClose} type="button" className="btn-close"></button>
                <div className="text-left mb-4">{RoleId === userRoleConstants.ADMIN_ID ? <h3 className="mb-2">Add distributor</h3> : <h3 className="mb-2">Add retailer</h3>}</div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Full Name</label>
                    <input type="text" placeholder="" className="form-control" name="retailer_name" minLength={1} maxLength={256} autoComplete="off" {...register("retailer_name", registerOptions.retailer_name)} />
                    <small className="text-danger">{errors?.retailer_name && errors.retailer_name.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Phone Number</label>
                    {numberDisabled ? (<Controller
                      name="retailer_phone_number"
                      control={control}
                      rules={{
                        required: "Required",
                      }}
                      render={({ field }) => (
                        <>
                          <PhoneInput
                            maxLength={15}
                            className="form-control"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="AF"
                            value={field.value}
                            onChange={(value) => {
                              handleChange(value);
                            }}
                          />
                          {phoneCodeError && <small className="text-danger">required</small>}
                          {errors?.retailer_phone_number && <small className="text-danger">{errors.retailer_phone_number.message}</small>}
                        </>
                      )}
                    />) : 
                    (<input type="text" className="form-control"value={`${phoneCode} ${phoneNumber}`} disabled />)}
                  </div>
                  <div className="col-lg-6 mb-3">
                    {showNumberBox && (
                      <label className="btn btn-sm btn-outline-secondary waves-effect waves-light mt-4" onClick={() => sendNumberOtp(getValues("retailer_phone_number"))} id="number-verify">
                        Verify
                      </label>
                    )}
                     {loadingNumber && (
                      <div className="mt-4">
                        <span className="spinner-border h-15px w-15px align-middle text-danger" />
                      </div>
                    )}
                    {showNumberOtpBox && (
                      <div>
                        <label className="form-label">Verify OTP</label>
                        <div className="d-flex justify-content-between">
                          <input type="text" placeholder="" className="form-control" onChange={(event) => setNumberOtp(event.target.value)} />
                          <button type="button" className="btn btn-sm btn-outline-secondary waves-effect waves-light ms-2" onClick={() => verifyNumber(getValues("retailer_phone_number"))}>
                            <i className="ti ti-check" />
                          </button>
                        </div>
                      </div>
                    )}
                    {numberVerified && (
                      <label className="btn-verified mt-4">
                        Verified
                      </label>
                    )}
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Email</label>
                    <input type="email" placeholder="" name="retailer_email" className="form-control" minLength={1} maxLength={256} {...register("retailer_email", registerOptions.retailer_email)} autoComplete="off" disabled={emailDisabled} />
                    <small className="text-danger">{errors?.retailer_email && errors.retailer_email.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    {showEmailBox && (
                      <label className="btn btn-sm btn-outline-secondary waves-effect waves-light mt-4" onClick={() => sendEmailOtp(getValues("retailer_email"))} id="email-verify">
                        Verify
                      </label>
                    )}
                    {loading && (
                      <div className="mt-4">
                        <span className="spinner-border h-15px w-15px align-middle text-danger" />
                      </div>
                    )}
                    {showEmailOtpBox && (
                      <div>
                        <label className="form-label">Verify OTP</label>
                        <div className="d-flex justify-content-between">
                          <input type="text" placeholder="" className="form-control" onChange={(event) => setEmailOtp(event.target.value)} />
                          <button type="button" className="btn btn-sm btn-outline-secondary waves-effect waves-light ms-2" onClick={() => verifyEmail(getValues("retailer_email"))}>
                            <i className="ti ti-check" />
                          </button>
                        </div>
                      </div>
                    )}
                     {emailVerified && (
                      <label className="btn-verified mt-4">
                        Verified
                      </label>
                    )}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Address</label>
                    <input type="text" placeholder="" className="form-control" name="retailer_address" minLength={1} maxLength={256} {...register("retailer_address", registerOptions.retailer_address)} autoComplete="off" />
                    <small className="text-danger">{errors?.retailer_address && errors.retailer_address.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Country</label>
                    <Controller
                      name="retailer_country"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti={false}
                          options={countryOptions()}
                          isSearchable
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            handleState(selectedOption);
                          }}
                        />
                      )}
                    />
                    <small className="text-danger">{errors?.retailer_country && errors.retailer_country.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">State</label>
                    <Controller name="retailer_state" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} isMulti={false} options={stateOptions()} />} />
                    <small className="text-danger">{errors?.retailer_state && errors.retailer_state.message}</small>
                  </div>
                  <div className="col-lg-6  mb-3">
                    <label className="form-label">City</label>
                    <input type="text" placeholder="" className="form-control" name="retailer_city" minLength={1} maxLength={256} autoComplete="off" {...register("retailer_city", registerOptions.retailer_city)} />
                    <small className="text-danger">{errors?.retailer_city && errors.retailer_city.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Zip</label>
                    <input type="number" placeholder="" className="form-control" name="retailer_zip" min={0} maxLength={256} autoComplete="off" {...register("retailer_zip", registerOptions.retailer_zip)} />
                    <small className="text-danger">{errors?.retailer_zip && errors.retailer_zip.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Billing Type</label>
                    <Controller
                      name="billing_type"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti={false}
                          options={billingTypeOption()}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            setBillingType(selectedOption);
                            setValue("credit_day", null);
                            setValue("credit_limit", null);
                          }}
                        />
                      )}
                    />
                    <small className="text-danger">{errors?.billing_type && errors.billing_type.message}</small>
                  </div>
                  {billingType?.label === "Credit" && (
                    <>
                      <div className="col-lg-6 mb-3">
                        <label className="form-label">Credit Day</label>
                        <Controller name="credit_day" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} isMulti={false} options={creditDayOption} />} />
                        <small className="text-danger">{errors?.credit_day && errors.credit_day.message}</small>
                      </div>
                      <div className="col-lg-6  mb-3">
                        <label className="form-label">Credit Limit</label>
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <input type="number" placeholder="" className="form-control" name="credit_limit" min={0} maxLength={256} autoComplete="off" {...register("credit_limit", { required: "Required", min: { value: 1, message: "Credit limit must be greater than 0" } })} />
                        </div>
                        <small className="text-danger">{errors?.credit_limit && errors.credit_limit.message}</small>
                      </div>
                    </>
                  )}
                  <div className="col-lg-6 mt-3 mt-lg-0 mb-3">
                    <label className="form-label">
                      Commission <small>(%)</small>
                    </label>
                    <input type="text" placeholder="" className="form-control" name="retailer_commission" minLength={1} maxLength={3} autoComplete="off" {...register("retailer_commission", registerOptions.retailer_commission)} />
                    <small className="text-danger">{errors?.retailer_commission && errors.retailer_commission.message}</small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" id="saveButton" disabled={submitButtonDisabled}>
                      Submit
                    </button>
                    <button onClick={handleResetGrantModal} type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RetailerAddModal;
