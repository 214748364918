import { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { editUser, SendEmailMobileOtp, verifyEmailOrPhoneNumberOtp} from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const ManageUserEditModal = ({ editModalShow, setEditModalShow, heading, userDetailsForEditModal, setReRendering, reRendering, setUserDetailsForEditModal, masterData, setId, transformedDataToBeFiltered, dispatch, postUserFilterApi, page, currentSize }) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { name: userDetailsForEditModal?.name ?? "", phone_number: `${userDetailsForEditModal?.phone_code}${userDetailsForEditModal?.phone_number}` ?? "", email: userDetailsForEditModal?.email ?? "", address: userDetailsForEditModal?.address ?? "", country: { label: userDetailsForEditModal?.country_name, value: {code: userDetailsForEditModal?.country_code, id: userDetailsForEditModal?.country_id }} ?? "", state: { label: userDetailsForEditModal?.state_name, value: userDetailsForEditModal?.state_id } ?? "", city: userDetailsForEditModal?.city ?? "", zip: userDetailsForEditModal?.zip ?? "" } });

  const [phoneNumber, setPhoneNumber] = useState(userDetailsForEditModal?.phone_number);
  const [phoneCode, setPhoneCode] = useState(userDetailsForEditModal?.phone_code);
  const [phoneCodeError, setPhoneCodeError] = useState(false);
  const [numberOtp, setNumberOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [emailVerified, setEmailVerified] = useState(true);
  const [numberVerified, setNumberVerified] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingNumber, setLoadingNumber] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [numberEnabled, setNumberEnabled] = useState(true);
  const [showEmailBox, setShowEmailBox] = useState(false);
  const [showNumberBox, setShowNumberBox] = useState(false);
  const [showEmailOtpBox, setShowEmailOtpBox] = useState(false);
  const [showNumberOtpBox, setShowNumberOtpBox] = useState(false);

  const handleModalClose = () => {
    reset({ name: "", phone_number: "", email: "", address: "", country: "", state: "", city: "", zip: "" });
    setUserDetailsForEditModal({});
    setEditModalShow(!editModalShow);
  };

  const onSubmit = async (formData) => {
    const formattedData = {
      ...formData,
      country_id: formData?.country?.value?.id,
      state_id: formData?.state?.value,
      commission: 0,
      phone_number: phoneNumber ?? phoneNumber,
      phone_code: phoneCode ?? phoneCode,
    };

    delete formattedData.country;
    delete formattedData.state;
    try {
      if (formattedData.phone_number || formattedData.phone_code !== "") {
        const response = await editUser(userDetailsForEditModal?.id, formattedData);

        if (response?.success) {
          dispatch(postUserFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize }));
          successToast(response?.message);
          setUserDetailsForEditModal({});
          reset();
          setEditModalShow(!editModalShow);
        } else {
          errorToast(response?.message);
        }
      }
      else{
        setPhoneCodeError(true);
      }
    } catch (error) {
      errorToast("An error occurred while editing the user.");
    }
  };
  const countryOptions = masterData?.countries?.map((countriesOption) => ({ label: countriesOption?.name, value: { code: countriesOption?.countryCode, id: countriesOption?.id } }));

  const stateOptions = masterData?.states?.map((stateOption) => ({ label: stateOption?.name, value: stateOption?.id }));

  const handleCountrySelected = (event) => {
    setId(event?.value?.code);
    setValue("state", null);
  };

  const handleChange = (value) => {
    const stringValue = String(value);
    const parsedPhoneNumber = parsePhoneNumber(stringValue);
  
    if (parsedPhoneNumber) {
      const newPhoneCode = `+${parsedPhoneNumber.countryCallingCode}`;
      const newPhoneNumber = parsedPhoneNumber.nationalNumber;
      setPhoneCode(newPhoneCode);
      setPhoneNumber(newPhoneNumber);
      setValue("phone_number", stringValue);
      setPhoneCodeError(false);
      // Reset verification state if phone number changes
      if (userDetailsForEditModal?.phone_number === newPhoneNumber && userDetailsForEditModal?.phone_code === newPhoneCode) {
        setNumberVerified(true);
        setShowNumberBox(false);
      } else {
        setNumberVerified(false);
        setShowNumberBox(true);
      }
    } else {
      setPhoneNumber("");
      setPhoneCode("");
    }
  
    if (!parsedPhoneNumber && !phoneCode) {
      setPhoneCodeError(true);
    } else {
      setPhoneCodeError(false);
    }
  };

  const handleStateSelected = (event) => {
    setValue("state", event);
    if (errors.state) {
      setValue("state", event, { shouldValidate: true });
    }
  };

  const sendNumberOtp = async (number) => {
    const numberVerifyButton = document.getElementById("number-verify");

    // Check if number is empty
    if (!number) {
      errorToast("Please fill the phone number field");
    } else {
      let obj = {
        mobile: number,
      };
      setLoadingNumber(true);
      setShowNumberBox(false);
      try {
        numberVerifyButton.style.pointerEvents = "none";
        const response = await SendEmailMobileOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowNumberBox(false);
          setShowNumberOtpBox(true);
          setLoadingNumber(false);
        } else {
          numberVerifyButton.style.pointerEvents = "auto";
          errorToast(response?.message);
          setLoadingNumber(false);
          setShowNumberBox(true);

        }
      } catch (error) {
        numberVerifyButton.style.pointerEvents = "auto";
        errorToast(error?.response?.data?.message);
      }
    }
  };

  const sendEmailOtp = async (email) => {
    const emailVerifyButton = document.getElementById("email-verify");

    // Check if email is empty
    if (!email) {
      errorToast("Please fill the email field");
    } else {
      let obj = {
        email: email,
      };
      setLoading(true);
      setShowEmailBox(false);
      try {
        emailVerifyButton.style.pointerEvents = "none";
        const response = await SendEmailMobileOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowEmailBox(false);
          setShowEmailOtpBox(true);
          setLoading(false);
        } else {
          emailVerifyButton.style.pointerEvents = "auto";
          errorToast(response?.message);
          setLoading(false);
          setShowEmailBox(true);
        }
      } catch (error) {
        emailVerifyButton.style.pointerEvents = "auto";
        errorToast(error?.response?.data?.message);
      }
    }
  };

  const verifyEmail = async (email) => {
    if (!emailOtp) {
      errorToast("Please fill the otp field");
    } else {
      let obj = {
        email: email,
        emailOtp: emailOtp,
      };
      try {
        const response = await verifyEmailOrPhoneNumberOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowEmailBox(true);
          setShowEmailOtpBox(false);
          setShowEmailBox(false);
          setEmailVerified(true);
          setEmailDisabled(true);
        } else {
          errorToast(response?.message);
        }
      } catch (error) {
        errorToast(error?.response?.data?.message);
      }
    }
  };

  const verifyNumber = async (number) => {

    if (!numberOtp) {
      errorToast("Please fill the otp field");
    } else {
      let obj = {
        mobile: number,
        mobileOtp: numberOtp,
      };
      try {
        const response = await verifyEmailOrPhoneNumberOtp(obj);
        if (response?.success) {
          successToast(response?.message);
          setShowNumberBox(true);
          setShowNumberOtpBox(false);
          setNumberVerified(true);
          setShowNumberBox(false);
          setNumberEnabled(false);
        } else {
          errorToast(response?.message);
        }
      } catch (error) {
        errorToast(error?.response?.data?.message);
      }
    }
  };

  const handleEmail = (event) => {
    const currentEmail = event.target.value;
    if(currentEmail === userDetailsForEditModal?.email) {
      setEmailVerified(true);
      setShowEmailBox(false);
      setValue("email", currentEmail);
    } else {
      setEmailVerified(false);
      setShowEmailBox(true);
      setValue("email", currentEmail);
    }
  };

  return (
    <Modal show={editModalShow} centered>
      <div className="modal-simple">
        <div className="modal-content p-3 p-md-3">
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <button type="button" className="btn-close" onClick={handleModalClose} />
              <div className="text-left mb-4">
                <h3 className="mb-2">{heading}</h3>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <label className="form-label">Full Name</label>
                  <input type="text" {...register("name", { required: "User Name is required" })} placeholder="" className="form-control" minLength={1} maxLength={256} autoComplete="off" />
                  <small className="text-danger">{errors.name && errors.name.message}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">Phone Number</label>
                  {numberEnabled ? (<Controller
                    name="phone_number"
                    control={control}
                    rules={{
                      required: "Required",
                    }}
                    render={({ field }) => (
                      <>
                        <PhoneInput
                          className="form-control"
                          international
                          countryCallingCodeEditable={false}
                          value={field.value}
                          onChange={(value) => {
                            handleChange(value);
                          }}
                          autoComplete="off"
                        />
                         {phoneCodeError && <small className="text-danger">required</small>}
                        <small className="text-danger">{errors?.phone_number && errors?.phone_number?.message}</small>
                      </>
                    )}
                  />) : 
                  (<input type="text" className="form-control"value={`${phoneCode} ${phoneNumber}`} disabled />)}
                </div>
                <div className="col-lg-6 mb-3">
                    {showNumberBox && (
                      <label className="btn btn-sm btn-outline-secondary waves-effect waves-light mt-4" onClick={() => sendNumberOtp(getValues("phone_number"))} id="number-verify">
                        Verify
                      </label>
                    )}
                    {loadingNumber && (
                      <div className="mt-4">
                        <span className="spinner-border h-15px w-15px align-middle text-danger" />
                      </div>
                    )}
                    {showNumberOtpBox && (
                      <div>
                        <label className="form-label">Verify Otp</label>
                        <div className="d-flex justify-content-between">
                          <input type="text" placeholder="" className="form-control" onChange={(event) => setNumberOtp(event.target.value)} />
                          <button type="button" className="btn btn-sm btn-outline-secondary waves-effect waves-light ms-2" onClick={() => verifyNumber(getValues("phone_number"))}>
                            <i className="ti ti-check" />
                          </button>
                        </div>
                      </div>
                    )}
                    {numberVerified && (
                      <label className="btn-verified mt-4">
                        Verified
                      </label>
                    )}
                  </div>
                <div className="col-lg-6  mb-3">
                  <label className="form-label">Email</label>
                  <input type="email" {...register("email", { required: "Email is required", pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Please enter a valid email address" } })} placeholder="" className="form-control" minLength={1} maxLength={256} autoComplete="off" disabled={emailDisabled} onChange={handleEmail} />
                  <small className="text-danger">{errors.email && errors.email.message}</small>
                </div>
                <div className="col-lg-6 mb-3">
                    {showEmailBox && (
                      <label className="btn btn-sm btn-outline-secondary waves-effect waves-light mt-4" onClick={() => sendEmailOtp(getValues("email"))} id="email-verify">
                        Verify
                      </label>
                    )}
                    {loading && (
                      <div className="mt-4">
                        <span className="spinner-border h-15px w-15px align-middle text-danger" />
                      </div>
                    )}
                    {showEmailOtpBox && (
                      <div>
                        <label className="form-label">Verify Otp</label>
                        <div className="d-flex justify-content-between">
                          <input type="text" placeholder="" className="form-control" onChange={(event) => setEmailOtp(event.target.value)} />
                          <button type="button" className="btn btn-sm btn-outline-secondary waves-effect waves-light ms-2" onClick={() => verifyEmail(getValues("email"))}>
                            <i className="ti ti-check" />
                          </button>
                        </div>
                      </div>
                    )}
                     {emailVerified && (
                      <label className="btn-verified mt-4">
                        Verified
                      </label>
                    )}
                </div>
                <div className="col-lg-12 mb-3">
                  <label className="form-label">Address</label>
                  <input type="text" {...register("address", { required: "Address is required" })} placeholder="" className="form-control" minLength={1} maxLength={256} autoComplete="off" />
                  <small className="text-danger">{errors.address && errors.address.message}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">Country</label>
                  <Controller
                    name="country"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Country is required" }}
                    render={({ field }) => (
                      <>
                        <div className="css-b62m3t-container">
                          <Select
                            {...field}
                            options={countryOptions}
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setValue("country", selectedOption);
                              handleCountrySelected(selectedOption);
                            }}
                          />
                        </div>
                        {errors.country && <small className="text-danger">{errors.country.message}</small>}
                      </>
                    )}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">State</label>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue=""
                    rules={{ required: "State is required" }}
                    render={({ field }) => (
                      <>
                        <div className="css-b62m3t-container">
                          <span id="react-select-5-live-region" className="css-1f43avz-a11yText-A11yText" />
                          <span aria-live="polite" aria-atomic="false" aria-relevant="additions text" role="log" className="css-1f43avz-a11yText-A11yText" />
                          <Select
                            {...field}
                            options={stateOptions}
                            isMulti={false}
                            onChange={(selectedOption) => {
                              handleStateSelected(selectedOption);
                            }}
                          />
                        </div>
                        {errors.state && <small className="text-danger">{errors.state.message}</small>}
                      </>
                    )}
                  />
                </div>

                <div className="col-lg-6  mb-3">
                  <label className="form-label">City</label>
                  <input type="text" {...register("city", { required: "City is required" })} placeholder="" className="form-control" minLength={1} maxLength={256} autoComplete="off" />
                  <small className="text-danger">{errors.city && errors.city.message}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">Zip</label>
                  <input type="number" {...register("zip", { required: "ZIP Code is required"})} placeholder="" className="form-control" autoComplete="off" />
                  <small className="text-danger">{errors.zip && errors.zip.message}</small>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center mt-4">
                  <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" id="saveButton">
                    Submit
                  </button>
                  <button type="button" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleModalClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManageUserEditModal;
