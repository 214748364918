import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { Controller, useForm } from "react-hook-form";

const EditTopUpDetails = ({ showModalBox, setShowModalBox, carrierData, mobileNumber, setMobileNumber, carrierId, setCarrierId }) => {
  const handleClose = () => setShowModalBox(false);
  const [ , setPhoneNumber] = useState(mobileNumber);
  const [ , setPhoneCode] = useState();
  const [phoneCodeError, setPhoneCodeError] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { mobile_number: `${mobileNumber}`, carrier: { label: carrierId === 2 ? "Roshan" : "Etisalat", value: carrierId } } });

  const carrierOptions = () => {
    return carrierData?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const handleChange = (value) => {
    const stringValue = String(value);
    const parsedPhoneNumber = parsePhoneNumber(stringValue);
    if (parsedPhoneNumber) {
      setPhoneCode(`+${parsedPhoneNumber?.countryCallingCode}`);
      setPhoneNumber(parsedPhoneNumber?.nationalNumber);
      setValue("mobile_number", stringValue);
      setPhoneCodeError(false);
    } else {
      setPhoneCode("");
      setPhoneNumber("");
    }
    if (value) {
      setValue("mobile_number", value, { shouldValidate: true });
      setPhoneCodeError(false);

      // Check the starting digits and set carrier accordingly
      const nationalNumber = parsedPhoneNumber?.nationalNumber || "";
      if (nationalNumber.startsWith("78") || nationalNumber.startsWith("73")) {
        const etisalatCarrier = carrierData?.find((carrier) => carrier?.name === "Etisalat");
        if (etisalatCarrier) {
          setValue("carrier", { label: etisalatCarrier?.name, value: etisalatCarrier?.id }, { shouldValidate: true });
        }
      } else if (nationalNumber.startsWith("79") || nationalNumber.startsWith("72")) {
        const roshanCarrier = carrierData?.find((carrier) => carrier?.name === "Roshan");
        if (roshanCarrier) {
          setValue("carrier", { label: roshanCarrier?.name, value: roshanCarrier?.id }, { shouldValidate: true });
        }
      }
    }

    if (value === undefined) {
      setValue("mobile_number", setPhoneCode, { shouldValidate: true });
      setPhoneCodeError(true);
    }
  };

  const onSubmit = async (data) => {
    if (!phoneCodeError) {
      setCarrierId(data?.carrier?.value);
      setMobileNumber(data?.mobile_number);
      setShowModalBox(false);
    } else {
      setPhoneCodeError(true);
    }
  };

  return (
    <>
      <Modal show={showModalBox} size="sm" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <button type="button" className="btn-close" onClick={handleClose} />
              <div className="text-left mb-4">
                <h3 className="mb-2">Edit Details</h3>
              </div>
              <div className="add-number">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <label htmlFor="" className="form-label">
                          Number
                        </label>
                        <Controller
                          name="mobile_number"
                          control={control}
                          rules={{
                            required: "Required",
                          }}
                          render={({ field }) => (
                            <>
                              <PhoneInput
                                className="form-control"
                                international
                                countryCallingCodeEditable={false}
                                // countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="AF"
                                countries={["AF"]}
                                value={field.value}
                                onChange={(value) => {
                                  handleChange(value);
                                }}
                              />
                              {phoneCodeError && <small className="text-danger">required</small>}
                              {errors?.mobile_number && <small className="text-danger">{errors.mobile_number.message}</small>}
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <div>
                        <label htmlFor="" className="form-label">
                          Carrier
                        </label>
                        <Controller
                          name="carrier"
                          control={control}
                          rules={{ required: "required" }}
                          render={({ field }) => (
                            <>
                              <Select {...field} options={carrierOptions()} isSearchable placeholder="Select Carrier..." />
                              {errors.carrier && <small className="text-danger">{errors.carrier.message}</small>}
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-12 text-center mt-4">
                      <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-xl-1">
                        Update
                      </button>
                      <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditTopUpDetails;
