import React from "react";
import ColumnChart from "./ColumnChart";
import BarChart from "../../Shared/ManageChart/BarChart";

const Chart = ({ dashboardRecord }) => {
  
  const TransactionsText = "Retailers vs Distributors";
  const TransactionsSmallText = "";
  const color = "#7ab593";
  const xaxisText = "Distributors";
  const yaxisText = "Retailers";

  const SalesText = "Top-Up vs Retailers";
  const SalesSmallText = "";
  const xaxisSalesText = "Retailers";
  const yaxisSalesText = "Top-Ups (Sale & Count)";

  const retailerCounts = dashboardRecord && dashboardRecord?.retailerVsDistributor?.map(item => item?.retailer_count);
  const distributorName = dashboardRecord && dashboardRecord?.retailerVsDistributor?.map(item => item?.name);
  const amount = dashboardRecord && dashboardRecord?.retailerVsTopup?.map(item => item?.total_topup_amount);
  const accountNumber = dashboardRecord && dashboardRecord?.retailerVsTopup?.map(item => item?.retailer_account_no);
  const count = dashboardRecord && dashboardRecord?.retailerVsTopup?.map(item => item?.total_topup_count);

  return (
    <>
      <div className="col-lg-6 mb-3">
        <BarChart headerText={TransactionsText} smallText={TransactionsSmallText} colors={color} xaxisText={xaxisText} yaxisText={yaxisText} count={retailerCounts} name={distributorName}/>
      </div>
      <div className="col-lg-6 mb-3">
        <ColumnChart headerText={SalesText} smallText={SalesSmallText} xaxisText={xaxisSalesText} yaxisText={yaxisSalesText} accountNumber={accountNumber} count={count} amount={amount} />
      </div>
    </>
  );
};

export default Chart;
