import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ViewCustomerHistoryModal from "./ViewCustomerHistoryModal";
import { customerHistory } from "../../../services/AdminCustomer";
import { errorToast } from "../../Toasts/Toasts";
import moment from "moment";
import { formatCreditBalance } from "../../../utils/sharedFunctions";
import InfiniteScroll from "react-infinite-scroll-component";

const CustomerHistory = ({ id , customerName}) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [currentSize, setCurrentSize] = useState(10);
  const [loading, setLoading] = useState(false); // Initialize loading state as true
  const [recipientDetails, setRecipientDetails] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);

  let page = 1;

  useEffect(() => {
    fetchCustomerHistoryDetails();
    // eslint-disable-next-line
  }, []);

  const fetchCustomerHistoryDetails = async () => {
    try {
      const response = await customerHistory({ id, page, currentSize });
      // Set loading state to false when response is received
      if (response?.success) {
        setHistoryDetails(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);

    const response = await customerHistory({ id, page, currentSize: currentSize + 10 });

    if (response?.success) {
      const newData = response?.data;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
        setHistoryDetails(newData);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoading(false);
  }, [loading, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, page, id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("customerHistoryComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("customerHistoryComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">History</h5>
        </div>
        <InfiniteScroll dataLength={historyDetails.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="customerHistoryComponent">
          <div id="customerHistoryComponent" className="card-datatable table-responsive pb-0" style={{ height: "400px", overflowY: "auto" }}>
            <table className=" table" id="orders">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Date &amp; Time</th>
                  <th>Top-UP Amount</th>
                  <th>Amount Paid</th>
                  {/* <th>Type</th> */}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {historyDetails.length !== 0 ? (
                  historyDetails.map((historyDetail, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          to="#"
                          onClick={() => {
                            setShowHistoryModal(true);
                            setRecipientDetails(historyDetail);
                          }}
                        >
                          {historyDetail?.order_id}
                        </Link>
                      </td>
                      <td>{moment.utc(historyDetail?.credited_at).format("DD MMM YY | hh:mm A")}</td>
                      <td>
                        <p className="mb-0">
                          {formatCreditBalance(historyDetail?.topup_amount_converted_currency)} <small>{historyDetail?.converted_currency_type}</small>{" "}
                        </p>
                      </td>
                      <td>
                        {formatCreditBalance(historyDetail?.total_amount_converted_currency)} <small>{historyDetail?.converted_currency_type}</small>
                      </td>
                      {/* <td>Postpaid</td> */}
                      <td>
                        <span className={historyDetail?.status === "Successful" ? "badge px-2 bg-label-success" : historyDetail?.status === "Failed" ? "badge px-2 bg-label-danger" : "badge px-2 bg-label-warning"}>{historyDetail?.status}</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="pt-3 ps-3">
                    <td colSpan="5">No record found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      </div>
      {showHistoryModal && <ViewCustomerHistoryModal showHistoryModal={showHistoryModal} setShowHistoryModal={setShowHistoryModal} recipientDetails={recipientDetails} customerName={customerName} />}
    </>
  );
};

export default CustomerHistory;
