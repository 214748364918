import React from "react";

const SmallStatusCard = ({ color, icon, value, label, currency_format, top_up_count }) => {
  return (
    <>
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex align-items-center">
            <div className={`badge rounded-pill bg-label-${color} me-3 p-2`}>
              <i className={icon}></i>
            </div>
            <div className="card-info">
              <h5 className="mb-0">
                {value} <small>{currency_format}</small>
                <small className="text-muted">{top_up_count}</small>
              </h5>
              <small>{label}</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallStatusCard;
