import React from "react";
import { Modal } from "react-bootstrap";
import { getTransactionUpdateApi } from "../../../redux/features/updateTransactionSlice";
import { getTransactionDetailApi } from "../../../redux/features/transactionCreditSlice";
import { useDispatch } from "react-redux";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { useParams } from "react-router-dom";

const BillingTransactionUpdateModal = ({ showUpdateModal, updateType, setShowUpdateModal, transctionId, creditStatusType, transformedFilterData, page, currentSize, setReRender, reRender }) => {
  const dispatch = useDispatch();
  const { acc_no } = useParams();
  const handleSubmit = async () => {
    const response = await dispatch(getTransactionUpdateApi({ credit_status_type_id: updateType === "Approve" ? creditStatusType.APPROVED : updateType === "Decline" ? creditStatusType.DECLINED : updateType === "Paid" ? creditStatusType.PAID : "", transctionId: transctionId }));

    if (response) {
      successToast(response?.payload?.message);
      if(acc_no) {
        setReRender(!reRender);
      }
      else {
        dispatch(getTransactionDetailApi({ filterData: transformedFilterData, page, size: currentSize }));
      }
    } else {
      errorToast(response?.payload?.message);
    }
    setShowUpdateModal(!showUpdateModal);
  };

  return (
    <>
      {
        <Modal show={showUpdateModal} size="sm" centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <div className="modal-body dashboard-cards rounded warning">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowUpdateModal(!showUpdateModal);
                  }}
                />
                <div className="info text-warning">
                  <p>
                    {" "}
                    <i className="ti ti-exclamation-mark fs-1" />{" "}
                  </p>
                </div>
                <p className="para">{updateType === "Approve" ? "Are you sure you want to approve this transaction?" : updateType === "Decline" ? "Are you sure you want to decline this transaction?" : updateType === "Paid" ? "Are you sure you want to receive the payment?" : "Are you sure you want to resend invoice?"}</p>
                <div className="col-12 text-center mt-4">
                  <button type="submit" className="btn btn-primary btn-sm rounded-pill me-sm-3 me-xl-1" onClick={handleSubmit}>
                    Yes
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary btn-sm rounded-pill btn-reset waves-effect"
                    onClick={() => {
                      setShowUpdateModal(!showUpdateModal);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default BillingTransactionUpdateModal;
