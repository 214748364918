import React, { useEffect, useState } from "react";
import { fetchFinancialSummary } from "../../../services/transactionService";
import { errorToast } from "../../Toasts/Toasts";
import { formatCreditBalance } from "../../../utils/sharedFunctions";

const FinancialSummary = ({reRenderData}) => {

  const [financialSummary, setFinancialSummary] = useState();
  const [selectedOption, setSelectedOption] = useState("1"); 

  useEffect(() => {
    handleData({ target: { value:  selectedOption} });
  }, [reRenderData, selectedOption]);

  const handleData = async (e) => {
    try {
      const response = await fetchFinancialSummary(e.target.value);
      
      if (response?.success) {
        setFinancialSummary(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.message);
    }
  };
  
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  
  const formattedFinancialSummary = formatCreditBalance(financialSummary?.totalCommission ?? 0);
  const formattedCreditBalance = formatCreditBalance(parseFloat(financialSummary?.creditBalance ?? 0));
  const formattedPaymentDue = formatCreditBalance(parseFloat(financialSummary?.paymentDue ?? 0));
  

  return (
    <div className="card">
      <div className="card-header border-bottom mb-0 d-flex justify-content-between">
        <div className="card-title mb-0">
          <h5 className="mb-0">Financial Summary</h5>
        </div>
        <div>
          <select name="" id="" className="form-select form-select-sm" style={{ marginTop: "-4px" }} value={selectedOption} onChange={handleSelectChange}>
            <option value="1">Today</option>
            <option value="3">Till Date</option>
          </select>
        </div>
      </div>
      <div className="card-body p-4">
        <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="card shadow-none h-100 bg-transparent border border-secondary">
              <div className="card-body p-3 d-flex justify-content-between align-items-center">
                <div className="card-title mb-0">
                  <h5 className="mb-0 me-2" data-toggle="tooltip" data-placement="top" title={parseFloat(financialSummary?.creditBalance ?? 0)}>
                    {formattedCreditBalance} <small>USD</small>
                  </h5>
                  <small>Live Balance</small>
                </div>
                <div className="card-icon">
                  <span className="badge bg-label-primary rounded-pill p-2">
                    <i className="ti ti-scale ti-sm" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-3">
            <div className="card shadow-none h-100 bg-transparent border border-warning">
              <div className="card-body p-3 d-flex justify-content-between align-items-center">
                <div className="card-title mb-0">
                  <h5 className="mb-0 me-2">
                    {formattedPaymentDue} <small>USD</small>
                  </h5>
                  <small>Payment Due</small>
                </div>
                <div className="card-icon">
                  <span className="badge bg-label-warning rounded-pill p-2">
                    <i className="ti ti-cash ti-sm" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-3">
            <div className="card shadow-none h-100 bg-transparent border border-info">
              <div className="card-body p-3 d-flex justify-content-between align-items-center">
                <div className="card-title mb-0">
                  <h5 className="mb-0 me-2" data-toggle="tooltip" data-placement="top" title={financialSummary?.totalCommission ?? 0}>
                    {formattedFinancialSummary}<small> USD</small>
                  </h5>
                  <small>Earned Commission</small>
                </div>
                <div className="card-icon">
                  <span className="badge bg-label-info rounded-pill p-2">
                    <i className="ti ti-chart-bar ti-sm" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialSummary;
