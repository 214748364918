import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { validateOtpForMfa } from "../../services/userService";
import { errorToast, successToast } from "../Toasts/Toasts";

const MfaModal = ({ show, handleClose, qrCodeUrl, SetRenderProfileData, renderProfileData, mfaValue }) => {
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Handler for OTP input
  const handleOtpChange = (otp) => {
    setOtpCode(otp);
    setError("");
  };

  // Handler for form submission
  const handleSubmit = async () => {
    if (otpCode.length === 6) {
      setLoading(true); // Start loading when submitting

      // Initialize obj with proper assignment
      const obj = mfaValue === 1 ? { token: otpCode, isDisabled: true } : { token: otpCode };

      try {
        const response = await validateOtpForMfa(obj);
        if (response?.success) {
          successToast(response?.message);
          handleClose(); // Close the modal on success
          SetRenderProfileData(!renderProfileData);
        } else {
          errorToast(response?.message);
        }
      } catch (error) {
        errorToast(error?.message || "Something went wrong");
      } finally {
        setLoading(false); // Stop loading when request completes
      }
    } else {
      setError("Please enter a valid 6-digit OTP.");
    }
  };

  return (
    <Modal show={show} size="md" centered>
      <div className="modal-simple">
        <div className="modal-content p-3 p-md-3">
          <Modal.Body>
            <button type="button" className="btn-close" onClick={handleClose} />
            <div className="text-left mb-0">{mfaValue === 1 ? <h4 className="mb-3">Disable MFA</h4> : <h4 className="mb-3">Enable MFA</h4>}</div>
            {mfaValue === 0 && (
              <>
                <p>Scan this QR code with your MFA app:</p>
                {qrCodeUrl ? <img src={qrCodeUrl} alt="MFA QR Code" style={{ maxWidth: "100%", height: "auto" }} /> : <p>Loading QR code...</p>}
              </>
            )}

            {/* OTP Input Section using react-otp-input */}
            <div className="mt-4">
              <label>Enter 6-Digit OTP</label>
              <OtpInput value={otpCode} onChange={handleOtpChange} numInputs={6} renderSeparator={<span> </span>} renderInput={(props) => <input {...props} className={`my-2 me-3 text-center form-control rounded otp-input-width ${error ? "is-invalid" : ""}`} />} inputStyle="text-center form-control rounded otp-input-width" shouldAutoFocus={true} />
              {error && <div className="text-danger mt-2">{error}</div>}
            </div>
            <div className="text-center mt-4">
              <Button variant="primary" onClick={handleSubmit} className="rounded-pill" disabled={loading}>
                Submit
              </Button>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default MfaModal;
