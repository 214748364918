import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { verifyMfa } from "../../services/userService";
import { errorToast } from "../Toasts/Toasts";
import { userRoleConstants } from "../../utils/constants";
import { useDispatch } from "react-redux";
import decrypt from "../../utils/decrypt";
import { setLogin } from "../../redux/features/authSlice";

const UserVerifyMfa = () => {
  const { DISTRIBUTOR_ID, RETAILER_ID, ADMIN_ID, DISTRIBUTOR_USER_ID, CUSTOMER_ID, MANAGER_ID, SUPERVISOR_ID, SALES_REPRESENTATIVE_ID, PROCUREMENT_ID, ACCOUNTANT_ID} = userRoleConstants;
  const [otp, setOtp] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const validateOtp = () => {
    if (!otp) {
      setError("OTP is required");
      return false;
    } else if (otp.length !== 6) {
      setError("OTP must be 6 digits");
      return false;
    }
    setError("");
    return true;
  };
  const rechargeRequestId = location?.state?.rechargeRequestId;
  const onSubmit = async () => {
    if (validateOtp()) {
      setBtnDisabled(true);
      try {
        let obj = {
          mfaToken: location?.state?.response?.mfaToken,
          token: otp,
        };
        let response = await verifyMfa(obj);
        if (response?.success) {
          const roleId = parseInt(decrypt(response?.role_id));
          localStorage.setItem("token", response?.token);
          localStorage.setItem("roleId", response?.role_id);

          dispatch(
            setLogin({
              isAuthenticated: true,
              roleId: response?.role_id,
            })
          );
          setBtnDisabled(false);

          if (roleId === DISTRIBUTOR_ID || roleId === ADMIN_ID || roleId === DISTRIBUTOR_USER_ID || roleId === MANAGER_ID || roleId === PROCUREMENT_ID) {
            navigate("/dashboard");
          } else if (roleId === RETAILER_ID) {
            navigate(`/b2c-topup`);
          } else if (roleId === CUSTOMER_ID) {
            if (rechargeRequestId) {
              navigate("/customer/recharge-request");
              localStorage.setItem("rechargeRequestStatus", rechargeRequestId);
            } else {
              navigate("/customer/profile");
            }
          } else if (roleId === SALES_REPRESENTATIVE_ID) {
            navigate("/retailers");
          } else if(roleId === SUPERVISOR_ID) {
            navigate("/distributors");
          } else if (roleId === ACCOUNTANT_ID) {
            navigate("/billing");
          } else {
            navigate("/");
          }
        } else {
          errorToast(response?.message);
          setBtnDisabled(false);
        }
      } catch (error) {
        errorToast(error?.response?.data?.message ?? "Something went wrong");
        setBtnDisabled(false);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
            <div className="card card-topup bg-outline-primary mt-4">
              <div className="align-items-center py-3">
                <div className="card-body">
                  <h3 className="text-center">Enter Authentication Code</h3>
                  <p className="fs-6 mb-4 d-flex justify-content-between">
                    <span>Please check your authenticator app for the code</span>
                  </p>
                  <form>
                    <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                      <OtpInput value={otp} onChange={(value) => setOtp(value)} numInputs={6} renderSeparator={<span> </span>} renderInput={(props) => <input {...props} className={`my-2 me-3 text-center form-control rounded otp-input-width ${error ? "is-invalid" : ""}`} />} inputStyle="text-center form-control rounded otp-input-width" shouldAutoFocus={true} />
                    </div>
                    {error && <div className="text-danger mt-2">{error}</div>}
                    <div className="mt-4">
                      <button type="button" className="btn btn-primary w-100 rounded-pill text-white d-grid" onClick={onSubmit} disabled={btnDisabled}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVerifyMfa;
