import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customerProfile } from "../../services/customerService";

const initialState = {
  customerDetails: [],
  isLoading: false,
  error: null,
};

export const getCustomerProfileApi = createAsyncThunk(
  "customerProfile/getCustomerProfileApi",
  async () => {
    try {
      const response = await customerProfile();
      return response;
    } catch (error) {
      console.error("Error fetching transaction details:", error);
      throw error;
    }
  }
);

const customerProfileSlice = createSlice({
  name: "customerProfile",
  initialState,
  reducers: {
    setInitCustomerDetails: (state) => {
      state.customerDetails = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerProfileApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getCustomerProfileApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerDetails = action.payload.data.customerInfo;
    });

    builder.addCase(getCustomerProfileApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = customerProfileSlice;
export const { setInitCustomerDetails } = customerProfileSlice.actions;
export default reducer;