import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { topUpFilter } from "../../services/transactionService";

const initialState = {
  topUpDetails: [],
  isLoading: false,
  error: null,
  filteredChips: [],
  count: {},
};

export const getTopUpDetailApi = createAsyncThunk(
  "topUp/getTopUpDetailApi",
  async (filteredData) => {
    try {
      const response = await topUpFilter(filteredData?.filteredData,filteredData?.page,filteredData?.size);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const topUpSlice = createSlice({
  name: "top_up",
  initialState,
  reducers: {
    setInitTransactionDetail: (state) => {
      state.topUpDetails = [];
    },
    setFilteredChips: (state, action) => {
      state.filteredChips = action.payload;
    },
    clearFilteredChips: (state) => {
      state.filteredChips = [];
    },
    resetTransactionTopUpState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopUpDetailApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getTopUpDetailApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.topUpDetails = action.payload.records;
      state.count = action.payload.count;
    });

    builder.addCase(getTopUpDetailApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = topUpSlice;
export const { setInitTransactionDetail, setFilteredChips, clearFilteredChips, resetTransactionTopUpState } = topUpSlice.actions;
export default reducer;
