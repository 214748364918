import React, { useEffect, useState } from "react";
import ProductsGrid from "./ProductsGrid";
import { useParams } from "react-router-dom";
import { getMasterData } from "../../../services/userService";
import { postProductFilterApi} from "../../../redux/features/productSlice";
import { postUserFilterApi } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { userRoleConstants } from "../../../utils/constants";
import FilterModal from "./FilterModal";

const RetailerProducts = () => {

  const [clearFilterValues, setClearFilterValues] = useState("");
  const [masterData, setMasterData] = useState("");
  const dispatch = useDispatch();
  const { acc_no } = useParams();
  const { RETAILER_ID } = userRoleConstants;
  const [currentSize, setCurrentSize] = useState(10)

  let id = "";
  let page = 1;
  let size = 10;

  useEffect(() => {
    fetchMasterData();
    dispatch(postProductFilterApi({ filteredData: { account_no: acc_no}, page, size}));
    dispatch(postUserFilterApi({filteredData: {role_id: RETAILER_ID }, page, size}))
    // eslint-disable-next-line
  }, [dispatch]);

  const fetchMasterData = async () => {
    try {
      const response = await getMasterData(id);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };
  
  const productDetails = useSelector((state) => state.product.productDetails);
  const usersDetails = useSelector((state) => state?.user?.userDetails)

  const getNameFromAccount = (accountNo, data) => {
    return data.find(entry => entry.account_no === accountNo) || null;
  }

  const userDetail = getNameFromAccount(acc_no, usersDetails);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-1 pt-1">{userDetail?.name}</h4>
        <div className="d-flex">
        <FilterModal  masterData={masterData} clearFilterValues={clearFilterValues} account_no = {acc_no} currentSize={currentSize} />
        </div>
      </div>
      <ProductsGrid setClearFilterValues={setClearFilterValues} productDetails={productDetails} userDetail={userDetail} account_no = {acc_no} currentSize={currentSize} setCurrentSize={setCurrentSize} />
    </>
  );
};

export default RetailerProducts;
