import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import EditPriceModal from "./EditPriceModal";
import ProductHistoryModal from "./ProductHistoryModal";
import ViewProductDetails from "./ViewProductDetails";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";
import { useDispatch, useSelector } from "react-redux";
import { postProductFilterApi, setFilteredChips } from "../../../redux/features/productSlice";
import moment from "moment";
import { successToast, errorToast } from "../../Toasts/Toasts";
import { editProduct } from "../../../services/productService";
import InfiniteScroll from "react-infinite-scroll-component";
import { chipName, formatCreditBalance } from "../../../utils/sharedFunctions";

const ManageProductGrid = ({ setClearFilterValues, currentSize, setCurrentSize }) => {
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [showModalBox, setShowModalBox] = useState(false);
  const [showHistoryModalBox, setShowHistoryModalBox] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [productAllDetails, setProductAllDetails] = useState("");
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [productId, setProductId] = useState("");
  const RoleId = parseInt(localStorageStore.getRole());
  const dispatch = useDispatch();
  const filteredChips = useSelector((state) => state.product.filteredChips);

  let page = 1;

  const dataToBeFiltered = Object.fromEntries(filteredChips);
  // eslint-disable-next-line
  let transformedDataToBeFiltered = {};

  for (let key in dataToBeFiltered) {
    if (typeof dataToBeFiltered[key] === "object" && "label" in dataToBeFiltered[key] && "value" in dataToBeFiltered[key]) {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key].value;
    } else {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key];
    }
  }

  useEffect(() => {
    dispatch(postProductFilterApi({ filteredData: {}, page, size: currentSize }));
    // eslint-disable-next-line
  }, []);

  const productDetails = useSelector((state) => state.product.productDetails);
  const serviceFee = useSelector((state) => state.product.serviceFee);
  const vat = useSelector((state) => state.product.vat);

  const handleShowHistoryModal = (productId) => {
    setShowHistoryModalBox(!showHistoryModalBox);
    setProductId(productId);
  };

  const handleCheckboxChange = async (event, productDetail) => {
    let userStatus = {
      is_active: event.target.checked,
    };

    const response = await editProduct(productDetail?.product_id, userStatus);
    if (response?.success) {
      dispatch(postProductFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize }));
      successToast(response?.message);
    } else {
      successToast(response?.message);
    }
  };

  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredChips.filter(([existingKey]) => existingKey !== key);

    // Dispatch the updated chips to the Redux store
    dispatch(setFilteredChips(updatedChips));

    // Call the clearFilterValues function to clear the corresponding state values
    setClearFilterValues(key);

    const convertedChips = Object.fromEntries(updatedChips);

    let transformedChips = {};

    for (let key in convertedChips) {
      if (typeof convertedChips[key] === "object" && "label" in convertedChips[key] && "value" in convertedChips[key]) {
        transformedChips[key] = convertedChips[key].value;
      } else {
        transformedChips[key] = convertedChips[key];
      }
    }

    // Call the product filter API with the updated filteredChips
    dispatch(postProductFilterApi({ filteredData: transformedChips, page, size: currentSize + 10 }));
  };

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);
    const response = await dispatch(postProductFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize + 10 }));
    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload.records;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoading(false);
  }, [loading, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, dispatch, page, transformedDataToBeFiltered]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("productComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("productComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  function calculateFinalPrice(productPrice, vatPercentage = 0, serviceFeePercentage = 0) {

    const vatAmount = vatPercentage > 0 ? (productPrice * vatPercentage) / 100 : 0;
    const serviceFeeAmount = serviceFeePercentage > 0 ? (productPrice * serviceFeePercentage) / 100 : 0;
    const finalPrice = productPrice - vatAmount - serviceFeeAmount;
  
    return finalPrice.toFixed(0);
  }
  
  return (
    <>
      <div className="card mt-2">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Products</h5>
          <div className="filter-card" style={{ display: "block" }}>
            {filteredChips.map(([key, value]) => (
              <div className="chip" key={key}>
                <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                <span>{chipName(key)} {":"} {key === "country_id" || key === "carrier_id" || key === "is_active" ? value?.label : value}</span>
                <button type="button" className="zap">
                  <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <InfiniteScroll dataLength={productDetails.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="productComponent">
          <div id="productComponent" className="card-datatable table-responsive pb-0" style={{ height: "400px", overflowY: "auto" }}>
            <table className=" table" id="orders">
              <thead>
                <tr className="align-baseline">
                  <th>Number</th>
                  <th width="10%">Name</th>
                  {RoleId !== userRoleConstants.RETAILER_ID && (
                    <th>
                      {" "}
                      Price <small>(USD)</small>
                    </th>
                  )}
                  <th>Offer Price <small>(USD)</small></th>
                  <th>Carrier</th>
                  <th>Country</th>
                  {RoleId === userRoleConstants.ADMIN_ID && (
                    <th>
                      {" "}
                      Commission <small>(%)</small>{" "}
                    </th>
                  )}
                  {RoleId !== userRoleConstants.ADMIN_ID && <th width="13%">Last Update</th>}
                  {RoleId === userRoleConstants.RETAILER_ID && (
                    <th>
                      {" "}
                      Recommended Price <small>(USD)</small>{" "}
                    </th>
                  )}
                  {RoleId === userRoleConstants.RETAILER_ID && (
                    <th>
                      {" "}
                      Final Price <small>(USD)</small>{" "}
                    </th>
                  )}
                  {RoleId !== userRoleConstants.RETAILER_ID && <th>Status</th>}
                  {RoleId !== userRoleConstants.DISTRIBUTOR_ID && RoleId !== userRoleConstants.DISTRIBUTOR_USER_ID && <th width="10%">Action</th>}
                </tr>
              </thead>
              <tbody>
                {productDetails?.length !== 0 ? 
                  (productDetails?.map((productDetail, index) => (
                    <tr key={index}>
                      <td>
                        {" "}
                        <Link
                          to=""
                          onClick={() => {
                            setShowProductDetails(true);
                            setProductAllDetails(productDetail);
                          }}
                        >
                          {" "}
                          {productDetail?.product_no}{" "}
                        </Link>{" "}
                      </td>
                      <td>{productDetail?.product_name}</td>
                      {RoleId !== userRoleConstants.RETAILER_ID && <td>{formatCreditBalance(productDetail?.product_price)}</td>}
                      <td>{calculateFinalPrice(productDetail?.product_price, vat, serviceFee)}</td>
                      <td>{productDetail?.carrier_name}</td>
                      <td>{productDetail?.country_name}</td>
                      {(RoleId === userRoleConstants.ADMIN_ID) && <td>{(productDetail?.commission)}</td>}
                      {(RoleId !== userRoleConstants.ADMIN_ID) && <td>{moment(productDetail?.updatedAt).format("DD MMM YY")}</td>}
                      {(RoleId === userRoleConstants.RETAILER_ID) && ( <td> <div className="d-flex"> <p className="mb-0 fw-medium">{(Number(productDetail?.product_price) + (Number(productDetail?.product_price) * Number(productDetail?.recommended_commission)) / 100).toFixed(2)}</p> <p className="ms-2 text-warning mb-0">{productDetail?.recommended_commission}%</p> </div> </td> )}
                      {(RoleId === userRoleConstants.RETAILER_ID) && ( <td> <div className="d-flex"> <p className="mb-0 fw-medium">{(Number(productDetail?.product_price) + (Number(productDetail?.product_price) * Number(productDetail?.final_commission)) / 100).toFixed(2)}</p> <p className="ms-2 text-info mb-0">{productDetail?.final_commission}%</p> </div> </td> )}
                      {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) && ( <td> <span className={productDetail?.is_active === 1 ? "badge bg-label-success" : "badge bg-label-danger"}>{productDetail?.is_active === 1 ? "Active" : "Inactive"}</span> </td> )}
                      {(RoleId === userRoleConstants.ADMIN_ID) && ( <td> <div className="d-flex"> <input className="input-switch" type="checkbox" id={`demo-${index}`} checked={productDetail?.is_active === 1} onChange={(event) => handleCheckboxChange(event, productDetail)} /> <label className="label-switch" htmlFor={`demo-${index}`} /> <span className="info-text ms-2" /> </div> </td> )}
                      {(RoleId !== userRoleConstants.DISTRIBUTOR_ID) && (RoleId !== userRoleConstants.DISTRIBUTOR_USER_ID) && ( <td> <Link to="" onClick={() => {setProductAllDetails(productDetail); setShowModalBox(true);}} className="me-3" title="Edit Price" > <i className="ti ti-cash " /> </Link> <Link to="" onClick={() => { handleShowHistoryModal(productDetail?.product_id) }} title="History" > <i className="ti ti-clock " /> </Link> </td> )}
                    </tr>
                  ))) : (<tr className="pt-3 ps-3"><td colSpan="8">No product found</td></tr>)
                }
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      </div>
      {showModalBox && <EditPriceModal showModalBox={showModalBox} setShowModalBox={setShowModalBox} productAllDetails={productAllDetails} currentSize={currentSize} />}
      {showHistoryModalBox && <ProductHistoryModal showHistoryModalBox={showHistoryModalBox} setShowHistoryModalBox={setShowHistoryModalBox} productId={productId} account_no={""} RoleId={RoleId} />}
      {showProductDetails && <ViewProductDetails showProductDetails={showProductDetails} setShowProductDetails={setShowProductDetails} productAllDetails={productAllDetails} />}
    </>
  );
};

export default ManageProductGrid;
