import React, { useEffect, useRef, useState } from "react";
import ManageFilter from "./ManageFilter";
import { Link } from "react-router-dom";
import localStorageStore from "../../../utils/localStorageStore";
import { userRoleConstants, helpAndSupportTag } from "../../../utils/constants";
import SendQuery from "./SendQuery";
import { getQueryDetails, getQueryCategories, replyQueryResponse, requestFollowUp, postQueryStatus } from "../../../services/helpAndSupportService";
import { postHelpAndSupportFilterApi } from "../../../redux/features/helpAndSupportSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { errorToast, successToast } from "../../Toasts/Toasts";
import ReplyNotResolved from "./ReplyNotResolved";

const HelpAndSupport = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showQueryBox, setShowQueryBox] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [currentSize] = useState(10);
  const [tagId, setTagId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(); // Define count state variable
  const [queryDetails, setqueryDetails] = useState([]);
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [files, setFiles] = useState([]);
  const [ticketId, setTicketId] = useState("");
  const [showNotResolvedBox, setShowNotResolvedBox] = useState(false);
  const [isFollowUpRequested, setIsFollowUpRequested] = useState(true);
  const RoleId = parseInt(localStorageStore.getRole());
  const dispatch = useDispatch();

   
  // eslint-disable-next-line 
  let folders = [];

  const quillRef = useRef();

  useEffect(() => {
    // Fetch count data based on the selected folder (tagId)
    if (tagId) {
      dispatch(postHelpAndSupportFilterApi({ filteredData: { tag_id: tagId }, page: currentPage, size: currentSize }));
    }
  }, [tagId, currentPage, currentSize, dispatch]);

  const helpAndSupportDetails = useSelector((state) => state.helpAndSupport.helpAndSupportDetails);
  const helpAndSupportCount = useSelector((state) => state.helpAndSupport.count[0]);

  // const totalPages = Math.ceil(helpAndSupportCount / currentSize);
  useEffect(() => {
    fetchQueryCategories();
  }, []);

  if (RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.SUPERVISOR_ID) {
    folders = [
      { target: "sent", icon: "ti ti-mail-opened ti-sm", text: "Open", badgeClass: "bg-label-info", badgeValue: helpAndSupportTag.OPEN, badgeCount: helpAndSupportCount?.total_open_ticket },
      { target: "trash", icon: "ti ti-mail-pause ti-sm", text: "Pending", badgeClass: "bg-label-primary", badgeValue: helpAndSupportTag.PENDING, badgeCount: helpAndSupportCount?.total_pending_ticket },
      { target: "spam", icon: "ti ti-mail-check ti-sm", text: "Solved", badgeClass: "bg-label-success", badgeValue: helpAndSupportTag.SOLVED, badgeCount: helpAndSupportCount?.total_solved_ticket },
      { target: "starred", icon: "ti ti-mail-plus ti-sm", text: "Re-open", badgeClass: "bg-label-warning", badgeValue: helpAndSupportTag.RE_OPEN, badgeCount: helpAndSupportCount?.total_reopen_ticket },
      { target: "draft", icon: "ti ti-mail-x ti-sm", text: "Closed", badgeClass: "bg-label-danger", badgeValue: helpAndSupportTag.CLOSED, badgeCount: helpAndSupportCount?.total_closed_ticket },
    ];
  } else if (RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID) {
    folders = [
      { target: "trash", icon: "ti ti-mail-pause ti-sm", text: "Pending", badgeClass: "bg-label-info", badgeValue: helpAndSupportTag.PENDING, badgeCount: helpAndSupportCount?.total_pending_ticket },
      { target: "spam", icon: "ti ti-mail-check ti-sm", text: "Solved", badgeClass: "bg-label-success", badgeValue: helpAndSupportTag.SOLVED, badgeCount: helpAndSupportCount?.total_solved_ticket },
      { target: "draft", icon: "ti ti-mail-x ti-sm", text: "Closed", badgeClass: "bg-label-danger", badgeValue: helpAndSupportTag.CLOSED, badgeCount: helpAndSupportCount?.total_closed_ticket },
    ];
  }

  useEffect(() => {
    if (folders.length > 0 && !tagId) {
      setTagId(folders[0]?.badgeValue);
    }
    if (tagId === 1 || tagId === 2) {
      const selectedFolder = folders.find((folder) => folder.badgeValue === tagId); // Find the folder with matching badgeValue
      if (selectedFolder) {
        setCount(selectedFolder.badgeCount); // Set the count based on the selected folder
      }
    }
  }, [folders, tagId]);

  const fetchQueryCategories = async () => {
    try {
      const response = await getQueryCategories();
      setCategoriesData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Pagination handler for changing page
  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage * currentSize < count) {
      setCurrentPage(currentPage + 1);
    }
  };

  let timeoutId;
  const basicSearch = (event) => {
    const keyword = event?.target?.value?.trim();
    if (keyword !== "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          // Dispatch the action and await the response
          await dispatch(postHelpAndSupportFilterApi({ filteredData: { search_input: keyword }, page: currentPage, size: currentSize }));
        } catch (error) {
          // Handle error here
          errorToast(error?.response?.data?.message);
        }
      }, 1200);
    } else {
      // Clear the timeout and reset the state
      clearTimeout(timeoutId);
      setCurrentPage(1); // Reset to the first page
      setTagId(""); // Reset the tagId to show all items
    }
  };

  const showEmailBox = (id) => {
    const box = document.getElementById("app-email-view"); // Remove '#' from the id
    box.classList.add("show");
    handleDetails(id); // Use classList.add() to add the 'show' class
  };

  const handleDetails = async (id) => {
    try {
      const response = await getQueryDetails(id);
      setqueryDetails(response?.data);
      // Parse follow_up_last_update into a Date object
      const lastUpdateDate = new Date(response?.data?.follow_up_last_update);
      // Get today's date
      const today = new Date();
      // Calculate the difference in milliseconds between today and lastUpdateDate
      const timeDiff = today - lastUpdateDate;
      // Calculate the difference in days
      const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      // Check if the difference is less than or equal to 2 days
      if (diffDays <= 2 && response?.data?.follow_up_count < 2) {
        setIsFollowUpRequested(true);
      } else {
        setIsFollowUpRequested(false);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    // Check if the selectedFiles array length exceeds 5
    if (selectedFiles.length + files.length > 5) {
      // Notify the user about the maximum number of files allowed
      errorToast("Maximum 5 files are allowed");
      return;
    }

    // Loop through each selected file
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // Check if file type is allowed
      if (!allowedTypes.includes(file.type)) {
        errorToast(`File type not allowed for ${file.name}`);
        continue;
      }
      // Check if file size is within the allowed limit
      if (file.size > maxSize) {
        errorToast(`File size exceeds 2 MB limit for ${file.name}`);
        continue;
      }

      setFiles((prevFiles) => [...prevFiles, file]);

      event.target.value = '';
    }
  };

  const deleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleDescriptionChange = (content) => {
    const regex = /<p>(.*?)<\/p>/;
    const match = content?.match(regex);
    const extractedValue = match ? match[1] : null;
    setDescription(extractedValue === "<br>" ? "" : extractedValue);
  };

  async function getFilesBase64(files) {
    const base64Array = [];
    for (const file of files) {
      const base64String = await getFileBase64(file);
      base64Array.push({ fileName: file.name, fileType: file?.name?.split(".")?.pop(), base64: `data:${file?.type};base64,${base64String}` });
    }
    return base64Array;
  }

  async function getFileBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  useEffect(() => {
    const changeToBase64 = async () => {
      try {
        const base64Files = await getFilesBase64(files);
        setAttachment(base64Files); // Array of objects containing name and base64 representation of each file
        // Now you can use the base64Files variable as needed
      } catch (error) {
        console.error("Error converting files to base64:", error);
      }
    };

    changeToBase64();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleReplyQuery = async () => {
    const button = document.getElementById("submitButton");
    if (description !== "") {
      const requestData = {
        ticketId: queryDetails?.ticketHistory[0]?.ticket_id,
        description: description,
        attachments: attachment,
        title: queryDetails?.ticketHistory[0]?.title
      };
      const fileInput = document.getElementById("file-input");
      try {
        button.disabled = true;
        const sendQueryResponse = await replyQueryResponse(requestData);
        if (sendQueryResponse?.success) {
          successToast(sendQueryResponse?.data);
          setDescription("");
          setAttachment([]);
          setFiles([]); // Reset the files state to empty array
          quillRef.current.editor.setContents([{ insert: "\n" }]);
          setShowQueryBox(!setShowQueryBox);
          dispatch(postHelpAndSupportFilterApi({ filteredData: { tag_id: tagId }, page: currentPage, size: currentSize }));
          // Hide the element with ID "app-email-view"
          emailView();
          fileInput.value = "";
          button.disabled = false;
        } else {
          errorToast("Failed to raise query");
          setShowQueryBox(!setShowQueryBox);
          button.disabled = false;
        }
      } catch (error) {
        // Handle errors here
        errorToast(error?.response?.data?.message);
        button.disabled = false;
      }
    } else {
      errorToast("Please fill the required fields");
    }
  };

  const handleFollowUpRequest = async (id) => {
    try {
      const response = await requestFollowUp({ ticket_id: id });
      if (response?.success) {
        successToast(response?.message);
        setIsFollowUpRequested(true);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  // Function to generate initials from a name
  const generateInitials = (name) => {
    if (!name) return ""; // Return empty string if name is falsy
    // Split the name into words
    const words = name.split(" ");
    // Extract the first character of each word and convert to uppercase
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    // Join the initials together
    return initials.join("");
  };

  const queryStatus = async (value, ticket_id) => {
    let obj = {
      ticketId: ticket_id,
      isResolved: value,
    };
    try {
      const response = await postQueryStatus(obj);
      successToast(response?.message);
      if (value === false) {
        setShowNotResolvedBox(true);
        setTicketId(ticket_id);
      } else {
        dispatch(postHelpAndSupportFilterApi({ filteredData: { tag_id: tagId }, page: currentPage, size: currentSize }));
        // Hide the element with ID "app-email-view"
        emailView();
        setTicketId(ticket_id);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const refreshGridData = async () => {
    if (tagId) {
      dispatch(postHelpAndSupportFilterApi({ filteredData: { tag_id: tagId }, page: currentPage, size: currentSize }));
    }
  };

  const emailView = () => {
    // Hide the element with ID "app-email-view"
    const box = document.getElementById("app-email-view");
    box.classList.remove("show");
    if (tagId === 1) {
      dispatch(postHelpAndSupportFilterApi({ filteredData: { tag_id: tagId }, page: currentPage, size: currentSize }));
    }
  };

  return (
    <>
      <div className="app-email card">
        <div className="row g-0">
          {/* Email Sidebar */}
          <div className="col app-email-sidebar border-end flex-grow-0" id="app-email-sidebar">
            {(RoleId === userRoleConstants.ADMIN_ID||RoleId === userRoleConstants.SUPERVISOR_ID) ? (
              <h5 className="card-title mb-0 p-4 pb-3">Help &amp; Support</h5>
            ) : (
              <div className="btn-compost-wrapper d-grid">
                <button className="btn rounded-pill btn-primary btn-compose waves-effect waves-light" id="emailComposeSidebarLabel" onClick={() => setShowQueryBox(true)}>
                  Send Query
                </button>
              </div>
            )}
            {/* Email Filters */}
            <div className="email-filters py-2 ps">
              {/* Email Filters: Folder */}
              <ul className="email-filter-folders list-unstyled mb-4">
                {folders.map((folder, index) => (
                  <li
                    key={folder.target}
                    className={`d-flex justify-content-between ${tagId === folder.badgeValue || (!tagId && index === 0) ? "active" : ""}`}
                    data-target={folder.target}
                    onClick={() => {
                      setTagId(folder.badgeValue);
                      setCount(folder.badgeCount);
                    }}
                  >
                    <Link to="#" className="d-flex flex-wrap align-items-center">
                      <i className={folder.icon} />
                      <span className="align-middle ms-2">{folder.text}</span>
                    </Link>
                    <div className={`badge rounded-pill badge-center ${folder.badgeClass}`}>{folder.badgeCount}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/*/ Email Sidebar */}
          {/* Emails List */}
          <div className="col app-emails-list">
            <div className="shadow-none border-0">
              <div className="emails-list-header p-3 py-lg-3 py-2">
                {/* Email List: Search */}
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center w-100">
                    <i className="ti ti-menu-2 ti-sm cursor-pointer d-block d-lg-none me-3" data-bs-toggle="sidebar" data-target="#app-email-sidebar" data-overlay="" />
                    <div className="mb-0 mb-lg-2 w-100">
                      <div className="input-group input-group-merge shadow-none">
                        <span className="input-group-text border-0 ps-0" id="email-search">
                          <i className="ti ti-search" />
                        </span>
                        <input type="text" className="form-control email-search-input border-0" placeholder="Search mail" aria-label="Search mail" aria-describedby="email-search" onChange={basicSearch} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-0 mb-md-2">
                    <i className="ti ti-rotate-clockwise ti-sm rotate-180 scaleX-n1-rtl cursor-pointer email-refresh me-2" onClick={refreshGridData} />
                    <div className="dropdown d-flex align-self-center">
                      <ManageFilter showFilter={showFilter} setShowFilter={setShowFilter} categoriesData={categoriesData} currentSize={currentSize} currentPage={currentPage} tagId={tagId} />
                    </div>
                  </div>
                </div>
                <hr className="mx-n3 emails-list-header-hr" />
                {/* Email List: Actions */}
                <div className="d-flex justify-content-end align-items-center">
                  <div className="d-flex align-items-center"></div>
                  <div className="email-pagination d-sm-flex d-none align-items-center flex-wrap justify-content-between justify-sm-content-end">
                    {count !== undefined && (
                      <span className="d-sm-block d-none mx-3 text-muted">
                        {currentPage * currentSize - currentSize + 1}-{Math.min(currentPage * currentSize, count)} of {count}
                      </span>
                    )}
                    <i className={`email-prev ti ti-chevron-left ti-sm scaleX-n1-rtl cursor-pointer me-2 ${currentPage > 1 ? "" : "text-muted"}`} onClick={() => handlePageChange("prev")} />
                    <i className={`email-next ti ti-chevron-right ti-sm scaleX-n1-rtl cursor-pointer ${currentPage * currentSize < count ? "" : "text-muted"}`} onClick={() => handlePageChange("next")} />
                  </div>
                </div>
              </div>
              <hr className="container-m-nx m-0" />
              {/* Email List: Items */}
              <div className="email-list pt-0 ps--active-y">
                {helpAndSupportDetails?.length !== 0 ? (
                  helpAndSupportDetails?.map((supportDetails) => (
                    <ul className="list-unstyled m-0" key={supportDetails?.id}>
                      <li
                        className="email-list-item"
                        data-starred="true"
                        data-bs-toggle="sidebar"
                        data-target="#app-email-view"
                        onClick={() => {
                          showEmailBox(supportDetails?.id);
                        }}
                      >
                        {" "}
                        {/* Pass the function without invoking */}
                        <div className="d-flex">
                          <div className="avatar avatar-md d-block flex-shrink-0 me-sm-3 me-2 ms-2">
                            <span className="avatar-initial rounded-circle bg-label-danger">{supportDetails?.name && generateInitials(supportDetails.name)}</span>
                          </div>
                          <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="email-list-item-content ms-2 ms-sm-0 me-2">
                                <span className="h6 email-list-item-username me-1"> {supportDetails?.name} </span>
                                <span className="text-info">#{supportDetails?.id}</span>
                              </div>
                              <div className="email-list-item-meta ms-auto d-flex align-items-center">
                                {(RoleId === userRoleConstants.ADMIN_ID||RoleId === userRoleConstants.SUPERVISOR_ID) && (<small className={`badge rounded-pill ${supportDetails?.role === "Retailer" ? "bg-label-danger" : supportDetails?.role === "Distributor" ? "bg-label-success" : "bg-label-warning"}`}>{supportDetails?.role}</small>)}
                                <span className={`email-list-item-label badge rounded-pill ms-2 ${supportDetails?.category_name?.toLowerCase() === "top-up related" ? "bg-label-info" : supportDetails?.category_name?.toLowerCase() === "platform related" ? "bg-label-warning" : "bg-label-danger"} capital`}>{(supportDetails?.category_name)}</span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="email-list-item-content ms-2 ms-sm-0 me-2">
                                <span className="email-list-item-subject d-xl-inline-block d-block small ms-1">{supportDetails?.CommunicationLogs[0]?.title}</span>
                              </div>
                              <div className="email-list-item-meta ms-auto d-flex align-items-center">
                                <small className="email-list-item-time text-muted">{moment(supportDetails?.CommunicationLogs[0]?.updatedAt).format("h:mm a")}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))
                ) : (
                  <ul className="list-unstyled m-0">
                    <li>No items found.</li>
                  </ul>
                )}
              </div>
            </div>
            <div className="app-overlay" />
          </div>
          <div className="col app-email-view flex-grow-0 bg-body" id="app-email-view">
            <div className="card shadow-none border-0 rounded-0 app-email-view-header p-3 py-md-3 py-2">
              {/* Email View : Title  bar*/}
              <div className="d-flex justify-content-between align-items-center py-2">
                <div className="d-flex w-100 justify-content-between">
                  <div>
                    <i className="ti ti-chevron-left ti-sm cursor-pointer me-2" onClick={emailView} />
                    {queryDetails && queryDetails.ticketHistory && queryDetails.ticketHistory[0] && <span className="text-info h6">#{queryDetails.ticketHistory[0]?.ticket_id}</span>}
                  </div>
                  <div>{queryDetails && queryDetails.ticketHistory && queryDetails.ticketHistory[0] && <span className={`badge rounded-pill ${queryDetails?.ticketHistory[0]?.category_name === "TOPUP RELATED" ? "bg-label-info" : queryDetails?.ticketHistory[0]?.category_name === "PLATFORM RELATED" ? "bg-label-warning" : "bg-label-danger"}`}>{queryDetails?.ticketHistory[0]?.category_name}</span>}</div>
                </div>
                {/* Email View : Action  bar*/}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center"></div>
              </div>
            </div>
            <hr className="m-0" />
            {/* Email View : Content*/}
            <div className="app-email-view-content py-4 ps--active-y email-list">
              {/* Email View : Previous mails*/}
              {/* Email View : Last mail*/}
              {queryDetails?.ticketHistory?.map((queryDetail, index) => (
                <div className="card email-card-last mx-sm-4 mx-3 mt-4" key={queryDetail?.id}>
                  <div className="card-header d-flex justify-content-between align-items-center flex-wrap">
                    <div className="d-flex align-items-center mb-sm-0 mb-3">
                      <div className="avatar">
                        <span className="avatar-initial rounded-circle bg-label-danger text-primary">{queryDetail?.name && generateInitials(queryDetail.name)}</span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="m-0">{queryDetail?.name}</h6>
                        <small className="text-muted">{queryDetail?.role === userRoleConstants.CUSTOMER_ID ? "Customer" : queryDetail?.role === userRoleConstants.DISTRIBUTOR_ID ? "Distributor" : queryDetail?.role === userRoleConstants.ADMIN_ID ? "Admin" : queryDetail?.role === userRoleConstants.SUPERVISOR_ID ? "Supervisor" : "Retailer"}</small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 me-3 text-muted">{moment.utc(queryDetail?.updatedAt).format("DD MMM YYYY, hh:mm A")}</p>
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="text-truncate mb-3 me-2 ms-0 ">
                      <span className="text-muted">Title:</span> {queryDetail?.title}
                    </h6>
                    <p>{queryDetail?.description}</p>
                    <p className="mb-0">Sincerely yours,</p>
                    <p className="fw-medium mb-0">
                      {queryDetail?.name} {queryDetail?.role === userRoleConstants.CUSTOMER_ID ? "(Customer)" : queryDetail?.role === userRoleConstants.DISTRIBUTOR_ID ? "(Distributor)" : queryDetail?.role === userRoleConstants.ADMIN_ID ? "(Admin)" : queryDetail?.role === userRoleConstants.SUPERVISOR_ID ? "(Supervisor)" : "(Retailer)"}
                    </p>
                    <hr />
                    <p className="email-attachment-title mb-2">Attachments</p>
                    {queryDetail?.Attachments?.map((attachment) => (
                      <Link to={attachment?.file_name} className="cursor-pointer" key={attachment?.id}>
                        <i className="ti ti-file" />
                        <span className="align-middle ms-1">{attachment?.file_name.split("/").pop()}, </span>
                      </Link>
                    ))}
                    {index === 0 && isFollowUpRequested && (RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID) && tagId === helpAndSupportTag.PENDING && (
                      <p className="mt-3 mb-0">
                        <Link to="#" onClick={() => handleFollowUpRequest(queryDetail?.ticket_id)}>
                          {" "}
                          Request a Follow Up
                        </Link>
                      </p>
                    )}
                    {index === queryDetails?.ticketHistory.length - 1 && (RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID) && tagId === helpAndSupportTag.SOLVED && (
                      <div className="col-12 text-end mt-2">
                        <Link type="button" className="btn btn-sm btn-label-secondary rounded-pill btn-reset not-resolved me-1" onClick={() => queryStatus(false, queryDetail?.ticket_id)}>
                          Not Resolved
                        </Link>
                        <Link type="button" className="btn btn-sm btn-primary rounded-pill me-sm-3 me-1" onClick={() => queryStatus(true, queryDetail?.ticket_id)}>
                          Resolved
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {/* Email View : Reply mail*/}
              {tagId !== helpAndSupportTag.CLOSED && tagId !== helpAndSupportTag.SOLVED && (
                <div className="email-reply card mt-4 mx-sm-4 mx-3">
                  {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID) && <h6 className="card-header border-0">Reply to Admin</h6>}
                  {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && queryDetails && queryDetails.ticketHistory && queryDetails.ticketHistory[0] && <h6 className="card-header border-0">{`Reply to ${queryDetails?.ticketHistory[0]?.name}`}</h6>}
                  <div className="card-body pt-0 px-3">
                    <div className="email-compose-content container-m-nx">
                      <ReactQuill ref={quillRef} onChange={handleDescriptionChange} modules={{ toolbar: false }} placeholder="Write your message here..." style={{ marginLeft: "2%", width: "95%" }} id="react-quil" />
                    </div>
                    <div className="file-input-container">
                      <input type="file" multiple onChange={handleFileChange} className="d-none" id="file-input" />
                      <label htmlFor="file-input" className="attach-file">
                        <i className="ti ti-paperclip cursor-pointer ms-2" />
                      </label>
                      <span>{files.length} file(s) selected</span>
                    </div>

                    {/* Display selected files and delete button */}
                    <div className="selected-files-container" style={{ paddingLeft: "1%" }}>
                      {files.map((file, index) => (
                        <div key={index} className="file-item">
                          <span>{file.name}</span>
                          <button type="button" className="btn delete-file" onClick={() => deleteFile(index)}>
                            <i className="ti ti-trash" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <button className="btn btn-sm btn-primary rounded-pill" onClick={handleReplyQuery} id="submitButton">
                        <i className="ti ti-send ti-xs me-1" />
                        <span className="align-middle">Send</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showQueryBox && <SendQuery showQueryBox={showQueryBox} setShowQueryBox={setShowQueryBox} categoriesData={categoriesData} />}
      {showNotResolvedBox && <ReplyNotResolved queryDetails={queryDetails} showNotResolvedBox={showNotResolvedBox} setShowNotResolvedBox={setShowNotResolvedBox} categoriesData={categoriesData} ticketId={ticketId} currentPage={currentPage} currentSize={currentSize} tagId={tagId} emailView={emailView} />}
    </>
  );
};

export default HelpAndSupport;
