import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

const BillingViewDetailsModal = ({ setShowDetailsModal, showDetailsModal, allTransactionDetails , selectedRoleTabId, paymentTypeTabId, roleId, userRoleConstants, }) => {
  const handleCloseModal = () => {
    setShowDetailsModal(!showDetailsModal);
  };
  
  return (
    <>
      <Modal show={showDetailsModal} size="md" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <div className="modal-body">
              <button type="button" className="btn-close" onClick={handleCloseModal} data-bs-dismiss="modal" aria-label="Close" />
              <div className="text-left mb-4">
                <h3 className="mb-2">{paymentTypeTabId === 1 ? "View credit details" : "View wallet details"}</h3>
              </div>
              <div className="row">
                {(roleId === userRoleConstants.ADMIN_ID  || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Transaction ID</h6>
                  <small className="text-muted">{allTransactionDetails?.transaction_id}</small>
                </div>}
                {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID) && (selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">
                    {" "}
                    Account No.
                  </h6>
                  <small className="text-muted">{allTransactionDetails?.account_no}</small> 
                </div>}

                {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">
                    {" "}
                    Distributor Name
                  </h6>
                  <small className="text-muted">{allTransactionDetails?.name}</small>
                </div> : roleId === userRoleConstants.DISTRIBUTOR_ID && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2) ? <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">
                    {" "}
                    Retailer Name
                  </h6>
                  <small className="text-muted">{allTransactionDetails?.name}</small>
                </div> : ""}
                {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">
                    {" "}
                    Amount <small>(USD)</small>
                  </h6>
                  <small className="text-muted">{allTransactionDetails?.amount}</small>
                </div>}
                {(((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.RETAILER_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2))) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Request Date</h6>
                  <small className="text-muted">{moment(allTransactionDetails?.request_date, moment.ISO_8601).format('DD MMM YY hh:mm A') !== "Invalid date" ? moment.utc(allTransactionDetails?.request_date, moment.ISO_8601).format('DD MMM YY, hh:mm A') : "--"}</small>
                </div>}
                {(roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 2 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Credit Date</h6>
                  <small className="text-muted">{moment(allTransactionDetails?.credit_date, moment.ISO_8601).format('DD MMM YY hh:mm A') !== "Invalid date" ? moment.utc(allTransactionDetails?.credit_date, moment.ISO_8601).format('DD MMM YY, hh:mm A') : "--"}</small>
                </div>}
                {(((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID) && selectedRoleTabId === 2 && paymentTypeTabId === 1) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0)) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Due Date</h6>
                  <small className="text-muted">{moment(allTransactionDetails?.due_date, moment.ISO_8601).format('DD MMM YY  hh:mm A') !== "Invalid date" ? moment.utc(allTransactionDetails?.due_date, moment.ISO_8601).format('DD MMM YY, hh:mm A') : "--"}</small>
                </div>}
                {(((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID)&& selectedRoleTabId === 2 && paymentTypeTabId === 1) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0)) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Payment Date</h6>
                  <small className="text-muted">{moment(allTransactionDetails?.payment_date, moment.ISO_8601).format('DD MMM YY hh:mm A') !== "Invalid date" ? moment.utc(allTransactionDetails?.payment_date, moment.ISO_8601).format('DD MMM YY, hh:mm A') : "--"}</small>
                </div>}
                {(((roleId === userRoleConstants.ADMIN_ID || roleId === userRoleConstants.ACCOUNTANT_ID)&& selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID || roleId === userRoleConstants.RETAILER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && (paymentTypeTabId === 1 || paymentTypeTabId === 2))) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Status</h6>
                  <small className="text-Successful">{allTransactionDetails?.credit_status_type_name}</small>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BillingViewDetailsModal;
