import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transactionUpdate } from "../../services/transactionService";

const initialState = {
  transactionUpdateDetails: [],
  isLoading: false,
  error: null,
};

export const getTransactionUpdateApi = createAsyncThunk(
  "transactionUpdate/getTransactionUpdateApi",
  async (data) => {
    try {
      const response = await transactionUpdate(data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const transactionUpdateSlice = createSlice({
  name: "transactionUpdate",
  initialState,
  reducers: {
    setInitTransactionUpdateDetail: (state) => {
      state.transactionUpdateDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactionUpdateApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getTransactionUpdateApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionUpdateDetails = [...action.payload];
    });

    builder.addCase(getTransactionUpdateApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = transactionUpdateSlice;
export const { setInitTransactionUpdateDetail } = transactionUpdateSlice.actions;
export default reducer;
