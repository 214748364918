import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme zindex-5">
      <div className="container-fluid">
        <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
          <span className="footer-text">©
	          Shaad Online <script>
		        document.write(new Date().getFullYear());
		        </script>2024, all rights owned by Shaad Online L.L.C FZ
          </span>
          <div className="d-none d-lg-inline-block">
            <Link to="#" className="footer-link me-4">
              FAQ
            </Link>
            <Link to="#" className="footer-link me-4">
              Privacy Policy
            </Link>
            <Link to="#" target="_blank" className="footer-link d-none d-sm-inline-block" >
              Support
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer