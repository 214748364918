import React,{useCallback, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { errorToast } from "../../Toasts/Toasts";
import { fetchCustomerProduct } from "../../../services/customerService";

const TopUpForm = () => {

  const [fullPhoneNumber, setFullPhoneNumber] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [size, setSize] = useState(10);

  const handlePhoneNumber = async (value) => {
    setFullPhoneNumber(value ?? "")
  }
  let page = 1;

  const parsedPhoneNumber = parsePhoneNumber(fullPhoneNumber ?? "");

  const handleValidation = (fullPhoneNumber) => {  
    if(parsedPhoneNumber?.nationalNumber?.length === 0 || fullPhoneNumber === ""){
      errorToast("Enter valid phone number")
    }
    


  }

  useEffect(() => {
    handleProductData();

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);

    try {
      const response = await fetchCustomerProduct(page, size + 10);

      if (response?.success) {
        const newData = response?.data?.records;
        if (newData?.length > 0 && newData?.length > currentResultSetLength) {
          setSize((prevSize) => prevSize + 10); // Increment page number
          setCurrentResultSetLength(newData?.length);
        } else {
          setHasMoreData(false);
        }
      } else {
        errorToast("Error occurred while loading more data");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while loading more data");
    } finally {
      setLoading(false);
    }
  }, [loading, hasMoreData, page, size, currentResultSetLength]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("productSelectionComponent");
      if (parseInt(scrollContainer.scrollHeight - scrollContainer.scrollTop) === parseInt(scrollContainer.clientHeight)) {
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("productSelectionComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [loadMoreData]);

  const handleProductData = async () => {
    try {
      const response = await fetchCustomerProduct(page, size);
      if (response?.success) {
        setHasMoreData(response?.data?.records.length === size); // Set hasMore based on whether there's more data to load
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  return (
    <section id="hero-animation">
      <div id="landingHero" className="section-py landing-hero position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="mt-2">
                <h2 className="text-dark fw-bold mb-md-0  ">Welcome to Shaad Online</h2>
                <h2 className="hero-sub-title h4 mb-3 pb-0">Your personal portal for sending happiness effortlessly</h2>
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <p className="px-3 hero-para">With us you're not just recharging your phone or sending a top-up, you're sending a piece of happiness to someone special. It's about those little moments of connection that brighten someone's day. So, come in and make yourself at home.</p>
                  </div>
                </div>
              </div>
              <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
                  <div className="card card-topup bg-outline-primary mt-4">
                    <div className="card-body px-4 py-4">
                      <h2 className="mb-4 pt-2 text-center">Lets Top-Up!</h2>
                      <form id="" className="mb-3" action="" method="POST">
                        <div className="mb-3">
                        <PhoneInput
                            className="form-control rounded-pill"
                            international
                            countryCallingCodeEditable={false}
                            // countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="AF"
                            countries={['AF']}
                            onChange={(value) => {
                              handlePhoneNumber(value);
                            }}
                          />
                        </div>
                        <div className="mb-3 mt-4">
                           <Link to={fullPhoneNumber.trim() !== "" ? `/customer/send-topup/${fullPhoneNumber}` : ""} className="btn btn-primary rounded-pill w-100 d-grid " onClick={() => {handleValidation(fullPhoneNumber)}} type="button">
                            Proceed
                          </Link> 
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="pic_2 d-none d-lg-flex">
            <img src="assets/img/girl.png" alt="Shaad" />
          </div>
          <div className="pic_3 d-none d-lg-flex">
            <img src="assets/img/boy.png" alt="Shaad" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopUpForm;
