import ContactsList from "./ContactsList";
import ContactDetails from "./ContactDetails";
import { useEffect, useState } from "react";
import CustomAlert from "../../Shared/CustomAlert";
import { topUpRequest } from "../../../services/transactionService";
import { approveRejectRechargeRequest, getTransactionDetails, storeTransactionDetails } from "../../../services/Customer";
import { getCustomerContactList } from "../../../services/customerService";
import { errorToast } from "../../Toasts/Toasts";
import { getMasterData } from "../../../services/userService";
import ProfileUpdated from "./ProfileUpdated";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notificationApi } from "../../../redux/features/notificationSlice";
import PaymentInProcessModal from "./PaymentInProcessModal";
import SelectPaymentMethod from "../../Shared/SelectPaymentMethod";
import RechargeModal from "./RechargeModal";

const Profile = () => {
  const [showCustomeAlertModal, setShowCustomeAlertModal] = useState(false);
  const [size, setSize] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = new URL(window.location.href);
  const sessionId = url.searchParams.get('session_id');
  const [rechargeDetails] = useState(localStorage.getItem("rechargeDetails"));
  const [alertMessage,setAlertMessage] = useState('');
  const [showClassName, setShowClassName] = useState('');
  const [iconClassName, setIconClassName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [indexSelected, setIndexSelected] = useState(0);
  const [showBox, setShowBox ] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const transactionId =  location?.state?.session_id ;
  const carrier =  location?.state?.carrierId ;
  const [render, setRender] = useState(false);

  const renderHistoryData = (response) => {
    setRender(response);
  }

  let page = 1;

  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get("carrier_id");

  const amount = useSelector(state => state?.paymentGateway?.amount);
  const carriersId = useSelector(state => state?.paymentGateway?.carrierId);

  useEffect( () => {
    if(amount?.length !== 0) {
      setShowBox(true);
    }

   },[amount,carriersId]);

  useEffect(() => {
    initiateStripePayment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, rechargeDetails]);

  useEffect(() => {
    initiatePaypalPayment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, rechargeDetails]);

  async function initiateStripePayment() {
    try {
      if (sessionId && rechargeDetails && Object.keys(rechargeDetails).length !== 0 ) {
        const response = await getTransactionDetails(sessionId);
        const transactionSuccessfullySaved = await storeTransactionDetails({
          amount: parseFloat(response?.data?.amount_total) / 100,
          payment_status: response?.data?.payment_status
        });

        const phoneNumberAndPhoneCode = localStorage.getItem("verificationPhoneDetails");

        const parsedPhoneDetails = JSON.parse(phoneNumberAndPhoneCode);

        if (transactionSuccessfullySaved?.success) {

          const userId = localStorage.getItem("userId");
          const response = JSON.parse(rechargeDetails);
          // const profileDataJSON = localStorage.getItem("profileData");
          // const profileData = JSON.parse(profileDataJSON);
          navigate(`/customer/profile?carrier_id=${carrierId}`);
          setShowModal(true);

          let obj = {
            phone_code: response?.phone_code !== "" ? response?.phone_code : parsedPhoneDetails?.phoneCode,
            mobile_number: response?.mobile_number !== "" ? response?.mobile_number : parsedPhoneDetails?.phoneNumber ,
            customer_id: parseInt(response?.requested_by) || parseInt(userId),
            aggregator_id: 1,
            carrier_id: carrierId,
            original_currency_type: response?.original_currency_type,
            converted_currency_type: response?.converted_currency,
            service_fee: response?.service_fee,
            vat: response?.vat,
            total_amount_source_currency: response?.total_source_amount,
            total_amount_converted_currency: response?.total_converted_amount,
            topup_amount_source_currency: response?.source_amount,
            topup_amount_converted_currency: response?.converted_amount,
            stripe_id: sessionId,
            ...((response?.requested_by !== response?.customer_contact_id && response?.customer_contact_id) && { customer_contact_id: parseInt(response?.customer_contact_id) }),
            ...(response?.recharge_request_id && { recharge_request_id:  response?.recharge_request_id}),
          };

          try {
            const topUpResp = await topUpRequest(obj);
            if (topUpResp) {
              if (topUpResp?.success) {
                if (topUpResp?.message?.toLowerCase()?.includes("success") ) {
                    setShowCustomeAlertModal(true);
                    setAlertMessage(topUpResp?.message);
                    setShowClassName('text-success check info');
                    setIconClassName('ti ti-check fs-1');
                } else if (topUpResp?.message?.toLowerCase()?.includes("process")) {
                    setShowCustomeAlertModal(true);
                    setAlertMessage(topUpResp?.message);
                    setShowClassName('text-warning check warning');
                    setIconClassName('ti ti-progress fs-1');
                } else {
                    setShowCustomeAlertModal(true);
                    setAlertMessage(topUpResp?.message);
                    setShowClassName('text-danger check danger');
                    setIconClassName('ti ti-x fs-1');
                }
                setShowModal(false);
                renderHistoryData(topUpResp?.message);
              }
              else {
                errorToast(response?.message);
                setShowModal(false);
                renderHistoryData(topUpResp?.message);
              }

              const rechargeThroughNotification = localStorage.getItem("rechargeThroughNotification");
              if (rechargeThroughNotification === "1" || response?.recharge_request_id) {
                const rechargeRequestId = localStorage.getItem("rechargeRequestId") || response?.recharge_request_id;

                const obj = {
                  recharge_request_id: rechargeRequestId,
                  recharge_request_updated_status: 1,
                };

                await approveRejectRechargeRequest(obj);

                localStorage.setItem("rechargeThroughNotification", "0");
              }
            }

          } catch (error) {
            errorToast(error?.response?.data?.message);
            setShowModal(false);

          }

          dispatch(notificationApi());

          localStorage.removeItem('rechargeDetails');
        }
        else{
          navigate('customer/profile');
        }
      }} catch (error) {
        errorToast(error?.response?.data?.message);
      }
  }

  async function initiatePaypalPayment() {
    try {
      if (transactionId && rechargeDetails && Object.keys(rechargeDetails).length !== 0) {
        setShowModal(true);
        const phoneNumberAndPhoneCode = localStorage.getItem("verificationPhoneDetails");
        const parsedPhoneDetails = JSON.parse(phoneNumberAndPhoneCode);
        const userId = localStorage.getItem("userId");
        const response = JSON.parse(rechargeDetails);
        let obj = {
          phone_code: response?.phone_code !== "" ? response?.phone_code : parsedPhoneDetails?.phoneCode,
          mobile_number: response?.mobile_number !== "" ? response?.mobile_number : parsedPhoneDetails?.phoneNumber,
          customer_id: parseInt(response?.requested_by) || parseInt(userId),
          aggregator_id: 1,
          carrier_id: carrier,
          original_currency_type: response?.original_currency_type,
          converted_currency_type: response?.converted_currency,
          service_fee: response?.service_fee,
          vat: response?.vat,
          total_amount_source_currency: response?.total_source_amount,
          total_amount_converted_currency: response?.total_converted_amount,
          topup_amount_source_currency: response?.source_amount,
          topup_amount_converted_currency: response?.converted_amount,
          paypal_transaction_id: transactionId,
          ...(response?.requested_by !== response?.customer_contact_id && response?.customer_contact_id && { customer_contact_id: parseInt(response?.customer_contact_id) }),
          ...(response?.recharge_request_id && { recharge_request_id: response?.recharge_request_id }),
        };

        try {
          const topUpResp = await topUpRequest(obj);
          if (topUpResp) {
            if (topUpResp?.success) {
              if (topUpResp?.message?.toLowerCase()?.includes("success")) {
                setShowCustomeAlertModal(true);
                setAlertMessage(topUpResp?.message);
                setShowClassName("text-success check info");
                setIconClassName("ti ti-check fs-1");
              } else if (topUpResp?.message?.toLowerCase()?.includes("process")) {
                setShowCustomeAlertModal(true);
                setAlertMessage(topUpResp?.message);
                setShowClassName("text-warning check warning");
                setIconClassName("ti ti-progress fs-1");
              } else {
                setShowCustomeAlertModal(true);
                setAlertMessage(topUpResp?.message);
                setShowClassName("text-danger check danger");
                setIconClassName("ti ti-x fs-1");
              }
              setShowModal(false);
              renderHistoryData(topUpResp?.message);
            } else {
              errorToast(response?.message);
              setShowModal(false);
              renderHistoryData(topUpResp?.message);
            }

            const rechargeThroughNotification = localStorage.getItem("rechargeThroughNotification");
            if (rechargeThroughNotification === "1" || response?.recharge_request_id) {
              const rechargeRequestId = localStorage.getItem("rechargeRequestId") || response?.recharge_request_id;

              const obj = {
                recharge_request_id: rechargeRequestId,
                recharge_request_updated_status: 1,
              };

              await approveRejectRechargeRequest(obj);

              localStorage.setItem("rechargeThroughNotification", "0");
            }
          }
        } catch (error) {
          errorToast(error?.response?.data?.message);
          setShowModal(false);
        }

        dispatch(notificationApi());
        localStorage.removeItem("rechargeDetails");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  }

  const [customerContactList, setCustomerContactList] = useState();
  const [selectedContact, setSelectedContact] = useState(null);
  const [masterData, setMasterData] = useState("");
  const [reRender, setReRender] = useState(false);
  const [renderDeleteContact, setRenderDeleteContact]  = useState(false);
  const [renderUpdateContact, setRenderUpdateContact] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [numberDisabled, setNumberDisabled] = useState(true);
  const [emailDisabled, setEmailDisabled] = useState(true);

  let id = "";

  useEffect(() => {
    if (customerContactList && customerContactList.length > 0) {
      let obj = {
        phoneCode: customerContactList[0]?.phone_code ?? "",
        phoneNumber: customerContactList[0]?.phone_number ?? ""
      };
      const contactDetailsAfterLogin = JSON.stringify(obj);

      localStorage.setItem("contactDetailsAfterLogin", contactDetailsAfterLogin);
    }
  }, [customerContactList]);

  localStorage.setItem("ContactId", selectedContact?.id);

  useEffect(() => {
    fetchCustomerContactList();
    fetchMasterData();
    // eslint-disable-next-line
  }, [reRender, renderDeleteContact, renderUpdateContact, indexSelected]);

  let userProfileData = useSelector((state) => state.customerProfile.customerDetails);

  useEffect(() => {
    if (selectedContact && (!userProfileData || !userProfileData.phone_number || userProfileData.carrier_id === null)) {
      setShowEditProfileModal(true);
      setNumberDisabled(false);
    }
    if(selectedContact && (!userProfileData || !userProfileData.email)) {
      setShowEditProfileModal(true);
      setEmailDisabled(false);
    }
  }, [selectedContact, userProfileData]);

  const handleReRender = (response) => {
    setReRender(response);
  };

  const handleDeleteContact = (response) => {
    setRenderDeleteContact(response);
  };

  const handleUpdateContact = (response) => {
    setRenderUpdateContact(response);
  }

  const fetchMasterData = async () => {
    try {
      const response = await getMasterData(id);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  const fetchCustomerContactList = async () => {
    try {
      const response = await getCustomerContactList(page,size);
      if (response?.success) {
        setCustomerContactList(response?.data);
        if (response?.data.length > 0 && selectedContact == null) {
          // setSelectedContact(response?.data[0]);
          const newSelectedContact = response?.data?.[indexSelected];
          setSelectedContact(newSelectedContact);
        } else if(response?.data.length > 0 && selectedContact !== null) {
          if (indexSelected >= 0) {
            const newSelectedContact = response?.data?.[indexSelected];
            setSelectedContact(newSelectedContact);
          } else {
            console.error("selectedContact or selectedContact.id is undefined");
          }
      }
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while fetching user profile.");
    }
  };

  const [showRechargeModal, setShowRechargeModal] = useState(false);

  const handleOpenModal = () => setShowRechargeModal(true);
  const handleCloseModal = () => setShowRechargeModal(false);

  return (
    <>
      <section className="section-py first-section-pt">
        <div className="container mt-2">
          <div className="row justify-content-md-center">
            <div className="col-lg-10 user-profile-details">
              <div className="row justify-content-end mb-3">
                <div className="col-auto">
                  <Link to="#" className="btn btn-secondary rounded-pill btn-sm add-mobile-btn" onClick={handleOpenModal}>
                     Recharge Any Number
                  </Link>
                </div>
              </div>
              <div className="border rounded p-2 profile-details card">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-12 mb-md-0 mb-3">
                    <ContactsList setIndexSelected={setIndexSelected} showCustomeAlertModal={showCustomeAlertModal} setShowCustomeAlertModal={setShowCustomeAlertModal} customerContactList={customerContactList} setSelectedContact={setSelectedContact} masterData={masterData} handleReRender={handleReRender} selectedContact={selectedContact} size={size} setSize={setSize} setCustomerContactList={setCustomerContactList} setAlertMessage={setAlertMessage} setShowClassName={setShowClassName} setIconClassName={setIconClassName} />
                  </div>
                  <div className="col-lg-9 col-md-8 col-12">
                    <ContactDetails customerContactList={customerContactList} selectedContact={selectedContact} masterData={masterData} handleDeleteContact={handleDeleteContact} handleUpdateContact={handleUpdateContact} render={render} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showCustomeAlertModal && <CustomAlert showCustomeAlertModal={showCustomeAlertModal} setShowCustomeAlertModal={setShowCustomeAlertModal} text={alertMessage} showClassName={showClassName} iconClassName={iconClassName} />}
      {showEditProfileModal && <ProfileUpdated showEditProfileModal={showEditProfileModal} setShowEditProfileModal={setShowEditProfileModal} selectedContact={selectedContact} masterData={masterData} handleUpdateContact={handleUpdateContact} numberDisabled={numberDisabled} emailDisabled={emailDisabled} />}
      {showModal && <PaymentInProcessModal showModal={showModal} />}
      {showBox && <SelectPaymentMethod showBox={showBox} setShowBox={setShowBox} setSelectedValue={setSelectedValue} selectedValue={selectedValue} amount={amount} carrierId={carriersId} />}
      {showRechargeModal && <RechargeModal show={showRechargeModal} handleClose={handleCloseModal} />}
    </>
  );
};
export default Profile;
