import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { editProduct } from "../../../services/productService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { postProductFilterApi } from "../../../redux/features/productSlice";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";

const EditPriceModal = ({ showModalBox, setShowModalBox, productAllDetails, userDetail, currentSize }) => {
  const [finalPrice, setFinalPrice] = useState("");
  const [commission, setCommission] = useState("");
  const [error, setError] = useState(false);
  const [commissionError, setCommissionError] = useState(false);
  const dispatch = useDispatch();
  let page = 1;
  const RoleId = parseInt(localStorageStore.getRole());
  const filteredChips = useSelector((state) => state.product.filteredChips);

  const dataToBeFiltered = Object.fromEntries(filteredChips);

  const handleClose = () => setShowModalBox(false);

  const onFinalPriceChange = (e) => {
    const finalPrice = parseFloat(e.target.value);
    const productPrice = parseFloat(productAllDetails?.product_price);
    const recommendedPrice = parseFloat((Number(productAllDetails?.product_price) + (Number(productAllDetails?.product_price) * Number(productAllDetails?.recommended_commission)) / 100).toFixed(2));
    if (finalPrice >= recommendedPrice) {
      const calculatedCommission = ((finalPrice - productPrice) * 100) / productPrice;
      const roundedCommission = Math.round(calculatedCommission * 100) / 100; // Limiting to 2 decimal places

      if (roundedCommission < 100) {
        // Check if the calculated commission is less than or equal to 100%
        setFinalPrice(finalPrice);
        setCommission(roundedCommission);
        setError(false);
        setCommissionError(false);
      } else {
        // If the calculated commission exceeds 100%, display an error
        setFinalPrice(finalPrice);
        setCommission("");
        setCommissionError(true);
        setError(false);
      }
    } else {
      setFinalPrice(finalPrice);
      setCommission("");
      setError(true);
      setCommissionError(false);
    }
  };

  const onSubmit = async () => {
    const button = document.getElementById("saveButton");
    button.disabled = true;
    try {
      let obj = {
        recommended_commission: productAllDetails?.recommended_commission,
        final_commission: commission,
      };
      let distributorData = {
        recommended_commission: productAllDetails?.recommended_commission,
        final_commission: commission,
        retailer_id: userDetail?.id,
      };
      let data = {
        commission: commission,
      };

      let transformedDataToBeFiltered = {};

      for (let key in dataToBeFiltered) {
        if (typeof dataToBeFiltered[key] === "object" && "label" in dataToBeFiltered[key] && "value" in dataToBeFiltered[key]) {
          transformedDataToBeFiltered[key] = dataToBeFiltered[key].value;
        } else {
          transformedDataToBeFiltered[key] = dataToBeFiltered[key];
        }
      }

      if (RoleId === userRoleConstants.ADMIN_ID) {
        const response = await editProduct(productAllDetails?.product_id, data);
        button.disabled = false;

        if (response?.success) {
          setShowModalBox(false);
          successToast(response?.message);
          dispatch(postProductFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize }));
        } else {
          errorToast(response?.message);
        }
      } else if (RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) {
        const response = await editProduct(productAllDetails?.product_id, distributorData);
        button.disabled = false;

        if (response?.success) {
          transformedDataToBeFiltered.account_no = userDetail?.account_no;
          setShowModalBox(false);
          successToast(response?.message);
          dispatch(postProductFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize }));
        } else {
          errorToast(response?.message);
        }
      } else {
        const response = await editProduct(productAllDetails?.product_id, obj);
        button.disabled = false;

        if (response?.success) {
          setShowModalBox(false);
          successToast(response?.message);
          dispatch(postProductFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize }));
        } else {
          errorToast(response?.message);
        }
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setShowModalBox(false);
    }
  };

  const onDistributorPriceChange = (e) => {
    const commission = e.target.value;
    if (commission > 0 && commission < 100) {
      setCommission(commission);
      const calculatedCommission = parseFloat((Number(productAllDetails?.product_price) + (Number(productAllDetails?.product_price) * commission) / 100).toFixed(2));
      setFinalPrice(calculatedCommission);
      setCommissionError(false);
    } else {
      setFinalPrice("");
      setCommission(commission);
      setCommissionError(true);
    }
  };

  return (
    <>
      <Modal show={showModalBox} size="sm" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <button type="button" className="btn-close" onClick={handleClose} />
              <div className="text-left mb-4">
                <h3 className="mb-2">Edit Price</h3>
              </div>
              <div className="row">
                {RoleId !== userRoleConstants.ADMIN_ID ? (
                  <>
                    <div className="col-lg-12 mb-3">
                      <label className="form-label">
                      Recommended Price <small>(USD)</small>
                      </label>
                      <input type="number" placeholder="" className="form-control mb-3" value={finalPrice} onChange={onFinalPriceChange} min={0} />
                      {error && <span className="text-danger">Final Price should be greater than Recommended Price. </span>}
                    </div>
                    <div className="col-lg-12 mb-1">
                      <label className="form-label">
                        Commission <small>(%)</small>
                      </label>
                      <input type="text" className="form-control mb-3 commission" disabled value={commission} onChange={(e) => setCommission(e.target.value)} />
                      {commissionError && <span className="text-danger">Commission should be less than 100% </span>}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-12 mb-3">
                      <label className="form-label">
                        Your Price <small>(USD)</small>
                      </label>
                      <input type="number" placeholder="" className="form-control mb-3" disabled value={finalPrice} onChange={(e) => setFinalPrice(e.target.value)} />
                    </div>
                    <div className="col-lg-12 mb-1">
                      <label className="form-label">
                        Commission <small>(%)</small>
                      </label>
                      <input type="number" placeholder="" className="form-control mb-3" value={commission} onChange={onDistributorPriceChange} min={0} />
                      {commissionError && <span className="text-danger">Commission should be greater than 0 and less than 100% </span>}
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-12 text-center mt-4">
                  <button type="submit" id="saveButton" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-filter" onClick={onSubmit}>
                    Submit
                  </button>
                  <button type="reset" onClick={handleClose} className="btn btn-label-secondary rounded-pill btn-reset waves-effect">
                    Cancel
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPriceModal;
