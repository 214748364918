import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const approveRejectRechargeRequest = async (data) => {
    try{
        const response = await axios.put(`${baseUrl}/${ApiConstants.ACCEPT_REJECT_RECHARGE_REQUEST}`, data)
        return response;
    } catch(error) {
        throw error;
    }
};

export const rechargeRequestDetails = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.RECHARGE_REQUEST_DETAILS}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const refundRequest = async (id) => {
    try{
        const response = await axios.get(`${baseUrl}/${ApiConstants.GET_REFUND}/${id}`)
        return response
    } catch(error) {
        throw error;
    }
}

export const rechargeRequest = async (data) => {
    try{
        const response = await axios.post(`${baseUrl}/${ApiConstants.RECHARGE_REQUEST}`, data)
        return response;
    } catch (error) {
        throw error;
    }
}

export const customerCurrencyConversion = async (data) => {
    try{
        const response = await axios.post(`${baseUrl}/${ApiConstants.CONVERT_CURRENCY}`, data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSessionId = async (data) => {
    try{
        const response = await axios.post(`${baseUrl}/${ApiConstants.SESSION_ID_REQUEST}`, data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getTransactionDetails = async (id) => {
    try{
        const response = await axios.get(`${baseUrl}/${ApiConstants.PAYMENT_GATEWAY_TRANSACTION_ID}/${id}`)
        return response;
    } catch (error) {
        throw error;
    }
}

export const storeTransactionDetails = async (data) => {
    try{
        const response = await axios.post(`${baseUrl}/${ApiConstants.STORE_CUSTOMER_TRANSACTION}`, data)
        return response;
    } catch (error) {
        throw error;
    }
}

export const tokenVerification =async (token) => {
    try{
        const response = await axios.get(`${baseUrl}/${ApiConstants.TOKEN_VERIFICATION}/${token}`)
        return response;
    } catch (error) {
        throw error;
    }
}