import React from "react";
import { Link, Outlet } from "react-router-dom";
import LoginTopUpBackground from "./LoginTopUpBackground";

function AuthLayout() {
  return (
    <>
      <div className="main-page">
        <nav className="layout-navbar shadow-none py-0">
          <div className="container">
            <div className="navbar navbar-expand-lg landing-navbar px-3 px-md-4 mt-0">
              {/* Menu logo wrapper: Start */}
              <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4">
                <Link to="#" className="app-brand-link">
                  <img src="../assets/img/logo.png" alt="" width="130px" />
                </Link>
              </div>
              {/* Menu logo wrapper: End */}
              {/* Menu wrapper: Start */}
              <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
                <button className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="ti ti-x ti-sm" />
                </button>
              </div>
              <div className="landing-menu-overlay d-lg-none" />
            </div>
          </div>
        </nav>
        <div data-bs-spy="scroll" className="scrollspy-example">
          {/* Hero: Start */}
          <section id="hero-animation">
            <div id="landingHero" className="section-py landing-hero position-relative">
              <div className="container">
                <Outlet />
                <LoginTopUpBackground />
              </div>
            </div>
          </section>
          {/* Hero: End */}
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
