import React from "react";

const ProductSelectionBackground = () => {
  return (
   
  <div className="container-fluid">
  <div className="pic_2 d-none d-lg-flex">
    <img src="/assets/img/girl-3.png" alt="Shaad" />
  </div>
  <div className="pic_4 d-none d-lg-flex">
    <img src="/assets/img/girl-2.png" alt="Shaad" />
  </div>
</div>
  );
};

export default ProductSelectionBackground;
