import React from "react";
import Footer from "./Footer";
import TopUpForm from "./TopUpForm";
import About from "./About";

const LandingPage = () => {

  return (
    <>
      <TopUpForm />
      <About />
      <Footer />
    </>
  );
};

export default LandingPage;
