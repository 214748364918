import React from "react";
import ProductSelectionBackground from "./ProductSelectionBackground";
import ProductListing from "./ProductListing";

const Products = () => {
  return (
    <>
      <ProductListing />
      <ProductSelectionBackground />
    </>
  );
};

export default Products;
