import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RecipientDetails from "./RecipientDetails";
import { useDispatch, useSelector } from "react-redux";
import { getTopUpDetailApi } from "../../../redux/features/topUpSlice";
import moment from "moment";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";
import { formatCreditBalance, formatCreditBalanceUptoFourDecimal } from "../../../utils/sharedFunctions";

const TransactionTable = ({ acc_no }) => {
  const [showModalBox, setShowModalBox] = useState(false);
  const [indexForModal, setIndex] = useState(0);
  const roleId = parseInt(localStorageStore.getRole());
  const {RETAILER_ID} = userRoleConstants
  let page = 1;
  let size = 10;

  const dispatch = useDispatch();

  const handleModalDisplay = (index) => {
    setIndex(index);
    setShowModalBox(!showModalBox);
  };

  useEffect(() => {
    dispatch(getTopUpDetailApi({ filteredData: {account_no : acc_no}, page, size }));
    // eslint-disable-next-line
  }, [dispatch]);

  let topUpTransaction = useSelector((state) => state?.topUpSlice?.topUpDetails);
  const switchStatusBadgeClasses = (status) => {
    switch (status) {
      case 'Successful':
        return 'bg-label-success'
      case 'In Process':
        return 'bg-label-warning'
      case 'Failed':
        return 'bg-label-danger'
      default:
        return "bg-label-warning";
    }
  };
  return (
    <div className="col-md-9">
      <div className="card">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Recent Transactions</h5>
          <div>
            <Link to={roleId === RETAILER_ID ? "/order" : `/retailer/${acc_no}/transaction`} style={{ color: "#0282C3" }}>
              View All <i className="ti ti-chevron-right" />
            </Link>
          </div>
        </div>
        <div className="card-datatable table-responsive">
          <table className=" table" id="orders">
            <thead>
              <tr className="align-baseline">
                <th>Order ID</th>
                <th>Date &amp; Time</th>
                <th>Top-UP Amount</th>
                <th> Commission‌ <small>(%)</small> </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {topUpTransaction?.length !== 0  ? 
                (topUpTransaction?.map((transaction_details, index) => (
                  <tr key={index}>
                    <td> <span style={{ color: "#0282C3",cursor: "pointer" }} onClick={() => { handleModalDisplay(index); }} > {transaction_details?.order_id} </span> </td>
                    <td> {moment.utc(transaction_details?.credited_at).format('DD MMM YY | hh:mm A')}</td>
                    <td> <p className="mb-0"> {formatCreditBalance(transaction_details?.total_amount_in_converted_currency)} <small>{transaction_details?.converted_currency_type}</small> </p> </td>
                    <td> {formatCreditBalanceUptoFourDecimal(transaction_details?.commission)} </td>
                    <td><span className={`badge px-2 ${switchStatusBadgeClasses(transaction_details?.status)}`}>{transaction_details?.status}</span></td>
                  </tr>
                ))) : (<tr className="pt-3 ps-3"><td colSpan="5">No record found</td></tr>)
              }
            </tbody>
          </table>
        </div>
      </div>
      {showModalBox && <RecipientDetails showModalBox={showModalBox} setShowModalBox={setShowModalBox} topUpTransaction={topUpTransaction[indexForModal]} />}
    </div>
  );
};

export default TransactionTable;
