import React from "react";

const LoginTopUpBackground = () => {
  return (
    <div className="container-fluid">
      <div className="pic_2 d-none d-lg-flex">
        <img src="../../assets/img/girl-2.png" alt="Shaad" />
      </div>
      <div className="pic_3 d-none d-lg-flex">
        <img src="../../assets/img/boy.png" alt="Shaad" />
      </div>
    </div>
  );
};

export default LoginTopUpBackground;
