import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const getAggregatorStatus = async () => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.AGGREGATOR_STATUS}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRetailerDistributorInformation = async (id, type="distributor") => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.LOG_TRANSACTION_RETAILER_DISTRIBUTOR_INFO}/${id}/${type}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUsers = async (inputValue) => {
  try {
    const response = await axios.get(`${baseUrl}/admin/search?query=${inputValue}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
