import React from 'react'
import RetailerDetails from '../../Shared/UserProfile/Index'
import CreditStatus from '../../Shared/StatusCard/LargeStatusCard'

const ManageProfileHeader = ({ userDetails, setDateValue }) => {

  return (
    <>
      <div className="row">
        <RetailerDetails userDetails={userDetails?.record} />
        <div className="col-lg-8 mb-4 col-md-12">
          <CreditStatus userDetails={userDetails?.record} totalTopUpAmount={userDetails?.count} setDateValue={setDateValue} />
        </div>
      </div>
    </>
  )
}

export default ManageProfileHeader