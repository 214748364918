import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { sendQuery } from "../../../services/helpAndSupportService";
import CustomAlert from "../CustomAlert";

const SendQuery = ({ showQueryBox, categoriesData, setShowQueryBox, transactionId}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [isFollowUpRequested, setIsFollowUpRequested] = useState(false);
  const [files, setFiles] = useState([]);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (content) => {
    const regex = /<p>(.*?)<\/p>/;
    const match = content?.match(regex);
    const extractedValue = match ? match[1] : null;
    setDescription(extractedValue === "<br>" ? "" : extractedValue);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    // Check if the selectedFiles array length exceeds 5
    if (selectedFiles.length + files.length > 5) {
      // Notify the user about the maximum number of files allowed
      errorToast("Maximum 5 files are allowed");
      return;
    }

    // Loop through each selected file
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // Check if file type is allowed
      if (!allowedTypes.includes(file.type)) {
        errorToast(`File type not allowed for ${file.name}`);
        continue;
      }
      // Check if file size is within the allowed limit
      if (file.size > maxSize) {
        errorToast(`File size exceeds 2 MB limit for ${file.name}`);
        continue;
      }

      setFiles((prevFiles) => [...prevFiles, file]);

      event.target.value = '';
    }
  };

  const deleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  async function getFilesBase64(files) {
    const base64Array = [];
    for (const file of files) {
      const base64String = await getFileBase64(file);
      base64Array.push({ fileName: file.name, fileType: file?.name?.split(".")?.pop(), base64: `data:${file?.type};base64,${base64String}` });
    }
    return base64Array;
  }

  async function getFileBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  useEffect(() => {
    const changeToBase64 = async () => {
      try {
        const base64Files = await getFilesBase64(files);
        setAttachment(base64Files);
      } catch (error) {}
    };

    changeToBase64();
    
    // eslint-disable-next-line
  }, [files]);

  const handleSendQuery = async () => {
    const button = document.getElementById("submitButton");
    try {
      if (categoryId !== "" && title !== "" && description !== "") {
        const requestData = {
          category_id: categoryId,
          title: title,
          description: description,
          attachments: attachment,
          ...(transactionId !== null && { category_value: transactionId }),
        };
        button.disabled = true;
        const sendQueryResponse = await sendQuery(requestData);

        if (sendQueryResponse?.success) {
          successToast("Query raised successfully");
          setIsFollowUpRequested(true);
          button.disabled = false;
        } else {
          errorToast("Failed to raise query");
          setShowQueryBox(!showQueryBox);
          button.disabled = false;
        }
      } else {
        errorToast("Please fill the required fields");
      }
    } catch (error) {
      setShowQueryBox(!showQueryBox);
      errorToast("Failed to submit the query");
      button.disabled = false;
    }
  };

  return (
    <>
      {!isFollowUpRequested ? (
        <Modal show={showQueryBox} size="lg" className="app-email-compose">
          <div className="modal-content p-0">
            <div className="modal-header py-3 bg-body d-flex justify-content-between">
              <h5 className="modal-title fs-5">Add Query</h5>
              <div className="choose-category-query">
                <label htmlFor="">
                  <sup className="text-danger">*</sup>
                </label>
                <select
                  className="form-select form-select-sm w-px-150"
                  onChange={(e) => {
                    setCategoryId(e?.target?.value);
                  }}
                >
                  <option value="">Category</option>
                  {categoriesData?.categories?.map((option) => (
                    <option key={option?.id} value={option?.id}>
                      {option?.name}
                    </option>
                  ))}
                </select>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShowQueryBox(!showQueryBox);
                }}
              />
            </div>
            <div className="modal-body flex-grow-1 pb-sm-0 p-4 py-2">
              <form className="email-compose-form">
                <div className="email-compose-subject d-flex align-items-center mb-2">
                  <label htmlFor="email-subject" className="form-label mb-0">
                    Query:
                  </label>
                  <input type="text" onChange={handleTitle} className="form-control border-0 shadow-none flex-grow-1 mx-2" id="email-subject" placeholder="Query Title" />
                </div>
                <div className="email-compose-content container-m-nx mb-2">
                  <ReactQuill onChange={handleDescriptionChange} modules={{ toolbar: false }} placeholder="Write your message here..." />
                </div>
                <div className="file-input-container">
                <input type="file" multiple onChange={handleFileChange} className="d-none" id="file-upload" />
                <label htmlFor="file-upload" className="attach-file">
                    <i className="ti ti-paperclip cursor-pointer ms-2" />
                  </label>
                  <span>{files.length} file(s) selected</span>
                </div>
                <div className="selected-files-container" style={{paddingLeft:'1.5%'}}>
                  {files.map((file, index) => (
                    <div key={index} className="file-item">
                      <span>{file.name}</span>
                      <button type="button" className="btn delete-file" onClick={() => deleteFile(index)}>
                        <i className="ti ti-trash" />
                      </button>
                    </div>
                  ))}
                </div>
                <hr className="container-m-nx mt-2 mb-2" />
                <div className="email-compose-actions d-flex justify-content-between align-items-center mt-3 mb-3">
                  <div className="d-flex align-items-center">
                    <div className="btn-group">
                      <button type="button" className="btn btn-sm rounded-pill btn-primary" onClick={handleSendQuery} id="submitButton">
                        <i className="ti ti-send ti-xs me-1" />
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      ) : (
        <CustomAlert showCustomeAlertModal={showQueryBox} setShowCustomeAlertModal={setShowQueryBox} text="Your request has been sent to Admin successfully." showClassName="text-success check info" iconClassName="ti ti-check fs-1" />
      )}
    </>
  );
};

export default SendQuery;
