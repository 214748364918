import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transactionFilters } from "../../services/transactionService";

const initialState = {
  transactionDetails: [],
  count: [],
  filteredChips: [],
  isLoading: false,
  error: null,
};

export const getTransactionDetailApi = createAsyncThunk(
  "transaction/getTransactionDetailApi",
  async (filteredData) => {
    try {
      const response = await transactionFilters(filteredData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setInitTransactionDetail: (state) => {
      state.transactionDetails = [];
    },
    setFilteredChips: (state, action) => {
      state.filteredChips = action.payload;
    },
    clearFilteredChips: (state) => {
      state.filteredChips = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactionDetailApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getTransactionDetailApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionDetails = action.payload.records;
      state.count = action.payload.count;
    });

    builder.addCase(getTransactionDetailApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = transactionSlice;
export const { setInitTransactionDetail, setFilteredChips, clearFilteredChips, } = transactionSlice.actions;
export default reducer;
