import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const adminCustomerFilter = async (filterData, page, size) => {
  try {
    let url = `${baseUrl}/${ApiConstants.CUSTOMERS}?page=${page}&size=${size}`;
    const queryParams = new URLSearchParams(filterData).toString();

    if (queryParams) {
      url += `&${queryParams}`;
    }

    const response = await axios.get(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const editCustomer = async (id,data) => {
  try {
    const response = await axios.put(`${baseUrl}/${ApiConstants.CUSTOMERS}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const customerProfile = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMERS}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const customerHistory = async ({id, page, currentSize}) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMERS}/${id}/history?page=${page}&size=${currentSize}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const customerContactList = async ({id, page, currentSize}) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.CUSTOMERS}/${id}/contacts?page=${page}&size=${currentSize}`);
    return response;
  } catch (error) {
    throw error;
  }
};

