import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateConfiguration } from "../../../services/configurationService";
import { errorToast, successToast } from "../../Toasts/Toasts";

const EditModal = ({ showModalBox, setShowModalBox, value, handleReRenderData }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({ name: value?.name ?? "", value: value?.value ?? "" });
  }, [reset, value]);

  const handleClose = () => {
    setShowModalBox(!showModalBox);
  };

  const onSubmit = async (data) => {
    const button = document.getElementById("saveButton");
    button.disabled = true;
    let configurationInfo = {
      name: data?.name,
      value: data?.value,
    };
    try {
      const response = await updateConfiguration(value?.id, configurationInfo);
      button.disabled = false;
      if (response?.success) {
        successToast(response?.message);
        handleReRenderData(response);
        setShowModalBox(false);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setShowModalBox(false);
    }
  };

  return (
    <>
      <Modal show={showModalBox} size="md" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                <button type="button" className="btn-close" onClick={handleClose} />
                <div className="text-left mb-4">
                  <h3 className="mb-2">Edit configuration</h3>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Config Name</label>
                    <input type="text" placeholder="" className="form-control" name="name" {...register("name", { required: "required" })} disabled />
                    <small className="text-danger">{errors?.name && errors.name.message}</small>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Config Value</label>
                    <input type="text" placeholder="" className="form-control" name="value" {...register("value", { required: "required" })} />
                    <small className="text-danger">{errors?.value && errors.value.message}</small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" id="saveButton">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditModal;
