const userRoleConstants = {
  ADMIN_ID: 1,
  DISTRIBUTOR_ID: 2,
  RETAILER_ID: 3,
  CUSTOMER_ID: 4,
  DISTRIBUTOR_USER_ID: 5,
  MANAGER_ID: 6,
  SUPERVISOR_ID: 7,
  SALES_REPRESENTATIVE_ID: 8,
  ACCOUNTANT_ID: 9,
  PROCUREMENT_ID: 10,
};

const dateFilterConstants = {
  TODAY: 1,
  THIS_MONTH: 2,
  TILL_TODAY: 3,
  THIS_WEEK: 4,
};

const billingType = {
  CREDIT : 1,
  WALLET : 2 ,
};

const creditStatusType = {
  APPROVED: 1,
  DECLINED: 2,
  REQUESTED: 3,
  PAID: 4,
  UNPAID: 5,
};

const helpAndSupportTag = {
  OPEN: 1,
  PENDING: 2,
  SOLVED: 3,
  RE_OPEN: 4,
  CLOSED: 5,
};

const topUpChannel = {
  B2B: "B2B",
  B2C: "B2C",
};

const paymentMethod = {
  Retailer:0,
  Stripe: 1,
  Paypal: 2,
};

const carrierNames = {
  "750": "Wasel",
  "78": "Etisalat",
  "73": "Etisalat",
  "79": "Roshan",
  "72": "Roshan",
  "74": "Salaam",
  "77": "MTN",
  "76": "MTN",
  "71": "AWCC",
  "70": "AWCC",
  "75": "Cdma"
};


module.exports = {
  userRoleConstants,
  dateFilterConstants,
  billingType,
  creditStatusType,
  helpAndSupportTag,
  topUpChannel,
  paymentMethod,
  carrierNames
};
