import React from "react";
import { Link } from "react-router-dom";
import FilterModal from "./FilterModal";
import StatusCard from "../../Shared/StatusCard/SmallStatusCard";
import { userRoleConstants } from "../../../utils/constants";
import decrypt from "../../../utils/decrypt";
import { formatCreditBalance, formatCreditBalanceUptoFourDecimal } from "../../../utils/sharedFunctions";

const Headers = ({setClearFilterValues, headerDetails, acc_no, currentSize, clearFilterValues}) => {
 
  const encryptedRoleId = localStorage.getItem("roleId")
  const roleId = parseInt(decrypt(encryptedRoleId))
  const {DISTRIBUTOR_ID, DISTRIBUTOR_USER_ID} = userRoleConstants

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        {(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? <h4 className = "mb-1 pt-1"> <span className="text-muted fw-light"> <Link to={`/retailer/${acc_no}/profile`}>Retailer Profile</Link> / </span> Retailer Transaction </h4> : <h4 className="pt-1 mb-1"> Manage Orders</h4>}
        <FilterModal setClearFilterValues={setClearFilterValues} acc_no={acc_no} currentSize={currentSize} clearFilterValues={clearFilterValues} roleId={roleId} DISTRIBUTOR_ID={DISTRIBUTOR_ID} />
      </div>
      <div className="row">
        <div className="col-lg-3 col-12 mb-4"><StatusCard icon={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "ti ti-world ti-sm" : "ti ti-list ti-sm"} color={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "info" : "badge rounded-pill bg-label-primary me-3 p-2"} value={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? `${headerDetails?.total_countries ?? 0}` : `${formatCreditBalance(headerDetails?.total_topups ?? 0)}`} label={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "Total Countries" : "Total Orders"} /></div>
        <div className="col-lg-3 col-12 mb-4"><StatusCard icon={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "ti ti-wallet ti-sm" : "ti ti-cash ti-sm"} color={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "primary" : "info"} value={`${formatCreditBalanceUptoFourDecimal(headerDetails?.total_topup_amount ?? 0)}`} label={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "Total Top-UPs" : "Total Orders Amount"} currency_format={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "USD / " : "USD"} top_up_count={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? `Count: ${formatCreditBalance(headerDetails?.total_topups ?? 0)}` : ""}/></div>
        <div className="col-lg-3 col-12 mb-4"><StatusCard icon={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "ti ti-percentage ti-sm" : "ti ti-percentage ti-sm"} color={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "warning" : "badge rounded-pill bg-label-warning me-3 p-2"} value={`${formatCreditBalanceUptoFourDecimal(headerDetails?.total_commission_earned ?? 0)}`}  label={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "Total Commission Earned" : "Total Orders Commission"} currency_format={(roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === userRoleConstants.SALES_REPRESENTATIVE_ID) ? "USD" : "USD"}/></div>
        <div className="col-lg-3 col-12 mb-4"><StatusCard icon={"ti ti-access-point ti-sm"} color={"primary"} value={`${formatCreditBalance(headerDetails?.total_carrier ?? 0)}`} label={"Total Carriers"}/></div>       
      </div>
    </>
  );
};

export default Headers;