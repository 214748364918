import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCommissionHistory } from "../../services/productService";

const initialState = {
  commissionHistoryDetails: [],
  isLoading: false,
  error: null,
};

export const commissionHistoryApi = createAsyncThunk("commissionHistory/getCommissionHistoryApi", async (filterData) => {
  try {
    const response = await getCommissionHistory(filterData);
    return response
  } catch (error) {
    console.error("Error fetching transaction details:", error);
    throw error;
  }
});

const commissionHistorySlice = createSlice({
  name: "commissionHistory",
  initialState,
  reducers: {
    setInitCommissionHistoryDetail: (state) => {
      state.commissionHistoryDetails = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(commissionHistoryApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(commissionHistoryApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.commissionHistoryDetails = [...action.payload];
    });

    builder.addCase(commissionHistoryApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = commissionHistorySlice;
export const { setInitCommissionHistoryDetail } = commissionHistorySlice.actions;
export default reducer;
