import React from "react";
import { Modal } from "react-bootstrap";

const CustomAlert = ({ showCustomeAlertModal, setShowCustomeAlertModal, text, showClassName, iconClassName }) => {
  
  return (
    <>
      <Modal show={showCustomeAlertModal} size="sm" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body className="dashboard-cards rounded text-center">
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowCustomeAlertModal(false)} />
              <div className={showClassName} >
                <p>
                  <i className={iconClassName}></i>
                </p>
              </div>
              <p className="para">{text}</p>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomAlert;
