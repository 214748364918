import React, { useEffect, useState } from "react";
import ManageDashboard from "../Distributor/ManageDashboard/Index";
import AdminDashboard from "../Admin/AdminDashboard/Index";
import localStorageStore from "../../utils/localStorageStore";
import { userRoleConstants } from "../../utils/constants";
import { fetchAdminDashboard } from "../../services/configurationService";
import { errorToast } from "../Toasts/Toasts";

const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; 
};

const Dashboard = () => {
  const RoleId = parseInt(localStorageStore.getRole());
  const [dashboardRecord, setDashboardRecord] = useState({});
  const [dateValue, setDateValue] = useState(`${getTodayDate()} - ${getTodayDate()}`);

  useEffect(() => {
    fetchRecords();
    // eslint-disable-next-line
  }, [dateValue]);

  const fetchRecords = async () => {
    try {
      const response = await fetchAdminDashboard(dateValue);
      if (response?.success) {
        setDashboardRecord(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };
  return (
    <>
      {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (<ManageDashboard dashboardRecord={dashboardRecord} setDateValue={setDateValue} />)}
      {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.PROCUREMENT_ID) && <AdminDashboard dashboardRecord={dashboardRecord} setDateValue={setDateValue} />}
    </>
  );
};

export default Dashboard;
