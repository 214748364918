import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productFilter } from "../../services/productService";

const initialState = {
  productDetails: [],
  isLoading: false,
  error: null,
  filteredChips: [],
  productFilteredChips : [],
  count: {},
  serviceFee: 0, vat: 0
};

export const postProductFilterApi = createAsyncThunk(
  "product/postProductFilterApi",
  async (filteredData) => {
    try {
      const response = await productFilter(filteredData?.filteredData,filteredData?.page,filteredData?.size);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setInitProductDetail: (state) => {
      state.productDetails = [];
    },
    setFilteredChips: (state, action) => {
      state.filteredChips = action.payload;
    },
    clearFilteredChips: (state) => {
      state.filteredChips = [];
    },
    setProductFilteredChips : (state, action) => {
      state.productFilteredChips = action.payload;
    },
    clearProductFilteredChips: (state) => {
      state.productFilteredChips = [];
    },
    resetProductState: (state) => {
      Object.assign(state, initialState);
    },   
  },
  extraReducers: (builder) => {
    builder.addCase(postProductFilterApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(postProductFilterApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.productDetails = action.payload.records;
      state.count = action.payload.count;
      state.serviceFee = action.payload.serviceFee;
      state.vat = action.payload.vat;

    });

    builder.addCase(postProductFilterApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = productSlice;
export const { setInitProductDetail, setFilteredChips, clearFilteredChips, resetProductState, setProductFilteredChips, clearProductFilteredChips } = productSlice.actions;
export default reducer;