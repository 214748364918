import { Routes, Route, Navigate } from "react-router";
import Layout from "./components/Layout/Index";
import Login from "./components/Auth/Login/Index";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import RetailerTransaction from "./components/Retailer/RetailerTransaction/Index";
import AuthLayout from "./components/Layout/AuthLayout/Index";
import ForgotPassword from "./components/Auth/ForgotPassword/Index";
import VerifyOtp from "./components/Auth/VerifyOtp/Index";
import SetPasswordForm from "./components/Auth/SetPasswordForm/Index";
import "./App.css";
import ManageRetailer from "./components/Shared/ManageUser/Index";
import RetailerProfile from "./components/Retailer/RetailerProfile/Index";
import ManageProduct from "./components/Shared/ManageProduct";
import ManageDistributorUser from "./components/Distributor/ManageDistributorUser/Index";
import ManageTopUp from "./components/Retailer/ManageTopUp/Index";
import CustomerTopUp from "./components/Customer/TopUp/Index";
import CustomerLayout from "./components/Layout/CustomerLayout/Index";
import CustomAmountTopUp from "./components/Customer/CustomAmountTopUp";
import LandingPage from "./components/Customer/LandingPage/Index";
import Profile from "./components/Customer/Home/Index";
import Products from "./components/Customer/Products/Index";
import RetailerProducts from "./components/Retailer/RetailerProducts/Index";
import ManageCustomers from "./components/Admin/ManageCustomers/Index";
import ManageBilling from "./components/Shared/ManageBilling/Index";
import Configuration from "./components/Admin/Configuration/Index";
import CustomerProfile from "./components/Admin/CustomerProfile";
import SetPassword from "./components/Auth/SetPasswordForm/SetPassword";
import Dashboard from "./components/Shared/Dashboard";
import Register from "./components/Auth/Register/Index";
import RequestOtp from "./components/Auth/Register/RequestOtp";
import RequestOtpMobileNumber from "./components/Auth/Register/RequestOtpMobileNumber";
import VerificationPage from "./components/Customer/VerificationPage/Index";
// import PaymentGatewayIfAuthanticated from "./components/Customer/Home/PaymentGatewayIfAuthanticated";
import CheckoutAfterNotification from "./components/Customer/TopUp/CheckoutAfterNotification";
import About from "./components/Customer/LandingPage/About";
import HelpAndSupport from "./components/Shared/HelpAndSupport";
import CustomerRechargeRequest from "./components/Customer/CustomerRechargeRequest";
import LogTransaction from "./components/Admin/LogTransaction";
import UserVerifyMfa from "./components/Auth/UserVerifyMfa";
import RechargeAnyNumber from "./components/Customer/TopUp/RechargeAnyNumber";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <>
      <Routes>
        <Route element={<CustomerLayout />}>
          <Route exact path="/verify-recharge-request/:token" element={<VerificationPage/>}/>
          <Route exact path="/customer" element={<LandingPage />}/> 
          <Route exact path="/customer/profile" element={<Profile />}/>
          <Route exact path="/customer/topup" element={<CustomAmountTopUp />}/> 
          <Route exact path="/customer/topup/products" element={<Products />}/>      
          <Route exact path="/customer/about" element={<About />}/> 
          <Route exact path="/customer/recharge" element={<CheckoutAfterNotification />}/>
          <Route exact path="/customer/recharge-request" element={<CustomerRechargeRequest />}/>
          <Route exact path="/customer/recharge-any-number/:phone_number" element={<RechargeAnyNumber />}/>
        </Route>
        <Route exact path="/customer/send-topup/:phone_number" element={<CustomerTopUp />}/>
        <Route element={<AuthLayout />}>
          <Route exact path="/" element={<Login />}/>
          {/* <Route exact path="/customer/login" element={<Login />}/> */}
          <Route exact path="/forgot-password" element={<ForgotPassword />}/>
          <Route exact path="/customer/forgot-password" element={<ForgotPassword />}/>
          <Route exact path="/verify-otp" element={<VerifyOtp />}/>
          <Route exact path="/customer/verify-otp" element={<VerifyOtp />}/>
          <Route exact path="/customer/account-security" element={<SetPasswordForm />}/>
          <Route exact path="/customer/register" element={<Register />}/>
          <Route exact path="/customer/register/request-otp" element={<RequestOtp />}/>
          <Route exact path="/customer/register/request-otp-phone-number" element={<RequestOtpMobileNumber />}/>
          <Route exact path="/customer/register/verify-otp" element={<VerifyOtp />}/>
          <Route exact path="/customer/register/phone-number-verify-otp" element={<VerifyOtp />}/>
          <Route exact path="/customer/register/account-security" element={<SetPasswordForm />}/> 
          <Route exact path="/user/mfa" element={<UserVerifyMfa />}/> 
          {/* <Route exact path="/customer/register/verify-otp" element={<Register />}/>
          <Route exact path="/customer/register/set-password" element={<Register />}/> */}
          <Route path="account-security" >
            <Route index={true}  element={<SetPasswordForm />} />
            <Route path=":token" element={<SetPassword />} />
          </Route>
        </Route>
        <Route element={<Layout/>}>
          <Route exact path= "/retailers" element={isAuthenticated ? <ManageRetailer /> : <Navigate to="/" />}/>
          <Route exact path= "/distributors" element={isAuthenticated ? <ManageRetailer /> : <Navigate to="/" />}/>
          <Route exact path= "/retailer/:acc_no/products" element={isAuthenticated ? <RetailerProducts /> : <Navigate to="/" />}/>
          <Route exact path= "/retailer/:acc_no/profile" element={isAuthenticated ? <RetailerProfile /> : <Navigate to="/" />}/>
          <Route exact path= "/distributor/:acc_no/profile" element={isAuthenticated ? <RetailerProfile /> : <Navigate to="/" />}/>
          <Route exact path= "/order" element={isAuthenticated ? <RetailerTransaction /> : <Navigate to="/" />}/>
          <Route exact path= "/retailer/:acc_no/transaction" element={isAuthenticated ? <RetailerTransaction /> : <Navigate to="/" />}/>
          <Route exact path= "/b2c-topup" element={isAuthenticated ? <ManageTopUp /> : <Navigate to="/" />}/>
          <Route exact path= "/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}/>
          <Route exact path= "/products" element={isAuthenticated ? <ManageProduct /> : <Navigate to="/" />}/>
          <Route exact path= "/distributor/users" element={isAuthenticated ? <ManageDistributorUser /> : <Navigate to="/" />}/>
          <Route exact path="/billing" element={isAuthenticated ? <ManageBilling /> : <Navigate to="/" />}/>
          <Route exact path= "/distributor/:acc_no/retailers" element={isAuthenticated ? <ManageRetailer /> : <Navigate to="/" />}/>
          <Route exact path="/customers" element={isAuthenticated ? <ManageCustomers /> : <Navigate to="/" />}/>
          {/* <Route exact path="/carriers" element={isAuthenticated ? <ManageCarrier /> : <Navigate to="/" />}/>
          <Route exact path= "/carrier/:id" element={isAuthenticated ? <CarrierDetails /> : <Navigate to="/" />}/> */}
          <Route exact path= "/customer/:id" element={isAuthenticated ? <CustomerProfile /> : <Navigate to="/" />}/>
          <Route exact path="/configurations" element={isAuthenticated ? <Configuration /> : <Navigate to="/" />}/>
          <Route exact path= "/support-help" element={isAuthenticated ? <HelpAndSupport /> : <Navigate to="/" />}/>
          <Route exact path= "/log" element={isAuthenticated ? <LogTransaction /> : <Navigate to="/" />}/>
          
        </Route>
      </Routes>

      <ToastContainer />
    </>
  );
}

export default App;
