import React from "react";
import { Modal } from "react-bootstrap";

const ViewRetailerDistributorModal = ({ showDetails, setShowDetails, retailerDetails, distributorDetails }) => {
  const handleClose = () => setShowDetails(false);
  return (
    <Modal show={showDetails} size="lg" centered>
      <div className="modal-simple">
        <div className="modal-content p-3 p-md-3">
          <Modal.Body>
            <button type="button" className="btn-close" onClick={handleClose} />
            <div className="text-left mb-4">
              <h3 className="mb-2">Retailer and Distributor Details</h3>
            </div>

            <div className="row">
              {/* Retailer Details */}

              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Retailer Name</h6>
                <small className="text-muted">{retailerDetails?.name}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Retailer Email</h6>
                <small className="text-muted">{retailerDetails?.email}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Retailer Phone Number</h6>
                <small className="text-muted">{retailerDetails?.phone_code}{retailerDetails?.phone_number}</small>
              </div>

              {/* Distributor Details */}

              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Distributor Name</h6>
                <small className="text-muted">{distributorDetails?.name}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Distributor Email</h6>
                <small className="text-muted">{distributorDetails?.email}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Distributor Phone Number</h6>
                <small className="text-muted">{distributorDetails?.phone_code}{distributorDetails?.phone_number}</small>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ViewRetailerDistributorModal;
