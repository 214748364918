import React from "react";
import { Modal } from "react-bootstrap";

const ManageUserDetailsModal = ({ userDetailsShow, setUserDetailsShow, userDetailsForEditModal }) => {
  const handleClose = () => {
    setUserDetailsShow(!userDetailsShow);
  };

  return (
    <>
      <Modal show={userDetailsShow} centered size="lg">
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <div className="modal-body">
              <button type="button" className="btn-close" onClick={handleClose} />
              <div className="text-left mb-4">
                <h3 className="mb-2">Details</h3>
              </div>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">Users</h6>
                  <small className="text-muted">{userDetailsForEditModal?.name}</small>
                </div>
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">Phone Number</h6>
                  <small className="text-muted">{`${userDetailsForEditModal?.phone_code} ${userDetailsForEditModal?.phone_number}`}</small>
                </div>
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">Email</h6>
                  <small className="text-muted">{userDetailsForEditModal?.email}</small>
                </div>
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">Address</h6>
                  <small className="text-muted">{userDetailsForEditModal?.address}</small>
                </div>
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">Country</h6>
                  <small className="text-muted">{userDetailsForEditModal?.country_name}</small>
                </div>
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">State</h6>
                  <small className="text-muted">{userDetailsForEditModal?.state_name}</small>
                </div>
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">City</h6>
                  <small className="text-muted">{userDetailsForEditModal?.city}</small>
                </div>
                <div className="col-lg-4 mb-3">
                  <h6 className="mb-0">Zip</h6>
                  <small className="text-muted">{userDetailsForEditModal?.zip}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManageUserDetailsModal;
