import React, { useState } from "react";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { useDispatch } from "react-redux";
import { postAddTransactionDetailApi } from "../../../redux/features/addTransactionSlice";

const AddCredit = ({ userDetails, setReRender, reRender, acc_no }) => {
  const [creditAmount, setCreditAmount] = useState("");
  const dispatch = useDispatch();

  const handleAddCredit = (e) => {
    setCreditAmount(e?.target?.value);
  };

  const submitCredit = async () => {
    const submitData = {
      amount: creditAmount,
      account_no: acc_no,
    };

    const response = await dispatch(postAddTransactionDetailApi(submitData));

    if (response?.payload?.success) {
      successToast(response?.payload?.message);
      setReRender(!reRender);
    } else {
      errorToast(response?.payload?.message);
    }

    setCreditAmount("");
  };

  return (
    <div className="card mt-3 mt-lg-0">
      <div className="p-4 ">
        <div className="col-md-12">
          <label className="form-label fs-5">
            {" "}
            Add credit <span className="small">(USD)</span>{" "}
          </label>
          <div className="d-flex justify-content-between">
            <input type="number" value={creditAmount} onChange={handleAddCredit} min={0} placeholder="" className="form-control" />
            <button type="button" onClick={submitCredit} className="btn btn-sm btn-primary waves-effect waves-light ms-2" >
              Go
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCredit;