import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { userSetPassword } from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import decryptToken from "../../../utils/decryptToken";
 
function SetPassword() {
 
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const {token} = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
 
  const watchPassword = watch("newPassword", "");
 
  const registerOptions = {
    newPassword: {
      required: "Required",
      pattern: {
        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
        message: "Password must be between 8 and 20 characters and contain at least one letter, one digit, and one special character.",
      },
    },
    confirm_password: {
      required: "Required",
      validate: (value) => value === watchPassword || "Passwords do not match",
    },
  };
 
  const onSubmit = async (data) => {
    try { 
      const button = document.getElementById("btnSave");
      button.disabled = true;
 
      const decryptedText = decryptToken(token);
      const [tokenPart, emailPart] = decryptedText?.split("#$#");
 
      const tokenWithoutText = tokenPart?.split("token");
      let obj = {
        userEmailOrPhone: emailPart || "",
        resetToken: tokenWithoutText[1] || "",
        newPassword: data?.newPassword,
        loginType: "user"
      };
      const response = await userSetPassword(obj);
 
      button.disabled = false;
 
      if (response?.success) {
        successToast(response?.message);
        navigate("/");
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.message);
    }
  };
 
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
              <div className="card card-topup bg-outline-primary mt-4">
                <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                  <div className="w-px-400 mx-auto">
                    <div className="card-body">
                      <h3 className="mb-5 text-center">Set Password</h3>
                      <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group input-group-merge rounded-pill">
                            <input id="newPassword" className="form-control" type={showPassword ? "text" : "password"} name="newPassword" placeholder="........." minLength={8} maxLength={20} {...register("newPassword", registerOptions.newPassword)} />
                            <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                              <i className={`ti ti-eye${showPassword ? "" : "-off"}`} />
                            </span>
                          </div>
                          <small className="text-danger">{errors?.newPassword && errors.newPassword.message}</small>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-group input-group-merge rounded-pill">
                            <input className="form-control" type={showConfirmPassword ? "text" : "password"} name="confirm_password" placeholder=".........." minLength={8} maxLength={20} {...register("confirm_password", registerOptions.confirm_password)} />
                            <span className="input-group-text cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                              <i className={`ti ti-eye${showConfirmPassword ? "" : "-off"}`} />
                            </span>
                          </div>
                          <small className="text-danger">{errors?.confirm_password && errors.confirm_password.message}</small>
                        </div>
                        <button type="submit" className="btn btn-primary w-100 text-white d-grid rounded-pill signup" id="btnSave">
                          Save
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default SetPassword;