import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const getNotifications = async () => {
    try {
      const response = await axios.get(`${baseUrl}/${ApiConstants.NOTIFICATION}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const updateNotifications = async (notification_id) => {
  try{
      const response = await axios.put(`${baseUrl}/${ApiConstants.NOTIFICATION}`, {id: notification_id})
      return response;
  } catch(error) {
    throw error;
  }
}

export const updateAllNotifications = async (receiver_id) => {
  try{
      const response = await axios.put(`${baseUrl}/${ApiConstants.MARK_ALL_READ}`, {receiver_id: receiver_id})
      return response;
  } catch(error) {
    throw error;
  }
}