import React from "react";
import ConfigurationGrid from "./ConfigurationGrid";

const Configuration = () => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-1 pt-1"> Configurations</h4>
      </div>
      <ConfigurationGrid />
    </>
  );
};

export default Configuration;
