import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditModal from "./EditModal";
import { fetchConfiguration } from "../../../services/configurationService";
import { errorToast } from "../../Toasts/Toasts";

const ConfigurationGrid = () => {
  const [showModalBox, setShowModalBox] = useState(false);
  const [configurationDetails, setConfigurationDetails] = useState("");
  const [value, setValue] = useState('');
  const [reRenderData, setReRenderData] = useState(true);

  useEffect(() => {
    fetchCustomerHistoryDetails();
    
    // eslint-disable-next-line
  }, [reRenderData]);

  const fetchCustomerHistoryDetails = async () => {
    try {
      const response = await fetchConfiguration();
      if (response?.success) {
        setConfigurationDetails(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };
  const handleReRenderData = (response) => {
    setReRenderData(response);
  };
  return (
    <>
      <div className="card">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Details</h5>
        </div>
        <div className="card-datatable table-responsive">
          <table className="table" id="orders">
            <thead>
              <tr>
                <th>Config Name</th>
                <th>Config Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {configurationDetails?.length !== 0  ? (configurationDetails?.map((details,index) => (
                <tr key={index}>
                  <td>{details?.name}</td>
                  <td>{details?.value}</td>
                  <td><Link to="#" title="Edit" onClick={() => {setShowModalBox(true);setValue(details)}}><i className="ti ti-edit" /></Link></td>
                </tr>))
              ) : (
                <tr className="pt-3 ps-3">
                  <td colSpan="5">No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <EditModal showModalBox={showModalBox} setShowModalBox={setShowModalBox} value={value} handleReRenderData={handleReRenderData} />
    </>
  );
};

export default ConfigurationGrid;
