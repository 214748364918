import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { useDispatch } from "react-redux";
import { setFilteredChips, clearFilteredChips, postLogTransactionFilterApi } from "../../../redux/features/logTransactionSlice";

const LogTransactionFilter = ({ masterData, clearFilterValues, currentSize }) => {

  const [showModalBox, setShowModalBox] = useState(false);
  const [carrier, setCarrier] = useState("");
  const [status, setStatus] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState("");
  const [logLastUpdate, setLogLastUpdate] = useState("");
  let page = 1;
  const dispatch = useDispatch();

  useEffect(() => {
    const filterValue = () => {
      if (clearFilterValues === "transaction_id") setTransactionId("");
      if (clearFilterValues === "mobile_number") setRecipient("");
      if (clearFilterValues === "carrier_id") setCarrier("");
      if (clearFilterValues === "updatedAt") setLogLastUpdate("");
      if (clearFilterValues === "status_type_id") setStatus("");
      if (clearFilterValues === "total_amount_converted_currency") setAmount("");
    };

    filterValue();

    // eslint-disable-next-line
  }, [clearFilterValues]);

  const carrierOption = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };
  const statusOption = () => {
    return masterData?.status?.map((data) => ({
      label: data?.status_name,
      value: data?.status_id,
    }));
  };

  const handleEventReceiveDate = (event, picker) => {
    picker.element.val(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setLogLastUpdate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelReceiveDate = (event, picker) => {
    picker.element.val("");
    setLogLastUpdate("");
  };

  function handleClose() {
    setShowModalBox(false);
  }

  const filterData = {
    transaction_id: transactionId !== "" ? transactionId : "",
    carrier_id: carrier !== "" ? carrier?.value : "",
    status_type_id: status !== "" ? status?.value : "",
    updatedAt: logLastUpdate !== "" ? logLastUpdate : "",
    total_amount_converted_currency: amount !== "" ? amount : "",
    mobile_number: recipient !== "" ? recipient : "",
  };

  const filterChipsData = {
    transaction_id: transactionId !== "" ? transactionId : "",
    carrier_id: carrier !== "" ? carrier : "",
    status_type_id: status !== "" ? status : "",
    updatedAt: logLastUpdate !== "" ? logLastUpdate : "",
    total_amount_converted_currency: amount !== "" ? amount : "",
    mobile_number: recipient !== "" ? recipient : "",
  };

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };

  const filteredData = filterEmptyFields(filterData);
  const filteredChipsData = filterEmptyFields(filterChipsData);

  const submitFilterData = async () => {
    const isFilterEmpty = Object.entries(filteredData).every((value) => {
      return value === "";
    });

    if (!isFilterEmpty) {
      const response = await dispatch(postLogTransactionFilterApi({ filteredData: filteredData, page, size: currentSize }));
      dispatch(setFilteredChips(Object.entries(filteredChipsData)));

      if (response?.meta?.requestStatus === "fulfilled") {
        successToast("Filter applied successfully");
      } else {
        errorToast("Error occurred while filtering");
      }

      handleClose();
    } else {
      errorToast("No field selected for filter");
    }
  };

  const handleResetButton = () => {
    setStatus("");
    setCarrier("");
    setTransactionId("");
    setAmount("");
    setLogLastUpdate("");
    setRecipient("");
    dispatch(postLogTransactionFilterApi({ filteredData: {}, page, size: currentSize }));
    dispatch(clearFilteredChips());
    handleClose();
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary rounded-pill btn-smwaves-effect waves-light me-2" onClick={(e) => setShowModalBox(true)}>
        <i className="ti ti-filter me-1" /> Filter
      </button>
      {
        <Modal show={showModalBox} size="lg" centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <Modal.Body>
                <button type="button" className="btn-close" onClick={handleClose} />
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Transaction ID</label>
                    <input type="text" placeholder="" className="form-control" value={transactionId} onChange={(e) => setTransactionId(e.target.value)} />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Amount (USD)</label>
                    <input type="text" placeholder="" className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)} />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Transaction Date</label>
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: { cancelLabel: "Clear", format: "YYYY-MM-DD" },
                        showDropdowns: true,
                        drops: "down",
                      }}
                      onApply={handleEventReceiveDate}
                      onCancel={handleCancelReceiveDate}
                    >
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="DD-MM-YYYY to DD-MM-YYYY" autoComplete="off" defaultValue={logLastUpdate} />
                    </DateRangePicker>
                  </div>

                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Recipient No.</label>
                    <input type="text" placeholder="" className="form-control" value={recipient} onChange={(e) => setRecipient(e.target.value)} />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Provider</label>
                    <Select options={carrierOption()} isMulti={false} onChange={setCarrier} value={carrier} />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Status</label>
                    <Select options={statusOption()} isMulti={false} onChange={setStatus} value={status} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" onClick={submitFilterData} className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-filter">
                      Submit
                    </button>
                    <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleResetButton}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default LogTransactionFilter;
