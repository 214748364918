import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postUserFilterApi, setFilteredUserChips } from "../../../redux/features/userSlice";
import { editUser } from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import ManageUserEditModal from "./ManageUserEditModal";
import { chipName } from "../../../utils/sharedFunctions";

const ManageUserGrid = ({ userDetailsShow, setUserDetailsShow, setUserDetailsForEditModal, userDetailsForEditModal, setReRendering, reRendering, setTotalUsers, currentSize, setCurrentSize, masterData, setId, setClearFilterValues, filteredUserChips, userAddedBy, transformedDataToBeFiltered, DISTRIBUTOR_USER_ID, setAccountNumber, setUser, setOnboardingDate, setStatus, setDisplayDate }) => {
  const page = 1;
  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [editModalShow, setEditModalShow] = useState(false);
  const [heading, setHeading] = useState("");

  const handleShowUserDetailsModal = (index) => {
    setUserDetailsForEditModal(userDetails[index]);
    setUserDetailsShow(!userDetailsShow);
  };

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);
    const response = await dispatch(postUserFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize + 10 }));
    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload.records;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoading(false);
  }, [loading, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, dispatch, page, transformedDataToBeFiltered]);

  useEffect(() => {
    dispatch(postUserFilterApi({ filteredData: { role_id: DISTRIBUTOR_USER_ID, added_by_id: userAddedBy }, page, size: currentSize }));
    // eslint-disable-next-line
  }, [reRendering]);

  let userDetails = useSelector((state) => state.user.userDetails);

  let count = useSelector((state) => state.user.count);
  let loaderStatus = useSelector((state) => state.user.isLoading)
  if (userDetails) {
    setTotalUsers(count?.total_retailers);
  }

  const handleShowModal = (index) => {
    setUserDetailsForEditModal(userDetails[index]);
    setHeading("Edit user");
    setEditModalShow(!editModalShow);
  };

  const handleCheckboxChange = async (event, userDetail) => {
    let userStatus = {
      is_active: event.target.checked,
    };

    const response = await editUser(userDetail?.id, userStatus);
    if (response?.success) {
      successToast(response?.message);
      dispatch(postUserFilterApi({ filteredData: transformedDataToBeFiltered, page, size : currentSize }));
    } else {
      errorToast(response?.message);
    }
  };

  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredUserChips.filter(([existingKey]) => existingKey !== key);
    // Dispatch the updated chips to the Redux store
    dispatch(setFilteredUserChips(updatedChips));

    const convertedChips = Object.fromEntries(updatedChips);

    let transformedChips = {};

    for (let key in convertedChips) {
      if (typeof convertedChips[key] === "object" && "label" in convertedChips[key] && "value" in convertedChips[key]) {
        transformedChips[key] = convertedChips[key].value;
      } else {
        transformedChips[key] = convertedChips[key];
      }
    }

    transformedChips.role_id = DISTRIBUTOR_USER_ID;
    transformedChips.added_by_id = userAddedBy;

    if (key === "account_no") {
      setAccountNumber("");
    } else if (key === "name") {
      setUser("");
    } else if (key === "createdAt") {
      setOnboardingDate("");
      setDisplayDate("");
    } else if (key === "is_active") {
      setStatus("");
    } else {
    }
    // Call the product filter API with the updated filteredChips
    dispatch(postUserFilterApi({ filteredData: transformedChips, page, size: currentSize + 10 }));
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("distributorUserComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("distributorUserComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Details</h5>
          <div className="filter-card" style={{ display: "block" }}>
            {filteredUserChips?.map(([key, value]) => (
              <div className="chip" key={key}>
                <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                <span>
                  {chipName(key)} {":"} {key === "is_active" ? value?.label : value}
                </span>
                <button type="button" className="zap">
                  <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <InfiniteScroll dataLength={userDetails.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="distributorUserComponent">
          <div id="distributorUserComponent" className="card-datatable table-responsive" style={{ height: "400px", overflowY: "auto" }}>
            <table className="table" id="orders">
              <thead>
                <tr>
                  <th>Account No.</th>
                  <th>Users</th>
                  <th>Onboarding Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loaderStatus ? (
                  // Show loading indicator if loaderStatus is true
                  <tr className="pt-3 ps-3">
                    <td colSpan="12">Loading...</td>
                  </tr>
                ) : userDetails?.length !== 0 ? (
                  // Render user details if userDetails has data
                  userDetails.map((distributorUserDetails, index) => (
                    <tr key={index}>
                      <td key={distributorUserDetails?.id}>
                        <Link onClick={() => handleShowUserDetailsModal(index)}>{distributorUserDetails?.account_no}</Link>
                      </td>
                      <td>{distributorUserDetails?.name}</td>
                      <td>{moment(distributorUserDetails?.createdAt).format("DD MMM YY")}</td>
                      <td>
                        <div className="d-flex">
                          <input className="input-switch" type="checkbox" id={`checkbox-${index}`} checked={distributorUserDetails?.is_active === 1} onChange={(event) => handleCheckboxChange(event, distributorUserDetails)} />
                          <label className="label-switch" htmlFor={`checkbox-${index}`} />
                          <span className="info-text ms-2" />
                        </div>
                      </td>
                      <td>
                        <span onClick={() => handleShowModal(index)} style={{ color: "#0282C3", cursor: "pointer" }} title="edit">
                          <i className="ti ti-edit" />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  // Show "No record found" message if userDetails is empty
                  <tr className="pt-3 ps-3">
                    <td colSpan="12">No record found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      </div>

      {editModalShow && <ManageUserEditModal editModalShow={editModalShow} setEditModalShow={setEditModalShow} heading={heading} userDetailsForEditModal={userDetailsForEditModal} setReRendering={setReRendering} reRendering={reRendering} setUserDetailsForEditModal={setUserDetailsForEditModal} masterData={masterData} setId={setId} transformedDataToBeFiltered={transformedDataToBeFiltered} dispatch={dispatch} postUserFilterApi={postUserFilterApi} page={page} currentSize={currentSize} />}
    </>
  );
};

export default ManageUserGrid;
