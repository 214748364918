import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../../services/notificationService";

const initialState = {
  notificationDetails: [],
  isLoading: false,
  error: null,
};

export const notificationApi = createAsyncThunk("notification/getNotificationApi", async () => {
  try {
    const response = await getNotifications();
    return response
  } catch (error) {
    throw error;
  }
});

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setInitNotificationDetail: (state) => {
      state.notificationDetails = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(notificationApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(notificationApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notificationDetails = [...action.payload];
    });

    builder.addCase(notificationApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = notificationSlice;
export const { setInitNotificationDetail } = notificationSlice.actions;
export default reducer;
