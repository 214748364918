import ShortUniqueId from "short-unique-id";

export const formatCreditBalanceUptoFourDecimal = (creditBalance) => {
	const suffixes = ["", "K", "M", "B", "T"];
	const order = Math.floor(Math.log10(creditBalance) / 3);

	if (order < 1) return parseFloat(creditBalance).toFixed(2);

	const formattedBalance = (creditBalance / Math.pow(10, order * 3)).toFixed(2);

	return `${formattedBalance}${suffixes[order]}`;
};

export const formatCreditBalance = (creditBalance) => {
  const suffixes = ["", "K", "M", "B", "T"];

  const order = Math.floor(Math.log10(Math.abs(creditBalance)) / 3);

  // Return creditBalance as string if order is less than 1
  if (order < 1) {
    if (isNaN(creditBalance) || creditBalance === null || creditBalance === undefined) {
      return "0.00";  // Handle invalid or missing creditBalance
    }
    return Number(creditBalance).toFixed(2);  // Ensure it's a number before calling toFixed
  }

  // Calculate formatted balance and suffix
  const formattedBalance = (creditBalance / Math.pow(10, order * 3)).toFixed(2);
  const suffix = suffixes[order];

  return `${formattedBalance}${suffix}`;
};
export const formatAbsoluteValue = (creditBalance) => {
  const suffixes = ["", "K", "M", "B", "T"];

  const order = Math.floor(Math.log10(Math.abs(creditBalance)) / 3);

  // Return creditBalance as string if order is less than 1
  if (order < 1) return Math.floor(creditBalance).toString();

  // Calculate formatted balance and suffix
  const formattedBalance = Math.floor(creditBalance / Math.pow(10, order * 3));
  const suffix = suffixes[order];

  return `${formattedBalance}${suffix}`;
};


export const chipName = (key) => {
  function getKeyLabel(key) {
    switch (key) {
      case "createdAt":
        return "Onboarding Date";
      case "is_active":
        return "Status";
      case "country_id":
        return "Location";
      case "billing_type_id":
        return "Billing Type";
      case "retailerCount":
        return "Retailer Count";
      case "credit_status_type_id":
        return "Status";
      case "carrier_id":
        return "Carrier id";
      case "updatedAt":
        return "Last Update";
      case "status_type_id":
        return "Status";
      case "credit_balance":
        return "Live Balance";
      default:
        return key.replace(/_/g, " ").replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
    }
  }

  const keyName = getKeyLabel(key);

  return keyName;
};

export const generateShortUid = () => {
	return new ShortUniqueId({ length: 16, dictionary: "alphanum_upper" });
};
