import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transactionRequest } from "../../services/transactionService";

const initialState = {
  requestTransactionDetails: [],
  isLoading: false,
  error: null,
};

export const requestTransactionApi = createAsyncThunk("requestTransaction/getRequestTransactionApi", async (amount) => {
  try {
    const response = await transactionRequest(amount);
    return response
  } catch (error) {
    console.error("Error fetching transaction details:", error);
    throw error;
  }
});

const requestTransactionSlice = createSlice({
  name: "requestTransaction",
  initialState,
  reducers: {
    setInitRequestTransactionDetail: (state) => {
      state.requestTransactionDetails = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestTransactionApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(requestTransactionApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.requestTransactionDetails = action.payload;
    });

    builder.addCase(requestTransactionApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = requestTransactionSlice;
export const { setInitRequestTransactionDetail } = requestTransactionSlice.actions;
export default reducer;