import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setLogout } from "../../redux/features/authSlice";
import { userProfile } from "../../services/userService";
import { socket } from "../../utils/socket";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "../Toasts/Toasts";
import localStorageStore from "../../utils/localStorageStore";
import { userRoleConstants } from "../../utils/constants";
import { resetUserState } from "../../redux/features/userSlice";
import { resetProductState } from "../../redux/features/productSlice";
import { resetTransactionTopUpState } from "../../redux/features/topUpSlice";
import { setInitTransactionDetail } from "../../redux/features/transactionCreditSlice";
import { setInitAddTransactionDetail } from "../../redux/features/addTransactionSlice";
import { fetchCurrency } from "../../services/transactionService";
import { notificationApi } from "../../redux/features/notificationSlice";
import { updateNotificationApi } from "../../redux/features/notificationUpdationSlice";
import { getMfa } from "../../services/userService";
// import { updateAllNotificationApi } from "../../redux/features/notificationUpdateAllSlice";
import Swal from "sweetalert2";
import { getEmailNotification } from "../../services/helpAndSupportService";
import MfaModal from "./MfaModal";
const moment = require("moment");

const Header = () => {
  const [userProfileData, setUserProfileData] = useState();
  const [convertedCurrency, setConvertedCurrency] = useState();
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [id, setId] = useState("");
  const [selectedRoleTab, setSelectedRoleTab] = useState(userRoleConstants.RETAILER_ID);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [updatedMinute, setUpdatedMinute] = useState();
  const [openTicketCount, setOpenTicketCount] = useState();
  const [showMfaModal, setShowMfaModal] = useState(false); // Modal visibility state
  const [qrCodeUrl, setQrCodeUrl] = useState(""); // To store the QR code URL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let words = userProfileData?.name?.split(" ");
  let initials = words?.map((word) => word?.charAt(0));
  let result = initials?.join("");
  let addTransaction = useSelector((state) => state.addTransaction.addTransactionDetails);
  const RoleId = parseInt(localStorageStore.getRole());
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [renderProfileData, SetRenderProfileData] = useState(false);

  useEffect(() => {
    if (userProfileData?.billing_type_id) {
      localStorage.setItem("billing_type_id", userProfileData?.billing_type_id);
    } else if (userProfileData?.distributor_billing_type_id) {
      localStorage.setItem("billing_type_id", userProfileData?.distributor_billing_type_id);
    }
  }, [userProfileData]);

  function handleLogout() {
    Swal.fire({
      text: "Are you sure you want to Logout?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-label-secondary",
        content: "info text-primary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Code to execute when the user clicks "Yes, Delete"
        localStorage.removeItem("token");
        localStorage.removeItem("roleId");
        localStorage.removeItem("userId");
        localStorage.removeItem("distributorOrRetailerId");
        localStorage.removeItem("billing_type_id");
        dispatch(setLogout());
        dispatch(resetUserState());
        dispatch(resetProductState());
        dispatch(resetTransactionTopUpState());
        dispatch(setInitTransactionDetail());
        dispatch(setInitAddTransactionDetail());
        socket.disconnect("notification");
        navigate("/");
      }
    });
  }

  const fetchUserProfile = async () => {
    try {
      const response = await userProfile();
      if (response?.success) {
        const userId = response?.data?.id;

        if (userId !== undefined) {
          setId(userId);
          localStorage.setItem("userId", userId);
        }
        setUserProfileData(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while fetching user profile.");
    }
  };

  const fetchCardCurrency = async () => {
    try {
      const response = await fetchCurrency();
      if (response?.success) {
        setConvertedCurrency(response?.data);
        setLastUpdate(new Date()); // Store the current time as the last update time
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while fetching card currency.");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserProfile();
      const fetchNotificationDetails = async () => {
        try {
          await dispatch(notificationApi());
        } catch (error) {
          errorToast(error?.response?.data?.message || "Something went wrong");
        }
      };
      fetchNotificationDetails();
    }
  }, [dispatch, RoleId, isAuthenticated]);

  useEffect(() => {
    fetchUserProfile();
  }, [renderProfileData]);

  useEffect(() => {
    try {
      if (userProfileData?.id) {
        socket.connect();
        socket.on("connect", () => {
          socket.emit("mapUser", { sid: socket.id, user_id: parseInt(id) });
        });
      }

      socket.on("notification", (data) => {
        try {
          dispatch(notificationApi());
          if (id) {
            successToast(data?.notification);
          }
        } catch (error) {
          errorToast(error.message || "An error occurred");
        }
      });

      return () => {
        socket.disconnect("notification");
      };
    } catch (error) {
      errorToast(error.message || "An error occurred during initialization");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTransaction, id]);

  const response = useSelector((state) => state.notification.notificationDetails);

  useEffect(() => {
    setNotificationDetails(response);
  }, [response]);

  const handleNotificationClick = async (id) => {
    try {
      const response = await dispatch(updateNotificationApi(id));

      if (response.payload.success) {
        dispatch(notificationApi());
        successToast("Notification successfully marked as read");
      } else {
        errorToast("Failed to mark notification as read");
      }
    } catch (error) {
      errorToast(error?.message || "Something went wrong");
    }
  };

  // const handleAllMarked = async (receiverId) => {
  //   try {
  //     const response = await dispatch(updateAllNotificationApi(receiverId));

  //     if (response.payload.success) {
  //       dispatch(notificationApi());
  //       successToast("All notifications successfully marked as read");
  //     } else {
  //       errorToast("Failed to mark notifications as read");
  //     }
  //   } catch(error) {
  //     errorToast(error?.message || "Something went wrong");
  //   }

  // };

  let count = 0;

  for (const notification of notificationDetails) {
    if (!notification.is_read) {
      count++;
    }
  }

  useEffect(() => {
    if (RoleId === userRoleConstants.RETAILER_ID) {
      fetchCardCurrency();
    }
    calculateTimeDifference(); // Call the function directly without dependency on updatedMinute

    const intervalId = setInterval(() => {
      calculateTimeDifference(); // Recalculate time difference periodically
    }, 60000); // Run every minute

    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, []); // No dependencies to avoid infinite loop

  const calculateTimeDifference = () => {
    const currentTime = new Date();
    const difference = Math.floor((currentTime - lastUpdate) / (1000 * 60)); // Difference in minutes

    // If difference is 0 or less than 60, display the minutes
    if (difference <= 0) {
      setUpdatedMinute("0 minute ago");
    } else {
      setUpdatedMinute(`${difference} minute${difference === 1 ? "" : "s"} ago`);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const emailNotification = async () => {
        try {
          const response = await getEmailNotification();
          setOpenTicketCount(response?.data?.total_open_ticket_count);
        } catch (error) {
          errorToast(error?.response?.data?.message);
        }
      };
      if (RoleId !== userRoleConstants?.DISTRIBUTOR_USER_ID && RoleId !== userRoleConstants?.MANAGER_ID && RoleId !== userRoleConstants?.SALES_REPRESENTATIVE_ID && RoleId !== userRoleConstants?.ACCOUNTANT_ID && RoleId !== userRoleConstants?.PROCUREMENT_ID) {
        emailNotification();
      }
    }
  });
  const handleEnableMfa = async () => {
    try {
      const response = await getMfa();
      if (response?.data?.qrCodeUrl) {
        setQrCodeUrl(response?.data?.qrCodeUrl); // Set QR code URL
        setShowMfaModal(true); // Show the modal
      }
    } catch (error) {
      errorToast(error?.message || "Something went wrong");
    }
  };

  const handleCloseMfaModal = () => {
    setShowMfaModal(false);
  };

  return (
    <nav className="layout-navbar container-fluid navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
      <div className="navbar py-1 mt-0 navbar-nav-right d-flex p-0 m-0">
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none ">
          <Link className="nav-item nav-link px-0 me-xl-4" to="#">
            <i className="ti ti-menu-2 ti-sm" />
          </Link>
        </div>
        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {RoleId === userRoleConstants.RETAILER_ID && (
              <div>
                {" "}
                {convertedCurrency?.afnConversion?.converted_currency && (
                  <div className="d-flex">
                    {convertedCurrency?.afnConversion?.country_code === convertedCurrency?.retailerConversion?.country_code ? (
                      <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-3">
                        <div className="bg-label-success p-2 px-3 rounded-pill">
                          <span>
                            {" "}
                            <i className="ti ti-cash" />
                          </span>{" "}
                          <span className="text-dark me-2">
                            {convertedCurrency?.afnConversion?.source_currency_code}/{convertedCurrency?.afnConversion?.target_currency_code}
                          </span>{" "}
                          <span className="text-success"> {parseFloat(convertedCurrency?.afnConversion?.converted_currency).toFixed(4)}</span> <span />
                        </div>
                      </li>
                    ) : (
                      <>
                        <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-3">
                          <div className="bg-label-success p-2 px-3 rounded-pill">
                            <span>
                              {" "}
                              <i className="ti ti-cash" />
                            </span>{" "}
                            <span className="text-dark me-2">
                              {convertedCurrency?.afnConversion?.source_currency_code}/{convertedCurrency?.afnConversion?.target_currency_code}
                            </span>{" "}
                            <span className="text-success"> {parseFloat(convertedCurrency?.afnConversion?.converted_currency).toFixed(4)}</span> <span />
                          </div>
                        </li>
                        <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-3">
                          <div className="bg-label-info p-2 px-3 rounded-pill">
                            <span>
                              {" "}
                              <i className="ti ti-cash" />
                            </span>{" "}
                            <span className="text-dark me-2">
                              {convertedCurrency?.retailerConversion?.source_currency_code}/{convertedCurrency?.retailerConversion?.target_currency_code}
                            </span>{" "}
                            <span className="text-info"> {parseFloat(convertedCurrency?.retailerConversion?.converted_currency).toFixed(4)}</span>
                          </div>
                        </li>
                      </>
                    )}
                  </div>
                )}
                <small className="text-muted d-flex justify-content-end me-4">Updated {updatedMinute}</small>
              </div>
            )}
            {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && (
              <li className="nav-item me-3 me-xl-3">
                <Link className="nav-link" to="support-help">
                  <i className="ti ti-headset ti-md" />
                  <span className="badge bg-danger rounded-pill badge-notifications help-notification">{openTicketCount}</span>
                </Link>
              </li>
            )}
            {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) && <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-3">
              <Link className="nav-link dropdown-toggle hide-arrow" to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <i className="ti ti-bell ti-md" />
                {count !== 0 && <span className="badge bg-danger rounded-pill badge-notifications">{count}</span>}
              </Link>
              <ul className="dropdown-menu dropdown-menu-end py-0" style={{ maxHeight: "500px", overflowY: "auto" }}>
                <li className="dropdown-menu-header border-bottom">
                  <div className="dropdown-header d-flex align-items-center py-3">
                    <h5 className="text-body mb-0 me-auto">Notifications</h5>
                    {RoleId === (userRoleConstants.DISTRIBUTOR_ID || userRoleConstants.DISTRIBUTOR_USER_ID) && (
                      // <span
                      //   onClick={() => {
                      //     handleAllMarked(notificationDetails[0]?.receiver_id ?? "");
                      //   }}
                      //   style={{ cursor: "pointer" }}
                      //   className="dropdown-notifications-all text-body"
                      //   data-bs-toggle="tooltip"
                      //   data-bs-placement="top"
                      //   aria-label="Mark all as read"
                      //   data-bs-original-title="Mark all as read"
                      // >
                      //   <i className="ti ti-mail-opened fs-4" />
                      // </span>
                      <ul className="nav nav-pills mb-0" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            type="button"
                            className="nav-link active"
                            role="tab"
                            data-bs-toggle="tab"
                            data-bs-target="#navs-pills-top-home"
                            onClick={() => {
                              setSelectedRoleTab(userRoleConstants.RETAILER_ID);
                            }}
                          >
                            Retailer
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            type="button"
                            className="nav-link"
                            role="tab"
                            data-bs-toggle="tab"
                            data-bs-target="#navs-pills-top-profile"
                            onClick={() => {
                              setSelectedRoleTab(userRoleConstants.ADMIN_ID);
                            }}
                            tabIndex={-1}
                          >
                            Shaad
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
                {notificationDetails && RoleId === userRoleConstants.DISTRIBUTOR_ID
                  ? notificationDetails.map(
                      (notificationDetail) =>
                        selectedRoleTab === notificationDetail?.sender_role_id && (
                          <li
                            className="dropdown-notifications-list"
                            onClick={() => {
                              handleNotificationClick(notificationDetail?.id);
                            }}
                            key={notificationDetail.id}
                          >
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                {notificationDetail && notificationDetail?.is_read ? (
                                  ""
                                ) : (
                                  <span className="dropdown-notifications-read" style={{ marginLeft: "18rem" }}>
                                    <span className="badge badge-dot"></span>
                                  </span>
                                )}
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    {/* <h6 className="mb-1">{notificationDetail.notification_type_id === 1 ? "Credit added" : notificationDetail.notification_type_id === 2 ? "Credit Request" : notificationDetail.notification_type_id === 3 ? "Commission Updated" : notificationDetail.notification_type_id === 4 ? "Approve Credit" : notificationDetail.notification_type_id === 5 ? "Decline Credit" : notificationDetail.notification_type_id === 6 ? "Recharge Request" : notificationDetail.notification_type_id === 7 ? "Recharge Request Declined" : notificationDetail.notification_type_id === 8 ? "Recharge Request Approved" : notificationDetail.notification_type_id === 9 ? "Invoice Created" : ""}</h6> */}
                                    <h6 className="mb-1">{notificationDetail?.notification_title}</h6>
                                    <p className="mb-0">{notificationDetail?.notification}</p>
                                    <small className="text-muted">{moment(notificationDetail?.updatedAt).format("DD MMM YY, hh:mm A")}</small>
                                  </div>
                                </div>
                                <div className="col-12 text-end mt-2"></div>
                              </li>
                            </ul>
                          </li>
                        )
                    )
                  : notificationDetails.map((notificationDetail) => (
                      <li
                        className="dropdown-notifications-list"
                        onClick={() => {
                          handleNotificationClick(notificationDetail?.id);
                        }}
                        key={notificationDetail.id}
                      >
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action dropdown-notifications-item">
                            {notificationDetail && notificationDetail?.is_read ? (
                              ""
                            ) : (
                              <span className="dropdown-notifications-read" style={{ marginLeft: "18rem" }}>
                                <span className="badge badge-dot"></span>
                              </span>
                            )}
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <h6 className="mb-1">{notificationDetail?.notification_title}</h6>
                                <p className="mb-0">{notificationDetail?.notification}</p>
                                <small className="text-muted">{moment(notificationDetail?.updatedAt).format("DD MMM YY, hh:mm A")}</small>
                              </div>
                            </div>
                            <div className="col-12 text-end mt-2"></div>
                          </li>
                        </ul>
                      </li>
                    ))}
              </ul>
            </li>}
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <Link className="nav-link dropdown-toggle hide-arrow" to="#" data-bs-toggle="dropdown">
                <div className="avatar">
                  <span className="avatar-initial rounded-circle bg-label-danger text-primary">{result}</span>
                </div>
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item" to="#">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar">
                          <span className="avatar-initial rounded-circle bg-label-danger text-primary">{result}</span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-medium d-block">{userProfileData?.name}</span>
                        <small>
                          <strong>{userProfileData?.role}</strong>
                        </small>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider" />
                </li>
                {parseInt(userProfileData?.is_mfa_enabled) === 0 ? (
                  <li>
                    <Link className="dropdown-item" to="#" onClick={handleEnableMfa}>
                      <i className="ti ti-shield-lock me-2" />
                      <span className="align-middle">Enable MFA</span>
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link className="dropdown-item" to="#" onClick={() => setShowMfaModal(true)}>
                      <i className="ti ti-shield-lock me-2" />
                      <span className="align-middle">Disable MFA</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link className="dropdown-item" to="#" onClick={handleLogout}>
                    <i className="ti ti-logout me-2 ti-sm" />
                    <span className="align-middle">Log Out</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {showMfaModal && <MfaModal show={showMfaModal} handleClose={handleCloseMfaModal} qrCodeUrl={qrCodeUrl} SetRenderProfileData={SetRenderProfileData} renderProfileData={renderProfileData} mfaValue={parseInt(userProfileData?.is_mfa_enabled)} />}
    </nav>
  );
};

export default Header;
