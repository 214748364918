import React from "react";
import ColumnChart from "../../Shared/ManageChart/ColumnChart";
import BarChart from "../../Shared/ManageChart/BarChart";

const ManageDashboardBody = ({dashboardRecord}) => {
  const TransactionsText = "Transaction vs Retailer";
  const TransactionsSmallText = "";
  const color = "#7ab593";
  const xaxisText = "Retailers";
  const yaxisText = "Transactions";

  const PaymentText = "Payment vs Retailer";
  const PaymentSmallText = "";
  const colors = "#42a1d1";
  const xaxisPaymentText = "Retailers";
  const yaxisPaymentText = "Payment";

  const SalesText = "Sales & Commission vs Retailer";
  const SalesSmallText = "";
  const xaxisSalesText = "Retailers";
  const yaxisSalesText = "Sales & Commission";

  const amount = dashboardRecord && dashboardRecord?.retailerVsTopup?.map(item => item?.total_topup_amount);
  const accountNumber = dashboardRecord && dashboardRecord?.retailerVsTopup?.map(item => item?.retailer_account_no);
  const count = dashboardRecord && dashboardRecord?.retailerVsTopup?.map(item => item?.total_topup_count);
  const commission = dashboardRecord && dashboardRecord?.retailerVsTopup?.map(item => item?.total_commission_earned);
  const totalTransactionCount = dashboardRecord && dashboardRecord?.creditTransactionVsRetailer?.map(item => item?.total_transaction_count);
  const creditTransactionVsRetailerAccountNumber = dashboardRecord && dashboardRecord?.creditTransactionVsRetailer?.map(item => item?.retailer_account_no);
  const retailerVsPaymentDueAccountNumber = dashboardRecord && dashboardRecord?.retailerVsPaymentDue?.map(item => item?.retailer_account_no);
  const totalPaymentDuesCount = dashboardRecord && dashboardRecord?.retailerVsPaymentDue?.map(item => item?.total_payment_due_amount);
  return (
    <>
      <div className="col-lg-6 mb-3">
        <BarChart headerText = {TransactionsText} smallText= {TransactionsSmallText} colors = {color} xaxisText= {xaxisText} yaxisText={yaxisText} count={totalTransactionCount} name={creditTransactionVsRetailerAccountNumber} />
      </div>
      <div className="col-lg-6 mb-3 ">
        <BarChart headerText = {PaymentText} smallText= {PaymentSmallText} colors = {colors} xaxisText= {xaxisPaymentText} yaxisText={yaxisPaymentText} count={totalPaymentDuesCount} name={retailerVsPaymentDueAccountNumber}/>
      </div>
      <div className="col-lg-8 mb-3">
        <ColumnChart headerText = {SalesText} smallText= {SalesSmallText}  xaxisText= {xaxisSalesText} yaxisText={yaxisSalesText}  amount={amount} accountNumber={accountNumber} count={count} commission={commission} />
      </div>
    </>
  );
};

export default ManageDashboardBody;
