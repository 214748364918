import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transactions } from "../../services/transactionService";

const initialState = {
  addTransactionDetails: {},
  isLoading: false,
  error: null,
};

export const postAddTransactionDetailApi = createAsyncThunk(
  "addTransaction/postAddTransactionDetailApi",
  async (filteredData) => {
    try {
      const response = await transactions(filteredData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const addTransactionSlice = createSlice({
  name: "addTransaction",
  initialState,
  reducers: {
    setInitAddTransactionDetail: (state) => {
      state.addTransactionDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAddTransactionDetailApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(postAddTransactionDetailApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addTransactionDetails = [action.payload];
    });

    builder.addCase(postAddTransactionDetailApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = addTransactionSlice;
export const { setInitAddTransactionDetail } = addTransactionSlice.actions;
export default reducer;
