import encrypt from "./encrypt";
import decrypt from "./decrypt";

const localStorageStore = {}

localStorageStore.getToken = () => {
    return localStorage.token ?? '';
}

localStorageStore.setToken = (token) => {
    localStorage.setItem("token", token);
}

localStorageStore.setRole = (role) => {
    localStorage.setItem("roleId", encrypt(role));
}

localStorageStore.getRole = () => {
    return decrypt(localStorage.roleId ?? '');
}

export default localStorageStore;