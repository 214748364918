import { combineReducers } from "redux";
import authSlice from "./features/authSlice";
import transactionSlice from "./features/transactionCreditSlice";
import userSlice from "./features/userSlice";
import topUpSlice from "./features/topUpSlice";
import addTransactionSlice from "./features/addTransactionSlice";
import productSlice from "./features/productSlice";
import notificationSlice from "./features/notificationSlice";
import notificationUpdateSlice from "./features/notificationUpdationSlice";
import commissionHistorySlice from "./features/commissionHistorySlice";
import requestTransactionSlice from "./features/requestTransactionSlice";
import adminCustomerSlice from "./features/adminCustomerSlice"
import paymentGatewaySlice from "./features/paymentGatewaySlice";
import customerProfileSlice  from "./features/customerProfileSlice";
import helpAndSupportSlice from './features/helpAndSupportSlice';
import logTransactionSlice from "./features/logTransactionSlice";

const rootReducer = combineReducers({
    auth: authSlice,
    transaction: transactionSlice,
    topUpSlice: topUpSlice,
    user: userSlice, 
    addTransaction : addTransactionSlice,
    product : productSlice,
    notification : notificationSlice,
    updateNotification : notificationUpdateSlice,
    commissionHistory : commissionHistorySlice,
    requestTransaction: requestTransactionSlice,
    adminCustomer: adminCustomerSlice,
    paymentGateway: paymentGatewaySlice,
    customerProfile: customerProfileSlice,
    helpAndSupport : helpAndSupportSlice,
    logTransaction: logTransactionSlice
});

export default rootReducer;