import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const getQueryCategories = async () => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.QUERY_CATEGORIES}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const replyQueryResponse = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.QUERY_RESPONSE}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const helpAndSupportFilter = async (filterData, page, size) => {
  try {
    let url = `${baseUrl}/${ApiConstants.HELP_AND_SUPPORT}?page=${page}&size=${size}`;
    const queryParams = new URLSearchParams(filterData).toString();

    if (queryParams) {
      url += `&${queryParams}`;
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendQuery = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.QUERY}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const requestFollowUp = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/${ApiConstants.REQUEST_FOLLOW_UP}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getEmailNotification = async () => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.HELP_AND_SUPPORT}/notification`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const postQueryStatus = async (data) => {
  try {
    const response = await axios.put(`${baseUrl}/${ApiConstants.HELP_AND_SUPPORT}/query/status`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getQueryDetails = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.HELP_AND_SUPPORT}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getQueryDetailsForParticularId = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.GET_QUERY_BY_ID}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getQueryDetailsForAll = async () => {
  try{
    const response = await axios.get(`${baseUrl}/${ApiConstants.GET_QUERY_BY_ID}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateStatus = async (data) => {
  try {
    const response = await axios.put(`${baseUrl}/${ApiConstants.STATUS_UPDATE}`,data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const queryResponse = async (data) => {
  try{
    const response = await axios.post(`${baseUrl}/${ApiConstants.QUERY_RESPONSE}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}