import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { convertCurrency, fetchCustomerProduct } from "../../../services/customerService";
import { errorToast } from "../../Toasts/Toasts";
import InfiniteScroll from "react-infinite-scroll-component";
import PhoneInput from "react-phone-number-input";
import { Controller, useForm } from "react-hook-form";
import SelectPaymentMethod from "../../Shared/SelectPaymentMethod";

const ProductListing = () => {
  
  const [showProductSelect, setShowProductSelect] = useState(true);
  const [showTopUpInfo, setShowTopUpInfo] = useState(false);
  const [productData, setProductData] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [size, setSize] = useState(10);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [convertAmount, setConvertAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const [showBox, setShowBox ] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  let page = 1;
  const location = useLocation();
  // eslint-disable-next-line
  const [carrierId, setCarrierId] = useState(location?.state?.carrierId);

  const getSelectedContactFromLocalStorage = () => {
    // Retrieve JSON string from localStorage
    const selectedContactDataJSON = localStorage.getItem("selectedContactData");

    // Convert JSON string to object
    const selectedContactData = JSON.parse(selectedContactDataJSON);

    return selectedContactData;
  };

  useEffect(() => {
    if (!showProductSelect) {
      // Reset selected product when ProductSelectRetailer is hidden
      setSelectedOption(null);
    }

    // eslint-disable-next-line
  }, [showProductSelect]);

  const selectedContact = getSelectedContactFromLocalStorage();

  const { control } = useForm({ defaultValues: { retailer_phone_number: `${selectedContact?.phoneCode}${selectedContact?.phoneNumber}` ?? "" } });

  const handleTopUpSelect = async () => {
    if (selectedProduct === "" || null) {
      setShowProductSelect(true);
      setShowTopUpInfo(false);
      errorToast("Select Product From the List");
    } else {
      setShowProductSelect(false);
      setShowTopUpInfo(true);
      setLoader(true);
      fetchRecordConvertCurrency(selectedProduct?.source_currency_amount, selectedContact?.phoneCode);
    }
  };

  const handleArrowBtn = () => {
    setShowProductSelect(true);
    setShowTopUpInfo(false);
  };

  useEffect(() => {
    handleProductData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);

    try {
      const response = await fetchCustomerProduct(page, size + 10);

      if (response?.success) {
        const newData = response?.data?.records;
        if (newData?.length > 0 && newData?.length > currentResultSetLength) {
          setProductData(newData);
          setSize((prevSize) => prevSize + 10); // Increment page number
          setCurrentResultSetLength(newData?.length);
        } else {
          setHasMoreData(false);
        }
      } else {
        errorToast("Error occurred while loading more data");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while loading more data");
    } finally {
      setLoading(false);
    }
  }, [loading, hasMoreData, page, size, currentResultSetLength]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("productSelectionComponent");
      if (parseInt(scrollContainer.scrollHeight - scrollContainer.scrollTop) === parseInt(scrollContainer.clientHeight)) {
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("productSelectionComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [loadMoreData]);

  const handleProductData = async () => {
    try {
      const response = await fetchCustomerProduct(page, size);
      if (response?.success) {
        setProductData(response?.data?.records);
        setHasMoreData(response?.data?.records.length === size); // Set hasMore based on whether there's more data to load
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const basicSearch = (event) => {
    const keyword = event?.target?.value.toLowerCase().trim(); // Convert keyword to lowercase for case-insensitive search
    if (keyword === "") {
      // If the keyword is empty, reset productData to its original state
      handleProductData(); // Fetch the original product data
    } else {
      // If the keyword is not empty, filter the productData
      const filteredData = productData.filter((product) => product.source_currency_amount.toLowerCase().includes(keyword) || product.target_currency_amount.toLowerCase().includes(keyword));
      setProductData(filteredData);
    }
  };

  const handleRadioSelect = (index) => {
    let AfnValue = productData[index];
    setSelectedOption(index);
    setSelectedProduct(AfnValue);
  };

  const handleChange = (value) => {
    console.log(value);
  };

  const fetchRecordConvertCurrency = async (amount, phone_code) => {
    try {
      const response = await convertCurrency({ amount: amount });
      if (response?.success) {
        setConvertAmount(response?.data);
        setLoader(false);
      } else {
        errorToast(response?.message);
        setLoader(true);
      }
    } catch (error) {
      errorToast(error?.response?.data?.errors[0]?.message);
      setLoader(false);
      setShowTopUpInfo(false);
      setShowProductSelect(true);

    }
  };

  const handlePaymentGateway = async () => {
    try {
      const ContactId = localStorage.getItem("ContactId");
      const userId = localStorage.getItem("userId");
      const obj = {
        converted_currency: convertAmount?.convertedCurrency,
        mobile_number: selectedContact?.phoneNumber,
        original_currency_type: convertAmount?.sourceCurrency,
        phone_code: selectedContact?.phoneCode,
        service_fee: convertAmount?.serviceFee,
        converted_amount: convertAmount?.convertedAmount,
        source_amount: convertAmount?.sourceAmount,
        total_converted_amount: convertAmount?.totalConvertedAmount,
        total_source_amount: convertAmount?.totalSourceAmount,
        vat: convertAmount?.vat,
        requested_by: userId,
        customer_contact_id: ContactId,
      };

      const rechargeDetails = JSON.stringify(obj);
      localStorage.setItem("rechargeDetails", rechargeDetails);
      setShowBox(true);

      // const stripe = await loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

      // const response = await getSessionId({ amount: convertAmount?.totalConvertedAmount ?? 0 , carrierId: carrierId});

      // const result = stripe.redirectToCheckout({
      //   sessionId: response?.data?.session_id,
      // });

      // if (result.error) {
      //   errorToast(result.error);
      // }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  return (
    <div className="container pt-5 mt-5">
      <div className="row">
        <div className="col-md-12">
          <div className="top-up">
            <div className="authentication-wrapper authentication-basic container-p-y pt-0 mt-4 text-center">
              <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2 pt-0">
                <div className="card card-topup bg-outline-primary mt-0 sending-topup scrollbar">
                  <div className="card-header d-flex justify-content-center pb-2 text-center">
                    {showProductSelect && (
                      <>
                        <div className="back-button-products">
                          <Link to="/customer/profile" type="button">
                            <i className="ti ti-arrow-narrow-left ti-sm back-button" />
                          </Link>
                        </div>
                        <div className="card-title mb-3">
                          <h4 className="m-0 me-2">You are sending Top-Up to</h4>
                        </div>
                      </>
                    )}
                    {showTopUpInfo && (
                      <>
                        <div className="back-button-products">
                          <Link to="#" type="button" onClick={handleArrowBtn}>
                            <i className="ti ti-arrow-narrow-left ti-sm back-button" />
                          </Link>
                        </div>
                        <div className="card-title mb-3">
                          <h4 className="m-0 me-2">Your Top-Up Info</h4>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row pb-3 border-bottom border-bottom-dashed">
                    <div className="row ps-5">
                      <div className="col-md-6 d-flex mb-3 justify-content-between">
                        <div className="d-flex">
                          <Controller
                            name="retailer_phone_number"
                            control={control}
                            rules={{
                              required: "Required",
                            }}
                            render={({ field }) => (
                              <>
                                <PhoneInput
                                  className="form-control-phone-input"
                                  international
                                  countryCallingCodeEditable={false}
                                  // countrySelectProps={{ unicodeFlags: true }}
                                  value={field.value}
                                  onChange={(value) => {
                                    handleChange(value);
                                  }}
                                />
                              </>
                            )}
                          />
                          <h6 className="mb-0 me-1" style={{ alignContent: "center" }}>
                            {selectedContact?.phoneCode + " " + selectedContact?.phoneNumber}
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex">
                        {/* {selectedContact?.carrierName === "Roshan" ? <img src="../../assets/img/roshan-lg.png" alt="airtel" className="me-3" width="40" height="30" /> : <img src="../../assets/img/etisalat-lg.png" alt="Etisalat" className="me-3" width="40" height="30" />}  */}
                        <span className="me-1">{selectedContact?.carrierName} </span>
                      </div>
                    </div>
                  </div>
                  {showProductSelect && (
                    <div className="card-body p-1 px-0">
                      <div className="mb-3 row mt-3 pe-3">
                        <div className="col-lg-12 ps-4">
                          <input type="text" placeholder="Search for products" className="form-control mb-0" onChange={basicSearch} />
                        </div>
                      </div>
                      <InfiniteScroll dataLength={productData.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : ""} scrollableTarget="productSelectionComponent">
                        <div className="px-0 py-0 ps-2 products-list" id="productSelectionComponent" style={{ height: "300px", overflowY: "auto" }}>
                          <div className="demo-inline-spacing mt-0">
                            <div className="list-group mt-0 me-1" style={{ marginTop: "0 !important" }}>
                              {productData &&
                                productData?.map((productDetails, index) => (
                                  <div className={`form-check custom-option custom-option-basic w-100 ${selectedOption === index ? "checked" : ""}`} key={index}>
                                    <label className="d-flex justify-content-between form-check-label custom-option-content" htmlFor={`customRadioTemp${index}`}>
                                      <div>
                                        <p className="mb-0 text-primary h5 d-table">
                                          {parseFloat(productDetails.source_currency_amount).toFixed(4)}
                                          <small> AFN</small> <small className="text-muted ms-2">(Approx.)</small>
                                        </p>
                                        <p className="mb-1 h6 d-table">
                                          {parseFloat(productDetails.target_currency_amount).toFixed(4)}
                                          <small> USD</small> <small className="text-muted ms-2">(Approx.)</small>
                                        </p>
                                      </div>
                                      <div className="mt-2 pt-1">
                                        <input name="select-plan" className="form-check-input" type="radio" value={index} id={`customRadioTemp${index}`} onChange={() => handleRadioSelect(index)} />
                                      </div>
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </InfiniteScroll>
                      <div className="row justify-content-center mt-3 border-top">
                        <div className="col-xl-5 col-md-4 col-6 mb-0 mt-3 mb-3">
                          <Link type="button" className="btn-md btn btn-primary rounded-pill text-white zindex-5 select-order" onClick={handleTopUpSelect}>
                            Continue
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {loader ? (
                    <div className="text-center mt-3 mb-3">
                      <span className="spinner-border h-15px w-15px align-middle text-danger" />
                    </div>
                  ) : (
                    showTopUpInfo && (
                      <div className="card-body p-1 px-0">
                        <div className="row justify-content-center px-4">
                          <div className="col-md-12">
                            <div className="d-flex w-100 flex-wrap justify-content-between py-2 mt-3">
                              <div className="me-2">
                                <h5 className="mb-0">Amount</h5>
                              </div>
                              <div>
                                <div className="user-progress d-flex align-items-center gap-1">
                                  <p className="mb-0 fw-medium h5">
                                    <span className="text-primary me-2">
                                      ({convertAmount?.sourceAmount} <small>{convertAmount?.sourceCurrency}</small>)
                                    </span>
                                    <span className="h5">
                                      {convertAmount?.convertedAmount} <small>{convertAmount?.convertedCurrency}</small>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                              <div className="me-2">
                                <h5 className="mb-0">Service Fee</h5>
                              </div>
                              <div className="user-progress d-flex align-items-center gap-1">
                                <p className="mb-0 fw-medium h5">
                                  {convertAmount?.serviceFee} <small>{convertAmount?.convertedCurrency}</small>
                                </p>
                              </div>
                            </div>
                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                              <div className="me-2">
                                <h5 className="mb-0">Taxes</h5>
                              </div>
                              <div className="user-progress d-flex align-items-center gap-1">
                                <p className="mb-0 fw-medium h5">
                                  {convertAmount?.vat} <small>{convertAmount?.convertedCurrency}</small>
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between border-top mt-2 pt-2">
                              <h4>Total</h4>
                              <div>
                                <p className=" h3">
                                  <span className="text-primary me-2 fs-5">
                                    ({convertAmount?.totalSourceAmount} <small>{convertAmount?.sourceCurrency}</small>)
                                  </span>
                                  {convertAmount?.totalConvertedAmount} <small>{convertAmount?.convertedCurrency}</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <Link type="button" className="btn-md btn btn-primary rounded-pill mt-2 mb-4" to="#" onClick={handlePaymentGateway}>
                            Submit
                          </Link>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBox && <SelectPaymentMethod showBox= {showBox} setShowBox= {setShowBox} setSelectedValue={setSelectedValue} selectedValue={selectedValue} amount={Math.round(convertAmount?.totalConvertedAmount * 100) / 100} carrierId= {carrierId} />}
    </div>
  );
};

export default ProductListing;
