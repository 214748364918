import React, { useEffect, useState } from "react";
import FinancialSummary from "./FinancialSummary";
import TopUpThroughRetailer from "./TopUpThroughRetailer";
import { getMasterData } from "../../../services/userService";
import ProductSelectRetailer from "./ProductSelectRetailer";

const ManageTopUp = () => {
  const [masterData, setMasterData] = useState("");
  const [showProductSelect, setShowProductSelect] = useState(false);
  const [productAmount, setProductAmount] = useState("");
  const [finalCommission, setFinalCommission] = useState("");
  const [productId, setProductId] = useState("");
  const [reRenderData, setReRenderData] = useState(true);
  const [showImage,setShowImage] = useState(true);

  let id = "";

  useEffect(() => {
    fetchMasterData();

    // eslint-disable-next-line
  }, []);

  const fetchMasterData = async () => {
    try {
      const response = await getMasterData(id);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handleReRenderData = (response) => {
    setReRenderData(response);
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-0">
        <div className="d-flex flex-column justify-content-center">
          <h4>Top-Up</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-3">
          <FinancialSummary reRenderData={reRenderData} />
        </div>
        <TopUpThroughRetailer masterData={masterData} setShowProductSelect={setShowProductSelect} setProductAmount={setProductAmount} productAmount={productAmount} finalCommission={finalCommission} productId={productId} handleReRenderData={handleReRenderData} setShowImage={setShowImage} />
        {showImage && (<div className="col-md-3 top-up-girl">
          <div className="pic_4 d-lg-flex">
            <img src="../../assets/img/girl-2.png" alt="Shaad" style={{ width: "30%", zIndex: -1 }} />
          </div>
        </div>)}
        <ProductSelectRetailer showProductSelect={showProductSelect} setProductAmount={setProductAmount} setFinalCommission={setFinalCommission} setProductId={setProductId} />
      </div>
    </>
  );
};

export default ManageTopUp;
