import CryptoJS from "crypto-js";

const decrypt = (encryptedText)=> {
    // return crypto.AES.decrypt(value, process.env.REACT_APP_SECRET_PASS).toString(crypto.enc.Utf8)
    // const result = CryptoJS.AES.decrypt(value, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_PASS), { iv: CryptoJS.enc.Utf8.parse("\0".repeat(16)), mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.Pkcs7 });
    // return result.toString(CryptoJS.enc.Utf8);
    const decrypted = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_PASS), {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}

export default decrypt;