import React, { useState, useEffect } from "react";
import ManageUserGrid from "./ManageUserGrid";
import SmallStatusCard from "../../Shared/StatusCard/SmallStatusCard";
import { useDispatch, useSelector } from "react-redux";
import ManageUserAddModal from "./ManageUserAddModal";
import ManageUserFilterModal from "./ManageUserFilterModal";
import ManageUserDetailsModal from "./ManageUserDetailsModal";
import { getMasterData } from "../../../services/userService";
import { userRoleConstants } from "../../../utils/constants";
import { formatCreditBalance } from "../../../utils/sharedFunctions";

const ManageDistributorUser = () => {
  const [displayDate, setDisplayDate] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [user, setUser] = useState("");
  const [onboardingDate, setOnboardingDate] = useState("");
  const [status, setStatus] = useState("");
  const [addModalShow, setAddModalShow] = useState(false);
  const [userDetailsShow, setUserDetailsShow] = useState(false);
  const [heading, setHeading] = useState("");
  const [userDetailsForEditModal, setUserDetailsForEditModal] = useState({});
  const [reRendering, setReRendering] = useState(false);
  const [masterData, setMasterData] = useState("");
  const [id, setId] = useState();
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentSize, setCurrentSize] = useState(10);
  const [clearFilterValues, setClearFilterValues] = useState("");

  const { DISTRIBUTOR_USER_ID } = userRoleConstants;

  const dispatch = useDispatch();

  const filteredUserChips = useSelector((state) => state?.user?.filteredUserChips);

  const userAddedBy = localStorage.getItem("userId");

  const dataToBeFiltered = Object.fromEntries(filteredUserChips);

  let transformedDataToBeFiltered = {};

  for (let key in dataToBeFiltered) {
    if (typeof dataToBeFiltered[key] === "object" && "label" in dataToBeFiltered[key] && "value" in dataToBeFiltered[key]) {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key].value;
    } else {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key];
    }
  }

  transformedDataToBeFiltered.role_id = DISTRIBUTOR_USER_ID;
  transformedDataToBeFiltered.added_by_id = userAddedBy;

  const fetchMasterData = async (id) => {
    try {
      const response = await getMasterData(id);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handleShowAddModal = () => {
    setHeading("Add user");
    setAddModalShow(!addModalShow);
  };

  useEffect(() => {
    if (userDetailsForEditModal) {
      fetchMasterData(id ?? userDetailsForEditModal?.country_code);
    }
    // eslint-disable-next-line
  }, [id, userDetailsForEditModal]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4>Users</h4>
        <p>
          <ManageUserFilterModal masterData={masterData} currentSize={currentSize} clearFilterValues={clearFilterValues} accountNumber={accountNumber} setAccountNumber={setAccountNumber} user={user} setUser={setUser} onboardingDate={onboardingDate} setOnboardingDate={setOnboardingDate} status={status} setStatus={setStatus} displayDate={displayDate} setDisplayDate={setDisplayDate} />
          <button type="button" onClick={handleShowAddModal} className="btn btn-sm btn-secondary rounded-pill btn-smwaves-effect waves-light">
            <i className="ti ti-plus me-1" /> Add
          </button>
        </p>
      </div>
      <div className="row">
        <div className="col-lg-3 col-12 mb-4">
          <SmallStatusCard color="primary" icon="ti ti-users ti-sm" value={formatCreditBalance(totalUsers ?? 0)} label="Total Users" />
        </div>
      </div>
      <ManageUserGrid userDetailsShow={userDetailsShow} setUserDetailsShow={setUserDetailsShow} setUserDetailsForEditModal={setUserDetailsForEditModal} userDetailsForEditModal={userDetailsForEditModal} setReRendering={setReRendering} reRendering={reRendering} setTotalUsers={setTotalUsers} currentSize={currentSize} setCurrentSize={setCurrentSize} masterData={masterData} setId={setId} setClearFilterValues={setClearFilterValues} filteredUserChips={filteredUserChips} userAddedBy={userAddedBy} transformedDataToBeFiltered={transformedDataToBeFiltered} DISTRIBUTOR_USER_ID={DISTRIBUTOR_USER_ID} setAccountNumber={setAccountNumber} setUser={setUser} setOnboardingDate={setOnboardingDate} setStatus={setStatus} setDisplayDate={setDisplayDate} />

      {addModalShow && <ManageUserAddModal addModalShow={addModalShow} setAddModalShow={setAddModalShow} heading={heading} userDetailsForEditModal={userDetailsForEditModal} setReRendering={setReRendering} reRendering={reRendering} setUserDetailsForEditModal={setUserDetailsForEditModal} masterData={masterData} setId={setId} transformedDataToBeFiltered={transformedDataToBeFiltered} currentSize={currentSize} dispatch={dispatch} />}
      {userDetailsShow && <ManageUserDetailsModal userDetailsShow={userDetailsShow} setUserDetailsShow={setUserDetailsShow} userDetailsForEditModal={userDetailsForEditModal} />}
    </>
  );
};

export default ManageDistributorUser;
