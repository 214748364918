import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { commissionHistoryApi } from "../../../redux/features/commissionHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import { userRoleConstants } from "../../../utils/constants";

const moment = require("moment");

const ProductHistoryModal = ({ showHistoryModalBox, setShowHistoryModalBox, productId, account_no, RoleId }) => {
  const handleClose = () => setShowHistoryModalBox(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commissionHistoryApi((RoleId !== userRoleConstants?.DISTRIBUTOR_ID && RoleId !== userRoleConstants?.DISTRIBUTOR_USER_ID) ? { product_id: productId } : { product_id: productId, account_no: account_no }));
    // eslint-disable-next-line
  }, []);

  const commissionHistory = useSelector((state) => state?.commissionHistory?.commissionHistoryDetails);

  return (
    <>
      <Modal show={showHistoryModalBox} size="md" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <button type="button" className="btn-close" onClick={handleClose} />
              <div style={{ maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}>
                <div className="text-left mb-4">
                  <h3 className="mb-2">History</h3>
                </div>
                {commissionHistory && commissionHistory.length > 0 ? (
                  commissionHistory &&
                  commissionHistory?.map((commissionDetails, index) => (
                    <ul className="timeline ms-3 mb-0" key={index}>
                      <li className="timeline-item timeline-item-transparent ps-4">
                        <span className="timeline-point timeline-point-secondary" />
                        <div className="timeline-event">
                          <div className="timeline-header"> 
                            <h6 className="mb-0">
                              {commissionDetails?.role_id === userRoleConstants.ADMIN_ID ?
                                <>
                                  Changed product price from{" "}
                                  <span data-toggle="tooltip" data-placement="top" title={`Recommended Price: ${parseFloat(commissionDetails?.product_price) + parseFloat(commissionDetails?.product_price) * (parseFloat(commissionDetails?.recommended_commission) / 100)}`}>
                                    {(parseFloat(commissionDetails?.product_price) + parseFloat(commissionDetails?.product_price) * (parseFloat(commissionDetails?.recommended_commission) / 100)).toFixed(4)} USD
                                  </span>{" "}
                                  to{" "}
                                  <span data-toggle="tooltip" data-placement="top" title={`Final Price: ${parseFloat(commissionDetails?.product_price) + parseFloat(commissionDetails?.product_price) * (parseFloat(commissionDetails?.final_commission) / 100)}`}>
                                    {(parseFloat(commissionDetails?.product_price) + parseFloat(commissionDetails?.product_price) * (parseFloat(commissionDetails?.final_commission) / 100)).toFixed(4)} USD
                                  </span>
                                </>
                               : ( commissionDetails?.role_id === userRoleConstants.DISTRIBUTOR_ID || commissionDetails?.role_id === userRoleConstants.DISTRIBUTOR_USER_ID || commissionDetails?.role_id === userRoleConstants.RETAILER_ID ) ? (
                                <>
                                  Updated product price to{" "}
                                  <span data-toggle="tooltip" data-placement="top" title={`Final Price: ${parseFloat(commissionDetails?.product_price) + parseFloat(commissionDetails?.product_price) * (parseFloat(commissionDetails?.final_commission) / 100)}`}>
                                    {(parseFloat(commissionDetails?.product_price) + parseFloat(commissionDetails?.product_price) * (parseFloat(commissionDetails?.final_commission) / 100)).toFixed(4)} USD
                                  </span>
                                </>)
                              : ""}
                            </h6>
                            {/* <small class="text-muted">Today</small> */}
                          </div>
                          <p className="mb-2 small">
                            {moment.utc(commissionDetails?.updatedAt).format('DD MMM YY, hh:mm A')} <span className="text-primary">{`by ${commissionDetails?.role}`}</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  ))
                ) : (
                  <div>No History Found ...</div>
                )}
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductHistoryModal;
