import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { downloadReceipt } from "../../../services/customerService";
import { errorToast } from "../../Toasts/Toasts";

const ReceiptDetails = ({showReceiptDetails,setShowReceiptDetails, receiptData}) => {

  const handleClose = ()=> setShowReceiptDetails(false);

  const handleReceipt = async (transactionId) => {
      try {
        const response = await downloadReceipt(transactionId);
    
          if(response) {
            const blob = new Blob([response], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = url;
            alink.download = "";
            alink.click();
            };
      
        } catch (error) {
          errorToast(error?.response?.data?.message);
        }
  }

  return (
    <Modal show={showReceiptDetails} size="lg" centered>
      <div className="modal-simple">
        <div className="modal-content p-3 p-md-3">
          <Modal.Body>
            <button type="button" className="btn-close" onClick={handleClose} />

            <div className="text-left mb-0 d-flex justify-content-between">
              <h3 className="mb-3">Receipt Details</h3>
              <div>
                <span onClick={()=>{handleReceipt(receiptData?.transaction_id)}} className="btn btn-sm btn-outline-primary rounded-pill">
                  <i className="ti ti-download" /> Receipt
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="chart-statistics">
                  <h6 className="mb-0 text-muted">Transaction ID</h6>
                  <h5 className="card-title mb-0 pt-1 fs-6">{receiptData?.transaction_id}</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="chart-statistics">
                  <h6 className="mb-0 text-muted">Recipient No.</h6>
                  <h5 className="card-title mb-0 pt-1 fs-6">{receiptData?.phone_code + ' ' + receiptData?.mobile_number}</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="chart-statistics">
                  <h6 className="mb-0 text-muted">Status</h6>
                  <h5 className={receiptData?.status === "Failed" ? "card-title mb-0 pt-1 text-danger fs-6" : "card-title mb-0 pt-1 text-success fs-6"}>{receiptData?.status}</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="chart-statistics">
                  <h6 className="mb-0 text-muted">Date</h6>
                  <h5 className="card-title mb-0 pt-1 fs-6">{moment.utc(receiptData?.updatedAt).format("DD MMM YY, hh:mm A") ?? "-----"}</h5>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-4 border-top">
              <div className="col-md-12">
                <div className="d-flex w-100 flex-wrap justify-content-between py-2 mt-3">
                  <div className="me-2">
                    <h5 className="mb-0">Amount</h5>
                  </div>
                  <div>
                    <div className="user-progress d-flex align-items-center gap-1">
                      <p className="mb-0 fw-medium h5">
                        <span className="text-primary me-2">
                          ({receiptData?.topup_amount_source_currency} <small>AFN</small>)
                        </span>
                        <span className="h5">
                        {receiptData?.topup_amount_converted_currency} <small>{receiptData?.converted_currency_type}</small>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                  <div className="me-2">
                    <h5 className="mb-0">Service Fee</h5>
                  </div>
                  <div className="user-progress d-flex align-items-center gap-1">
                    <p className="mb-0 fw-medium h5">
                      {receiptData?.service_fee} <small>{receiptData?.converted_currency_type}</small>
                    </p>
                  </div>
                </div>
                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                  <div className="me-2">
                    <h5 className="mb-0">Taxes</h5>
                  </div>
                  <div className="user-progress d-flex align-items-center gap-1">
                    <p className="mb-0 fw-medium h5">
                      {receiptData?.vat} <small>{receiptData?.converted_currency_type}</small>
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-2 pt-2">
                  <h4>Total</h4>
                  <div>
                    <p className="text-primary h3">
                      {receiptData?.total_amount_converted_currency} <small>{receiptData?.converted_currency_type}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ReceiptDetails;
