import React from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";

const RechargeModal = ({ show, handleClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate(`/customer/recharge-any-number/${data?.mobileNumber}`);
    handleClose();
  };

  const handleChange = (value) => {
    const phoneNumber = value ? value.replace(/[^0-9]/g, "") : "";
    setValue("mobileNumber", value, { shouldValidate: true });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Recharge</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label htmlFor="mobileNumber" className="form-label">
              Mobile Number
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{
                required: "Required",
                minLength: {
                  value: 7,
                  message: "Mobile number must be at least 7 digits long",
                },
                maxLength: {
                  value: 16,
                  message: "Mobile number must be at most 16 digits long",
                },
                validate: (value) => {
                  const phoneNumber = value.replace(/[^0-9]/g, "");
                  return (phoneNumber.length >= 7 && phoneNumber.length <= 15) || "Mobile number must be between 7 and 15 digits";
                },
              }}
              render={({ field }) => (
                <>
                  <PhoneInput className="form-control" international countryCallingCodeEditable={false} defaultCountry="AF" value={field.value} countries={["AF"]} onChange={handleChange} />
                  {errors?.mobileNumber && <small className="text-danger">{errors.mobileNumber.message}</small>}
                </>
              )}
            />
          </div>
          <div>
          <div class="text-end mt-4">
            <button type="submit" class="rounded-pill btn btn-primary">
              Submit
            </button>
          </div>
          </div>
         
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default RechargeModal;
