import React from "react";

const StatusCard = ({ color, icon, value, label, currencyFormat, topUpCount, list, tooltipKey }) => {
  return (
    <div className="card">
      <div className="card-body p-3">
        <div className="d-flex align-items-center">
          <div className={color}>
            <i className={icon}></i>
          </div>
          <div className="card-info">
            <h5 className="mb-0" data-toggle="tooltip" data-placement="top"   title={list?.map(item => item?.[tooltipKey]).join(', ')}>
              {value} <small>{currencyFormat}</small>
              <small>{topUpCount}</small>
            </h5>
            <small> {label}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
