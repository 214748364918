import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ColumnChart = ({headerText, smallText, xaxisText, yaxisText, accountNumber, count, amount}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if count and name are defined and are arrays
    if (Array.isArray(count) && Array.isArray(accountNumber) && Array.isArray(amount)) {
      setIsLoading(false);
    }
  }, [count, amount, accountNumber]);

  const chartData = {
    series: [
      {
        name: "Sales",
        data: amount,
      },
      {
        name: "Count",
        data: count,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "54%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 20,
        colors: ["transparent"],
      },
      xaxis: {
        categories: accountNumber,
        labels: {
          rotate: -50,
          rotateAlways: true,
          trim: true,
          style: {
            colors: "grey",
            fontSize: '13px'
          },
          formatter: function (val) {
            return val;
          }
        },
        title: {
          display: true,
          text: xaxisText,
          style: {
            color: 'grey',
            fontSize:'13px',
            fontWeight: "normal"
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            gradient: {
              opacityFrom: 0,
              opacityTo: 0,
            },
          },
        }
      },
      yaxis: {
        title: {
          display: true,
          text: yaxisText,
          style: {
            color: 'grey',
            fontSize:'13px',
            fontWeight: "normal"
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            gradient: {
              opacityFrom: 0,
              opacityTo: 0,
            },
          },
        },
      },
      fill: {
        colors: ["#f57ea4", "#42a1d1"],
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  };

  return (
    <>
      <div className="card">
        <div className="card-header header-elements pb-3">
          <div>
            <h5 className="card-title mb-0">{headerText}</h5>
            <small className="text-muted">{smallText}</small>
          </div>
        </div>
        {isLoading ? (
          // Render loader here
          <div className="px-3 py-3">
            <h5>Loading.....</h5>
          </div>
        ) : (
        <div className="card-body pt-0">
          <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
          </div>
        </div>)}
      </div>
    </>
  );
  
};

export default ColumnChart;
