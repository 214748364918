import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
  isAuthenticated: !!localStorage.getItem('token'),
  roleId: localStorage.getItem('roleId') ?? null
};
 
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.roleId = action.payload.roleId;
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.roleId = null;
    },
  },
 
});
 
export const { setLogin, setLogout } = authSlice.actions;
export default authSlice.reducer;