import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const fetchConfiguration = async () => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.ADMIN}/configurations`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateConfiguration = async (id,data) => {
  try {
    const response = await axios.put(`${baseUrl}/${ApiConstants.ADMIN}/configurations/${id}`,data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAdminDashboard = async (data) => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.DASHBOARD}?dateRangeConstant=${data}`);
    return response;
  } catch (error) {
    throw error;
  }
};