import React, { useCallback, useEffect, useState } from "react";
import { errorToast } from "../../Toasts/Toasts";
import { customerContactList } from "../../../services/AdminCustomer";
import InfiniteScroll from "react-infinite-scroll-component";

const CustomerContactList = ({ id }) => {

  const [contactList, setContactList] = useState("");
  const [currentSize, setCurrentSize] = useState(10);
  const [loading, setLoading] = useState(false); // Initialize loading state as true
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);

  let page = 1;

  useEffect(() => {
    fetchCustomerContactDetails();
    // eslint-disable-next-line
  }, []);

  const fetchCustomerContactDetails = async () => {
    try {
      const response = await customerContactList({ id, page, currentSize });
      // Set loading state to false when response is received
      if (response?.success) {
        setContactList(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);

    const response = await customerContactList({ id, page, currentSize: currentSize + 10 });

    if (response?.success) {
      const newData = response?.data;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
        setContactList(newData);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoading(false);
  }, [loading, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, page, id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("customerContactListComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("customerContactListComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  const getClassFromIndex = (index) => {
    const classes = ["bg-label-primary", "bg-label-success", "bg-label-info", "bg-label-warning"];
    return classes[index % classes.length];
  };

  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title m-0 me-2 pt-1 d-flex align-items-center">Contact's List</h5>
        </div>
        <div className="card-body">
          <InfiniteScroll dataLength={contactList.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="customerContactListComponent">
            <div id="customerHistoryComponent" style={{ height: "360px", overflowY: "auto" }}>
              <ul className="p-0 m-0">
                {contactList.length !== 0 ? (
                  contactList.map((contactLists, index) => (
                    <li className="d-flex gap-3 align-items-center mb-lg-3 pt-2 pb-1" key={index}>
                      <div className={`badge rounded p-2 ${getClassFromIndex(index)}`}>{contactLists?.id}</div>
                      <div>
                        <h6 className="mb-0 text-nowrap">{contactLists?.name}</h6>
                        <small className="text-muted">
                          {contactLists?.phone_code} {contactLists?.phone_number}
                        </small>
                      </div>
                    </li>
                  ))
                ) : (
                  <h5 className="pt-3 ps-3">No record found</h5>
                )}
              </ul>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default CustomerContactList;
