import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
  amount : "",
  carrierId: "", // Add carrierId to the initial state
};
 
const authSlice = createSlice({
  name: "amount",
  initialState,
  reducers: {
    setRechargeAmount: (state, action) => {
      state.amount = action.payload;
    },
    setRechargeCarrierId: (state, action) => {
      state.carrierId = action.payload;
    },
    resetPaymentState: (state) => {
      Object.assign(state, initialState);
    }, 
  },
 
});
 
export const { setRechargeAmount, setRechargeCarrierId , resetPaymentState} = authSlice.actions;
export default authSlice.reducer;