import * as coreAxios from "axios";
import localStorageStore from "./localStorageStore";

const baseUrl = process.env.REACT_APP_API_HOST;

export const axios = coreAxios.default.create({
  baseURL: baseUrl,
});

export const axiosInterceptor = () => {
  axios.interceptors.request.use(async (request) => {
    const authToken = localStorageStore.getToken();

    if (authToken) {
      request["headers"]["Authorization"] = `Bearer ${authToken}`;
    }
    request["headers"]["Content-Type"] = `application/json`;
    request["mode"] = "cors";

    return request;
  });

  // Response interceptors

  axios.interceptors.response.use(
    async (response) => {
      if (!response.data) {
        return response;
      }

      return response.data;
    },
    (error) => {
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 401) {
          setTimeout(() => {
            window.location.pathname = "/";
          }, 1000);

        }
      }

      return Promise.reject(error);
    }
  );
};

axiosInterceptor();
