import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminCustomerFilter } from "../../services/AdminCustomer";

const initialState = {
  adminCustomerDetails: [],
  isLoading: false,
  error: null,
  filteredChips: [],
  adminCustomerFilteredChips : [],
  count: {},
};

export const postAdminCustomerFilterApi = createAsyncThunk(
  "adminCustomer/postAdminCustomerFilterApi",
  async (filteredData) => {
    try {
      const response = await adminCustomerFilter(filteredData?.filteredData,filteredData?.page,filteredData?.size);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const adminCustomerSlice = createSlice({
  name: "adminCustomer",
  initialState,
  reducers: {
    setInitAdminCustomerDetails: (state) => {
      state.adminCustomerDetails = [];
    },
    setFilteredChips: (state, action) => {
      state.filteredChips = action.payload;
    },
    clearFilteredChips: (state) => {
      state.filteredChips = [];
    },
    setAdminCustomerFilteredChips : (state, action) => {
      state.adminCustomerFilteredChips = action.payload;
    },
    clearAdminCustomerFilteredChips: (state) => {
      state.adminCustomerFilteredChips = [];
    },
    resetAdminCustomerState: (state) => {
      Object.assign(state, initialState);
    },   
  },
  extraReducers: (builder) => {
    builder.addCase(postAdminCustomerFilterApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(postAdminCustomerFilterApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.adminCustomerDetails = action.payload.records;
      state.count = action.payload.count;
    });

    builder.addCase(postAdminCustomerFilterApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = adminCustomerSlice;
export const { setInitAdminCustomerDetails, setFilteredChips, clearFilteredChips, resetAdminCustomerState, setaAdminCustomerFilteredChips, clearAdminCustomerFilteredChips } = adminCustomerSlice.actions;
export default reducer;