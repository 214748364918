import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {logTransactionFilter} from "../../services/productService";

const initialState = {
    logDetails: [],
    isLoading: false,
    error: null,
    filteredChips: [],
    logFilteredChips : [],
    count: {},
  };

  export const postLogTransactionFilterApi = createAsyncThunk(
    "logTransaction/ postLogTransactionFilterApi",
    async (filteredData) => {
      try {
        const response = await logTransactionFilter(filteredData?.filteredData,filteredData?.page,filteredData?.size);
        return response;
      } catch (error) {
        throw error;
      }
    }
  );
  
  const logTransactionSlice = createSlice({
    name: "logTransaction",
    initialState,
    reducers: {
      setInitLogDetails: (state) => {
        state.logDetails = [];
      },
      setFilteredChips: (state, action) => {
        state.filteredChips = action.payload;
      },
      clearFilteredChips: (state) => {
        state.filteredChips = [];
      },
      resetLogTransactionState: (state) => {
        Object.assign(state, initialState);
      },   
    },
    extraReducers: (builder) => {
      builder.addCase(postLogTransactionFilterApi.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      });
  
      builder.addCase(postLogTransactionFilterApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.logDetails = action.payload;
        // state.count = action.payload.count;
      });
  
      builder.addCase(postLogTransactionFilterApi.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    },
  });
  
  const { reducer } = logTransactionSlice;
  export const { setInitLogDetails, setFilteredChips, clearFilteredChips, resetLogTransactionState } = logTransactionSlice.actions;
  export default reducer;
